import { Header as RecMenu } from "swrec-menu";
import { withUidmSSO } from "uidm-react-lib";
import React from "react";

const containerBreakpoints = {
  0: {
    maxWidth: "328px",
    width: "100%",
    margin: "0 auto",
    padding: "0 8px",
  },
  575: {
    maxWidth: "328px",
    width: "100%",
    margin: "0 auto",
    padding: "0 8px",
  },
  820: {
    maxWidth: "800px",
    width: "100%",
    margin: "0 auto",
    padding: "0 8px",
  },
  1360: {
    maxWidth: "1296px",
    width: "100%",
    margin: "0 auto",
    padding: "0 8px",
  },
  1521: {
    maxWidth: "1440px",
    width: "100%",
    margin: "0 auto",
    padding: "0 8px",
  },
};

const Header = withUidmSSO(({ sso = {} }: { sso: any}) => {
  const { disableHeader } = sso;
  if (disableHeader) return null;
  return (
    <RecMenu
      containerBreakpoints={containerBreakpoints}
      showSubMenu
      sso={sso}
      menuApiUrl={window._env.REACT_APP_HEADER_URL}
      withSSO
    />
  )
});

export default Header;
