import {
  ActionTypes,
  approveRoadmapNotificationHidden,
  approveService,
  approveServiceFailure,
  approveServiceSuccess,
  loadRoadmap,
  loadRoadmapFailure,
  loadRoadmapSuccess,
  refreshRoadmap,
  showApproveRoadmapNotification,
  startService,
  startServiceFailure,
  startServiceSuccess,
} from "./actions";
import { AxiosResponse } from "axios";
import { History } from "history";
import { ServicesCatalogControllerV1, RoadMapControllerV1, RoadMapDto, RoadMapServiceStartedDto } from "api";
import {
  all,
  call,
  delay,
  fork,
  put,
  select,
  takeEvery,
} from "redux-saga/effects";
import { hasData } from "lib/remote";
import { selectRoadmap } from "./selectors";
import { showInternalErrorNotification } from "features/core/redux/actions";
import { toErrorCode } from "lib/error-codes";

let isRefreshing = false;

export default function* roadmapSaga(_history: History<any>) {
  yield all([
    takeEvery(
      [ActionTypes.LOAD_ROADMAP, ActionTypes.REFRESH_ROADMAP],
      function* (
        action: ReturnType<typeof loadRoadmap | typeof refreshRoadmap>
      ) {
        try {
          const roadmapRes: AxiosResponse<RoadMapDto> = yield call({
            context: RoadMapControllerV1,
            fn: RoadMapControllerV1.getRoadMapUsingGET,
          });

          if (!isRefreshing && action.type !== ActionTypes.REFRESH_ROADMAP) {
            yield fork(refreshRoadMapSaga);
          } else {
            isRefreshing = true;
          }
          // roadmapRes.data.approved = false;
          yield put(loadRoadmapSuccess(roadmapRes.data));
        } catch (e) {
          yield put(loadRoadmapFailure(toErrorCode(e)));
        }
      }
    ),
    takeEvery(ActionTypes.APPROVE_ROADMAP, function* () {
      try {
        yield call({
          context: RoadMapControllerV1,
          fn: RoadMapControllerV1.approveClientRoadMapUsingPOST,
        });
        yield put(approveRoadmapNotificationHidden());
        yield put(refreshRoadmap());
      } catch (e) {
        yield put(showInternalErrorNotification(e));
      }
    }),
    takeEvery(ActionTypes.START_SERVICE, function* (
      action: ReturnType<typeof startService>
    ) {
      const roadmapData: ReturnType<typeof selectRoadmap> = yield select(
        selectRoadmap
      );
      if (hasData(roadmapData) && roadmapData.data.approved) {
        try {
          const response = yield call(
            {
              context: ServicesCatalogControllerV1,
              fn: ServicesCatalogControllerV1.getServiceUsingGET
            },
            action.serviceUuid
          )
          // @ts-ignore
          const interfaceUrl = response.data?.interfaceUrl
          if (interfaceUrl) {
            window.open(interfaceUrl);
            const result: AxiosResponse<RoadMapServiceStartedDto> = yield call(
              {
                context: RoadMapControllerV1,
                fn: RoadMapControllerV1.startServiceByClientUsingPOST,
              },
              action.serviceId,
              action.stageId
            );
            if (result.data.redirectUrl) {
              window.open(result.data.redirectUrl);
            }
            return;
          } else {
            const result: AxiosResponse<RoadMapServiceStartedDto> = yield call(
              {
                context: RoadMapControllerV1,
                fn: RoadMapControllerV1.startServiceByClientUsingPOST,
              },
              action.serviceId,
              action.stageId
            );
            if (result.data.redirectUrl) {
              window.open(result.data.redirectUrl);
            } else {
              yield put(startServiceSuccess());
              yield put(loadRoadmap());
            }
          }
        } catch (e) {
          yield put(startServiceFailure(toErrorCode(e)));
        }
      } else {
        yield put(showApproveRoadmapNotification());
      }
    }),
    takeEvery(ActionTypes.APPROVE_SERVICE, function* (
      action: ReturnType<typeof approveService>
    ) {
      try {
        yield call(
          {
            context: RoadMapControllerV1,
            fn: RoadMapControllerV1.approveServiceByClientUsingPOST,
          },
          action.roadMapId,
          action.serviceId,
          action.stageId
        );
        yield put(approveServiceSuccess());
      } catch (e) {
        yield put(approveServiceFailure(toErrorCode(e)));
      }
    }),
  ]);
}

function* refreshRoadMapSaga() {
  while (true) {
    yield delay(60 * 1000);
    yield put(refreshRoadmap());
  }
}
