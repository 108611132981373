import React from "react";
import cn from "classnames";
import css from "./benefits.module.css";

const Benefits = () => {
  return (
    <div className={css.benefitswrap}>
      <div className={css.benefits}>
        <Benefit
          description={
            <>
              Сервис автоматически предложит комплекс наиболее подходящих Вам
              услуг из более чем 170 действующих продуктов группы РЭЦ и
              аккредитованных партнеров
            </>
          }
          image={css.b1}
          title={<>Экономия времени</>}
        />
        <Benefit
          description={
            <>
              Формирование мероприятий персонифицированной дорожной карты
              учитывает Ваш текущий уровень экспортной зрелости
            </>
          }
          image={css.b2}
          title={<>Индивидуальный подход</>}
        />
        <Benefit
          description={
            <>
              Полный цикл сопровождения компании закрепленным за ней
              персональным клиентским менеджером на весь период пользования
              сервисом
            </>
          }
          image={css.b3}
          title={<>Комплексная поддержка и сопровождение </>}
        />
        <Benefit
          description={
            <>
              Ваш успех – наша цель! В&nbsp;ходе&nbsp;совместной работы мы будем
              идентифицировать Ваши основные потребности для заключения
              экспортных контрактов и делать все от нас зависящее для их
              удовлетворения
            </>
          }
          image={css.b4}
          title={<>Нацеленность на результат</>}
        />
      </div>
    </div>
  );
};

export default Benefits;

const Benefit = ({
  title,
  description,
  image,
}: {
  title: React.ReactNode;
  description: React.ReactNode;
  image: string;
}) => (
  <div className={css.benefit}>
    <div className={cn(css.image, image)} />
    <div className={css.title}>{title}</div>
    <div className={css.description}>{description}</div>
  </div>
);
