import { ActionTypes } from "features/core/redux/actions";
import { combineReducers } from "redux";
import { reducer as core } from "../features/core";
import { reducer as profile } from "../features/profile";
import { reducer as roadmap } from "../features/roadmap";
import entities from "./entitiesReducer";

const rootReducer = (state: any, action: any) => {
  if (action.type === ActionTypes.LOGOUT) {
    state = undefined;
  }
  return appReducer(state, action);
};

const appReducer = combineReducers({
  entities,
  core,
  profile,
  roadmap,
});

export type StoreState = ReturnType<typeof rootReducer>;

export default rootReducer;
