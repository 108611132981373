import {
  ChatControllerV1Api,
  Configuration,
  CountriesControllerV1Api,
  DocumentControllerV1Api,
  MemorandumControllerV1Api,
  ProcessStatusControllerV1Api,
  ProfileControllerV1Api,
  RoadMapControllerV1Api,
  SearchControllerV1Api,
  ServicesCatalogControllerV1Api,
  SurveyControllerV1Api,
} from "./impl";
import Cookies from "js-cookie";

export * from "./impl";

export const apiGetTokenCookie = () => Cookies.get("accelerator_at");

export const getAuthHeaderContents = () => {
  return `Bearer sso_1.0_${apiGetTokenCookie()}`;
};

const configuration: Configuration = new Configuration({
  apiKey: getAuthHeaderContents,
});

export const ChatControllerV1 = new ChatControllerV1Api(configuration, "");
export const CountriesControllerV1 = new CountriesControllerV1Api(
  configuration,
  ""
);
export const DocumentControllerV1 = new DocumentControllerV1Api(
  configuration,
  ""
);
export const MemorandumControllerV1 = new MemorandumControllerV1Api(
  configuration,
  ""
);
export const ProcessStatusControllerV1 = new ProcessStatusControllerV1Api(
  configuration,
  ""
);
export const ProfileControllerV1 = new ProfileControllerV1Api(
  configuration,
  ""
);
export const RoadMapControllerV1 = new RoadMapControllerV1Api(
  configuration,
  ""
);
export const SearchControllerV1 = new SearchControllerV1Api(configuration, "");
export const SurveyControllerV1 = new SurveyControllerV1Api(configuration, "");

export const ServicesCatalogControllerV1 = new ServicesCatalogControllerV1Api(
  configuration,
  ""
);
