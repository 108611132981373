/* eslint-disable import/prefer-default-export */
// import { schema } from "normalizr";

export type EntitiesState = {
  // common_core_users: {
  //   [key: string]: UserNormalized;
  // };
};
export const ENTITIES_INITIAL_STATE: EntitiesState = {
  // common_core_users: {}
};

// export interface UserNormalized extends User {}
// export interface UserDenormalized extends UserNormalized {}

// export const userSchema = new schema.Entity(
//   "common_core_users",
//   {},
//   { idAttribute: "userId" }
// );
// export const usersListSchema = new schema.Array(userSchema);
