import { ProfileStatus } from "./reducer";
import { StoreState } from "store/rootReducer";
import { maybeData, remoteMap } from "lib/remote";

export const selectProfile = (state: StoreState) => state.profile.profile;

export const selectProfileStatus = (state: StoreState) =>
  remoteMap((profile) => profile.status, selectProfile(state));

export const selectIsProfileSaveFailed = (state: StoreState) =>
  maybeData(
    (profile) => profile.status === ProfileStatus.FAILED_TO_SAVE,
    false,
    selectProfile(state)
  );

export const selectIsMemorandumSigned = (state: StoreState) =>
  state.profile.memorandumSigned;
