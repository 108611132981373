import React from "react";
import WhatIncludeAccordion from "../whatinclude-accordion";
import cn from "classnames";
import css from "./whatinclude.module.css";

const steps = [
  {
    title: "Определение перспектив экспорта",
    content: [
      {
        title: <>Анализ рынка (статистика)</>,
        description: "",
      },
      {
        title: <>Маркетинговое исследование</>,
        description: "",
      },
      {
        title: <>Выбор целевого товара и рынка для экспорта</>,
        description: "",
      },
    ],
  },
  {
    title: <>Подготовка к&nbsp;экспорту</>,
    content: [
      {
        title: <>Подготовка к&nbsp;экспорту</>,
        description: "",
      },
      {
        title: <>Соблюдение обязательных требований страны импортера</>,
        description: "",
      },
      {
        title: <>Сертификация производства и/или продукции</>,
        description: "",
      },
      {
        title: <>Таможенное администрирование</>,
        description: "",
      },
      {
        title: <>Логистика</>,
        description: "",
      },
      {
        title: <>Защита интеллектуальной собственности</>,
        description: "",
      },
      {
        title: <>Адаптация информационных материалов</>,
        description: "",
      },
      {
        title: <>Формирование коммерческого предложения</>,
        description: "",
      },
    ],
  },
  {
    title: <>Поиск покупателя</>,
    content: [
      {
        title: <>Поиск покупателя</>,
        description: "",
      },
      {
        title: <>Участие в&nbsp;выставках и&nbsp;бизнес-миссиях</>,
        description: "",
      },
      {
        title: <>Размещение на&nbsp;международных торговых площадках</>,
        description: "",
      },
    ],
  },
  {
    title: <>Заключение экспортного контракта</>,
    content: [
      {
        title: <>Подготовка текста экспортного контракта</>,
        description: "",
      },
      {
        title: <>Заключение экспортного контракта</>,
        description: "",
      },
    ],
  },
  {
    title: <>Финансовое сопровождение экспорта</>,
    content: [
      {
        title: <>Кредитно-гарантийная поддержка</>,
        description: "",
      },
      {
        title: <>Страхование экспортных поставок</>,
        description: "",
      },
      {
        title: <>Субсидиарная поддержка</>,
        description: "",
      },
      {
        title: <>Валютный контроль</>,
        description: "",
      },
      {
        title: <>Возмещение НДС</>,
        description: "",
      },
    ],
  },
];

const WhatInclude = () => {
  const [active, setActive] = React.useState(0);

  return (
    <div className={css.whatinclude}>
      <div className={css.header}>
        <div className={css.title}>Что в программе?</div>
        <ul className={css.steps}>
          {steps.map((_, index) => (
            <li
              key={index}
              className={cn(css.step, { [css.stepActive]: index === active })}
              onClick={() => setActive(index)}
              role="presentation"
            >
              <span className={css.stepText}>Шаг {index + 1}</span>
            </li>
          ))}
        </ul>
      </div>

      <div className={css.body}>
        <div className={css.image}>
          <div className={css.titleStep}>{steps[active].title}</div>
        </div>
        <div className={css.content}>
          {steps.map((stp, index) => (
            <WhatIncludeAccordion
              key={index}
              content={stp.content}
              visible={index === active}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default WhatInclude;
