import { RestApiErrorErrorCodeEnum, RoadMapDto } from "api";

export const ActionTypes = {
  REFRESH_ROADMAP: "ROADMAP/REFRESH_ROADMAP" as const,
  LOAD_ROADMAP: "ROADMAP/LOAD_ROADMAP" as const,
  LOAD_ROADMAP_SUCCESS: "ROADMAP/LOAD_ROADMAP_SUCCESS" as const,
  LOAD_ROADMAP_FAILURE: "ROADMAP/LOAD_ROADMAP_FAILURE" as const,
  APPROVE_ROADMAP: "ROADMAP/APPROVE_ROADMAP" as const,

  START_SERVICE: "ROADMAP/START_SERVICE" as const,
  START_SERVICE_SUCCESS: "ROADMAP/START_SERVICE_SUCCESS" as const,
  START_SERVICE_FAILURE: "ROADMAP/START_SERVICE_FAILURE" as const,
  START_SERVICE_NOTIFICATION_HIDDEN: "ROADMAP/START_SERVICE_NOTIFICATION_HIDDEN" as const,

  APPROVE_SERVICE: "ROADMAP/APPROVE_SERVICE" as const,
  APPROVE_SERVICE_SUCCESS: "ROADMAP/APPROVE_SERVICE_SUCCESS" as const,
  APPROVE_SERVICE_FAILURE: "ROADMAP/APPROVE_SERVICE_FAILURE" as const,
  APPROVE_SERVICE_NOTIFICATION_HIDDEN: "ROADMAP/APPROVE_SERVICE_NOTIFICATION_HIDDEN" as const,

  SHOW_APPROVE_ROADMAP_NOTIFICATION: "ROADMAP/SHOW_APPROVE_ROADMAP_NOTIFICATION" as const,
  APPROVE_ROADMAP_NOTIFICATION_HIDDEN: "ROADMAP/APPROVE_ROADMAP_NOTIFICATION_HIDDEN" as const,
};

export const loadRoadmap = () => ({ type: ActionTypes.LOAD_ROADMAP });

export const refreshRoadmap = () => ({ type: ActionTypes.REFRESH_ROADMAP });

export const loadRoadmapSuccess = (roadmap: RoadMapDto) => ({
  type: ActionTypes.LOAD_ROADMAP_SUCCESS,
  roadmap,
});

export const loadRoadmapFailure = (error: RestApiErrorErrorCodeEnum) => ({
  type: ActionTypes.LOAD_ROADMAP_FAILURE,
  error,
});

export const approveRoadmap = () => ({ type: ActionTypes.APPROVE_ROADMAP });

export const startService = (stageId: number, serviceId: number, serviceUuid:string) => ({
  type: ActionTypes.START_SERVICE,
  serviceId,
  stageId,
  serviceUuid
});

export const startServiceSuccess = () => ({
  type: ActionTypes.START_SERVICE_SUCCESS,
});

export const startServiceFailure = (error: RestApiErrorErrorCodeEnum) => ({
  type: ActionTypes.START_SERVICE_FAILURE,
  error,
  meta: { dontIntercept: true },
});

export const startServiceNotificationHidden = () => ({
  type: ActionTypes.START_SERVICE_NOTIFICATION_HIDDEN,
});

export const approveService = (
  roadMapId: number,
  serviceId: number,
  stageId: number
) => ({
  type: ActionTypes.APPROVE_SERVICE,
  roadMapId,
  serviceId,
  stageId,
});

export const approveServiceSuccess = () => ({
  type: ActionTypes.APPROVE_SERVICE_SUCCESS,
});

export const approveServiceFailure = (error: RestApiErrorErrorCodeEnum) => ({
  type: ActionTypes.APPROVE_SERVICE_FAILURE,
  error,
});

export const approveServiceNotificationHidden = () => ({
  type: ActionTypes.APPROVE_SERVICE_NOTIFICATION_HIDDEN,
});

export const showApproveRoadmapNotification = () => ({
  type: ActionTypes.SHOW_APPROVE_ROADMAP_NOTIFICATION,
});

export const approveRoadmapNotificationHidden = () => ({
  type: ActionTypes.APPROVE_ROADMAP_NOTIFICATION_HIDDEN,
});
