import { History } from "history";
import { Reducer, applyMiddleware, createStore } from "redux";
import { mergeDeepRight } from "ramda";
import { persistReducer, persistStore } from "redux-persist";
import { middleware as sagaThunkMiddleware } from "redux-saga-thunk";
import storage from "redux-persist/lib/storage/session";
// import { captureException } from "@sentry/browser";
// import createCatch from "redux-catch"
import { composeWithDevTools } from "redux-devtools-extension";
import { createLogger } from "redux-logger";
import createSagaMiddleware from "redux-saga";
import rootSaga_ from "./rootSaga";

export default function configureStore(
  rootReducer: Reducer,
  rootSaga: typeof rootSaga_, // (persistor: Persistor, history: History<any>) => Saga,
  history: History<any>
) {
  const persistConfig = {
    key: "tasks",
    storage,
    migrate: (state: any) =>
      Promise.resolve(
        mergeDeepRight(
          rootReducer(undefined, { type: "INIT" }),
          state != null ? state : {}
        ) as any
      ),
  };
  const persistedReducer = persistReducer(persistConfig, rootReducer);

  // const monitor = (window as any)["__SAGA_MONITOR_EXTENSION__"];
  const sagaMiddleware = createSagaMiddleware({
    // sagaMonitor: monitor
    // onError: err => {
    //   captureException(err);
    // }
  });

  const middlewares = [
    // createCatch(err => captureException(err)),
    createLogger(),
    sagaThunkMiddleware,
    sagaMiddleware,
  ];

  const middlewareEnhancer = applyMiddleware(...middlewares);

  const composeEnhancers = composeWithDevTools({
    trace: process.env.NODE_ENV !== "production",
  });

  const store = createStore(
    persistedReducer,
    undefined,
    composeEnhancers(middlewareEnhancer)
  );

  const persistor = persistStore(store);

  sagaMiddleware.run(rootSaga(persistor, history));

  // axios.interceptors.response.use(
  //   response => {
  //     if (
  //       response.status === 403 &&
  //       oc(response)
  //         .config.url("")
  //         .indexOf("uidm") === -1
  //     ) {
  //       store.dispatch(logout());
  //     }
  //     return response;
  //   },
  //   error => {
  //     if (
  //       error.response.status === 403 &&
  //       oc(error)
  //         .request.url("")
  //         .indexOf("uidm") === -1
  //     ) {
  //       store.dispatch(logout());
  //     }
  //     throw error;
  //   }
  // );

  return { store, persistor };
}
