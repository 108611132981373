import "./page.css";
import Benefits from "../organisms/benefits";
// import Footer from "../organisms/footer";
import Header from "../../../ui/organisms/Header";
import Hello from "../organisms/hello";
import Howit from "../organisms/howit";
import React from "react";
import WhatInclude from "../organisms/whatinclude";
import Whom from "../organisms/whom";

const PromoPage = () => {
  return (
    <>
      <Header />
      <Hello />
      <WhatInclude />
      <Benefits />
      <Whom />
      <Howit />
      {/* <Footer /> */}
    </>
  );
};

export default PromoPage;
