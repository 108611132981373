/* eslint-disable */
/**
 * Accelerator API Documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as globalImportUrl from 'url';
import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * An object that stores request of services from catalog to add to road map stage
 * @export
 * @interface AddServicesDto
 */
export interface AddServicesDto {
    /**
     * Ids of services from catalog
     * @type {Array<string>}
     * @memberof AddServicesDto
     */
    services: Array<string>;
    /**
     * Road map stage id
     * @type {number}
     * @memberof AddServicesDto
     */
    stageId: number;
}
/**
 * An object that stores change block status reason
 * @export
 * @interface ChangeBlockRequestDto
 */
export interface ChangeBlockRequestDto {
    /**
     * Change block reason
     * @type {string}
     * @memberof ChangeBlockRequestDto
     */
    reason: string;
}
/**
 * An object that stores client chat information
 * @export
 * @interface ChatDto
 */
export interface ChatDto {
    /**
     * Chat id
     * @type {string}
     * @memberof ChatDto
     */
    chatId: string;
    /**
     * Organization id
     * @type {string}
     * @memberof ChatDto
     */
    orgId: string;
    /**
     * Organization name
     * @type {string}
     * @memberof ChatDto
     */
    orgName: string;
    /**
     * Sender name
     * @type {string}
     * @memberof ChatDto
     */
    senderName: string;
}
/**
 * An object that stores client filled profile information including questions with answers
 * @export
 * @interface ClientFilledProfileDto
 */
export interface ClientFilledProfileDto {
    /**
     * Address
     * @type {string}
     * @memberof ClientFilledProfileDto
     */
    address: string;
    /**
     * Contact person fio
     * @type {string}
     * @memberof ClientFilledProfileDto
     */
    contactPersonFio: string;
    /**
     * Contact phone number
     * @type {string}
     * @memberof ClientFilledProfileDto
     */
    contactPhone?: string;
    /**
     * Countries
     * @type {Array<CountryDto>}
     * @memberof ClientFilledProfileDto
     */
    countries?: Array<CountryDto>;
    /**
     * Drop-down lists of questions
     * @type {Array<ProfileClientListQuestionDto>}
     * @memberof ClientFilledProfileDto
     */
    dropDownLists?: Array<ProfileClientListQuestionDto>;
    /**
     * Exp products
     * @type {Array<string>}
     * @memberof ClientFilledProfileDto
     */
    expProducts?: Array<string>;
    /**
     * INN
     * @type {string}
     * @memberof ClientFilledProfileDto
     */
    inn: string;
    /**
     * Mail
     * @type {string}
     * @memberof ClientFilledProfileDto
     */
    mail: string;
    /**
     * Main brands
     * @type {Array<string>}
     * @memberof ClientFilledProfileDto
     */
    mainBrands?: Array<string>;
    /**
     * Main products
     * @type {Array<string>}
     * @memberof ClientFilledProfileDto
     */
    mainProducts?: Array<string>;
    /**
     * OGRN
     * @type {string}
     * @memberof ClientFilledProfileDto
     */
    ogrn: string;
    /**
     * Okveds
     * @type {Array<ProfileOkvedDto>}
     * @memberof ClientFilledProfileDto
     */
    okveds?: Array<ProfileOkvedDto>;
    /**
     * Okveds Exp
     * @type {Array<ProfileOkvedExpDto>}
     * @memberof ClientFilledProfileDto
     */
    okvedsExp?: Array<ProfileOkvedExpDto>;
    /**
     * Org name
     * @type {string}
     * @memberof ClientFilledProfileDto
     */
    orgName: string;
    /**
     * Phone number
     * @type {string}
     * @memberof ClientFilledProfileDto
     */
    phone: string;
    /**
     * Tnveds
     * @type {Array<ProfileTnvedDto>}
     * @memberof ClientFilledProfileDto
     */
    tnveds?: Array<ProfileTnvedDto>;
    /**
     * Web site url
     * @type {string}
     * @memberof ClientFilledProfileDto
     */
    webSiteUrl: string;
}
/**
 * An object that stores filled profile information with presentation
 * @export
 * @interface ClientFilledProfileWithPresentationRequest
 */
export interface ClientFilledProfileWithPresentationRequest {
    /**
     * Address
     * @type {string}
     * @memberof ClientFilledProfileWithPresentationRequest
     */
    address: string;
    /**
     * Contact person fio
     * @type {string}
     * @memberof ClientFilledProfileWithPresentationRequest
     */
    contactPersonFio: string;
    /**
     * Contact phone number
     * @type {string}
     * @memberof ClientFilledProfileWithPresentationRequest
     */
    contactPhone?: string;
    /**
     * Countries
     * @type {Array<CountryDto>}
     * @memberof ClientFilledProfileWithPresentationRequest
     */
    countries?: Array<CountryDto>;
    /**
     * Drop-down lists of questions
     * @type {Array<ProfileClientListAnswerDto>}
     * @memberof ClientFilledProfileWithPresentationRequest
     */
    dropDownLists?: Array<ProfileClientListAnswerDto>;
    /**
     * Exp products
     * @type {Array<string>}
     * @memberof ClientFilledProfileWithPresentationRequest
     */
    expProducts?: Array<string>;
    /**
     * INN
     * @type {string}
     * @memberof ClientFilledProfileWithPresentationRequest
     */
    inn: string;
    /**
     * Mail
     * @type {string}
     * @memberof ClientFilledProfileWithPresentationRequest
     */
    mail: string;
    /**
     * Main brands
     * @type {Array<string>}
     * @memberof ClientFilledProfileWithPresentationRequest
     */
    mainBrands?: Array<string>;
    /**
     * Main products
     * @type {Array<string>}
     * @memberof ClientFilledProfileWithPresentationRequest
     */
    mainProducts?: Array<string>;
    /**
     * OGRN
     * @type {string}
     * @memberof ClientFilledProfileWithPresentationRequest
     */
    ogrn: string;
    /**
     * Okveds
     * @type {Array<ProfileOkvedDto>}
     * @memberof ClientFilledProfileWithPresentationRequest
     */
    okveds?: Array<ProfileOkvedDto>;
    /**
     * Okveds Exp
     * @type {Array<ProfileOkvedExpDto>}
     * @memberof ClientFilledProfileWithPresentationRequest
     */
    okvedsExp?: Array<ProfileOkvedExpDto>;
    /**
     * Org name
     * @type {string}
     * @memberof ClientFilledProfileWithPresentationRequest
     */
    orgName: string;
    /**
     * Phone number
     * @type {string}
     * @memberof ClientFilledProfileWithPresentationRequest
     */
    phone: string;
    /**
     * 
     * @type {PresentationDto}
     * @memberof ClientFilledProfileWithPresentationRequest
     */
    presentationDto?: PresentationDto;
    /**
     * Tnveds
     * @type {Array<ProfileTnvedDto>}
     * @memberof ClientFilledProfileWithPresentationRequest
     */
    tnveds?: Array<ProfileTnvedDto>;
    /**
     * Web site url
     * @type {string}
     * @memberof ClientFilledProfileWithPresentationRequest
     */
    webSiteUrl: string;
}
/**
 * An object that stores country information
 * @export
 * @interface CountryDto
 */
export interface CountryDto {
    /**
     * Country name
     * @type {string}
     * @memberof CountryDto
     */
    caption: string;
    /**
     * Country Oktmo code
     * @type {string}
     * @memberof CountryDto
     */
    code: string;
}
/**
 * An object that stores decline client data
 * @export
 * @interface DeclineRequestDto
 */
export interface DeclineRequestDto {
    /**
     * Manager id
     * @type {string}
     * @memberof DeclineRequestDto
     */
    managerId: string;
}
/**
 * An object that stores request of road map services to delete from road map stage
 * @export
 * @interface DeleteRoadMapServicesDto
 */
export interface DeleteRoadMapServicesDto {
    /**
     * Roda map service ids
     * @type {Array<number>}
     * @memberof DeleteRoadMapServicesDto
     */
    services: Array<number>;
    /**
     * Road map stage id
     * @type {number}
     * @memberof DeleteRoadMapServicesDto
     */
    stageId: number;
}
/**
 * An object that stores document for upload information
 * @export
 * @interface DocumentUploadDto
 */
export interface DocumentUploadDto {
    /**
     * Document content base64 encoded value
     * @type {string}
     * @memberof DocumentUploadDto
     */
    content: string;
    /**
     * Document file name value
     * @type {string}
     * @memberof DocumentUploadDto
     */
    fileName: string;
}
/**
 * An object that stores profile edit information
 * @export
 * @interface EditProfileRequest
 */
export interface EditProfileRequest {
    /**
     * Address
     * @type {string}
     * @memberof EditProfileRequest
     */
    address: string;
    /**
     * Contact person fio
     * @type {string}
     * @memberof EditProfileRequest
     */
    contactPersonFio: string;
    /**
     * Contact phone number
     * @type {string}
     * @memberof EditProfileRequest
     */
    contactPhone?: string;
    /**
     * Countries
     * @type {Array<CountryDto>}
     * @memberof EditProfileRequest
     */
    countries?: Array<CountryDto>;
    /**
     * Drop-down lists of questions
     * @type {Array<ProfileClientListAnswerDto>}
     * @memberof EditProfileRequest
     */
    dropDownLists?: Array<ProfileClientListAnswerDto>;
    /**
     * Exp products
     * @type {Array<string>}
     * @memberof EditProfileRequest
     */
    expProducts?: Array<string>;
    /**
     * INN
     * @type {string}
     * @memberof EditProfileRequest
     */
    inn: string;
    /**
     * Mail
     * @type {string}
     * @memberof EditProfileRequest
     */
    mail: string;
    /**
     * Main brands
     * @type {Array<string>}
     * @memberof EditProfileRequest
     */
    mainBrands?: Array<string>;
    /**
     * Main products
     * @type {Array<string>}
     * @memberof EditProfileRequest
     */
    mainProducts?: Array<string>;
    /**
     * OGRN
     * @type {string}
     * @memberof EditProfileRequest
     */
    ogrn: string;
    /**
     * Okveds
     * @type {Array<ProfileOkvedDto>}
     * @memberof EditProfileRequest
     */
    okveds?: Array<ProfileOkvedDto>;
    /**
     * Okveds Exp
     * @type {Array<ProfileOkvedExpDto>}
     * @memberof EditProfileRequest
     */
    okvedsExp?: Array<ProfileOkvedExpDto>;
    /**
     * Org name
     * @type {string}
     * @memberof EditProfileRequest
     */
    orgName: string;
    /**
     * Phone number
     * @type {string}
     * @memberof EditProfileRequest
     */
    phone: string;
    /**
     * List of survey results
     * @type {Array<SurveyResultDto>}
     * @memberof EditProfileRequest
     */
    surveyResult?: Array<SurveyResultDto>;
    /**
     * Tnveds
     * @type {Array<ProfileTnvedDto>}
     * @memberof EditProfileRequest
     */
    tnveds?: Array<ProfileTnvedDto>;
    /**
     * Web site url
     * @type {string}
     * @memberof EditProfileRequest
     */
    webSiteUrl: string;
}
/**
 * An object that stores road map step finishing information
 * @export
 * @interface FinishRoadMapStepRequestDto
 */
export interface FinishRoadMapStepRequestDto {
    /**
     * Service rate comment by client
     * @type {string}
     * @memberof FinishRoadMapStepRequestDto
     */
    comment?: string;
    /**
     * Base64 encoded documents to upload to road map step
     * @type {Array<DocumentUploadDto>}
     * @memberof FinishRoadMapStepRequestDto
     */
    documents?: Array<DocumentUploadDto>;
    /**
     * Service rate by client
     * @type {number}
     * @memberof FinishRoadMapStepRequestDto
     */
    rate: number;
}
/**
 * An object that stores response memorandum information
 * @export
 * @interface MemorandumResponseDto
 */
export interface MemorandumResponseDto {
    /**
     * Memorandum text
     * @type {string}
     * @memberof MemorandumResponseDto
     */
    text: string;
}
/**
 * An object that stores response about client status
 * @export
 * @interface MemorandumStatusResponseDto
 */
export interface MemorandumStatusResponseDto {
    /**
     * Memorandum signed by client
     * @type {boolean}
     * @memberof MemorandumStatusResponseDto
     */
    signedByClient: boolean;
}
/**
 * 
 * @export
 * @interface ModelFile
 */
export interface ModelFile {
    /**
     * 
     * @type {boolean}
     * @memberof ModelFile
     */
    absolute?: boolean;
    /**
     * 
     * @type {any}
     * @memberof ModelFile
     */
    absoluteFile?: any;
    /**
     * 
     * @type {string}
     * @memberof ModelFile
     */
    absolutePath?: string;
    /**
     * 
     * @type {any}
     * @memberof ModelFile
     */
    canonicalFile?: any;
    /**
     * 
     * @type {string}
     * @memberof ModelFile
     */
    canonicalPath?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ModelFile
     */
    directory?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ModelFile
     */
    executable?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ModelFile
     */
    file?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ModelFile
     */
    freeSpace?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ModelFile
     */
    hidden?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ModelFile
     */
    lastModified?: number;
    /**
     * 
     * @type {string}
     * @memberof ModelFile
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelFile
     */
    parent?: string;
    /**
     * 
     * @type {any}
     * @memberof ModelFile
     */
    parentFile?: any;
    /**
     * 
     * @type {string}
     * @memberof ModelFile
     */
    path?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ModelFile
     */
    readable?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ModelFile
     */
    totalSpace?: number;
    /**
     * 
     * @type {number}
     * @memberof ModelFile
     */
    usableSpace?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ModelFile
     */
    writable?: boolean;
}
/**
 * An object that stores potential exporters data information
 * @export
 * @interface PotentialExportersReportDataDto
 */
export interface PotentialExportersReportDataDto {
    /**
     * Potential clients list data
     * @type {Array<ReportClientDto>}
     * @memberof PotentialExportersReportDataDto
     */
    clients?: Array<ReportClientDto>;
}
/**
 * An object that stores prefilled profile information
 * @export
 * @interface PrefilledProfileDto
 */
export interface PrefilledProfileDto {
    /**
     * Address
     * @type {string}
     * @memberof PrefilledProfileDto
     */
    address: string;
    /**
     * Contact person fio
     * @type {string}
     * @memberof PrefilledProfileDto
     */
    contactPersonFio: string;
    /**
     * Contact phone number
     * @type {string}
     * @memberof PrefilledProfileDto
     */
    contactPhone?: string;
    /**
     * Drop-down lists of questions
     * @type {Array<ProfileMetaListQuestionDto>}
     * @memberof PrefilledProfileDto
     */
    dropDownLists: Array<ProfileMetaListQuestionDto>;
    /**
     * INN
     * @type {string}
     * @memberof PrefilledProfileDto
     */
    inn: string;
    /**
     * Mail
     * @type {string}
     * @memberof PrefilledProfileDto
     */
    mail: string;
    /**
     * OGRN
     * @type {string}
     * @memberof PrefilledProfileDto
     */
    ogrn: string;
    /**
     * Okveds
     * @type {Array<ProfileOkvedDto>}
     * @memberof PrefilledProfileDto
     */
    okveds: Array<ProfileOkvedDto>;
    /**
     * 
     * @type {Array<ProfileOkvedExpDto>}
     * @memberof PrefilledProfileDto
     */
    okvedsExp?: Array<ProfileOkvedExpDto>;
    /**
     * Org name
     * @type {string}
     * @memberof PrefilledProfileDto
     */
    orgName: string;
    /**
     * Phone number
     * @type {string}
     * @memberof PrefilledProfileDto
     */
    phone: string;
    /**
     * Web site url
     * @type {string}
     * @memberof PrefilledProfileDto
     */
    webSiteUrl: string;
}
/**
 * An object that stores Presentation information
 * @export
 * @interface PresentationDto
 */
export interface PresentationDto {
    /**
     * Presentation name value
     * @type {string}
     * @memberof PresentationDto
     */
    fileName?: string;
    /**
     * Presentation base64 encoded value
     * @type {string}
     * @memberof PresentationDto
     */
    presentation?: string;
}
/**
 * An object that stores client process status information
 * @export
 * @interface ProcessStatusResponseDto
 */
export interface ProcessStatusResponseDto {
    /**
     * Process status description
     * @type {string}
     * @memberof ProcessStatusResponseDto
     */
    description: string;
    /**
     * Redirect Url
     * @type {string}
     * @memberof ProcessStatusResponseDto
     */
    redirectUrl: string;
    /**
     * Process status
     * @type {string}
     * @memberof ProcessStatusResponseDto
     */
    status: ProcessStatusResponseDtoStatusEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum ProcessStatusResponseDtoStatusEnum {
    SURVEYOPEN = 'SURVEY_OPEN',
    SURVEYINPROGRESS = 'SURVEY_IN_PROGRESS',
    SURVEYFAILED = 'SURVEY_FAILED',
    PROFILEOPEN = 'PROFILE_OPEN',
    PROFILEONVERIFY = 'PROFILE_ON_VERIFY',
    PROFILEDECLINED = 'PROFILE_DECLINED',
    MEMORANDUMOPEN = 'MEMORANDUM_OPEN',
    ROADMAPINPROGRESS = 'ROAD_MAP_IN_PROGRESS'
}

/**
 * An object that stores market and product
 * @export
 * @interface ProductAndMarketRequestDto
 */
export interface ProductAndMarketRequestDto {
    /**
     * 
     * @type {RoadMapMarketDto}
     * @memberof ProductAndMarketRequestDto
     */
    market?: RoadMapMarketDto;
    /**
     * 
     * @type {RoadMapProductDto}
     * @memberof ProductAndMarketRequestDto
     */
    product?: RoadMapProductDto;
    /**
     * 
     * @type {RoadMapProductServiceDto}
     * @memberof ProductAndMarketRequestDto
     */
    service?: RoadMapProductServiceDto;
}
/**
 * An object that stores market and product
 * @export
 * @interface ProductAndMarketResponseDto
 */
export interface ProductAndMarketResponseDto {
    /**
     * 
     * @type {RoadMapMarketDto}
     * @memberof ProductAndMarketResponseDto
     */
    market?: RoadMapMarketDto;
    /**
     * 
     * @type {RoadMapProductDto}
     * @memberof ProductAndMarketResponseDto
     */
    product?: RoadMapProductDto;
    /**
     * 
     * @type {RoadMapProductServiceDto}
     * @memberof ProductAndMarketResponseDto
     */
    service?: RoadMapProductServiceDto;
}
/**
 * An object that stores question client\'s answer
 * @export
 * @interface ProfileClientListAnswerDto
 */
export interface ProfileClientListAnswerDto {
    /**
     * Answer id
     * @type {number}
     * @memberof ProfileClientListAnswerDto
     */
    answerId: number;
    /**
     * Note
     * @type {string}
     * @memberof ProfileClientListAnswerDto
     */
    note?: string;
    /**
     * Question id
     * @type {number}
     * @memberof ProfileClientListAnswerDto
     */
    questionId: number;
}
/**
 * An object that stores question answer and client\'s selection for it
 * @export
 * @interface ProfileClientListQuestionAnswerDto
 */
export interface ProfileClientListQuestionAnswerDto {
    /**
     * Answer
     * @type {string}
     * @memberof ProfileClientListQuestionAnswerDto
     */
    answer: string;
    /**
     * Id
     * @type {number}
     * @memberof ProfileClientListQuestionAnswerDto
     */
    id: number;
    /**
     * Note
     * @type {string}
     * @memberof ProfileClientListQuestionAnswerDto
     */
    note?: string;
    /**
     * Selected by user
     * @type {boolean}
     * @memberof ProfileClientListQuestionAnswerDto
     */
    selectedByUser: boolean;
    /**
     * Show note
     * @type {boolean}
     * @memberof ProfileClientListQuestionAnswerDto
     */
    showNote: boolean;
}
/**
 * An object that stores question and selected client\'s answer
 * @export
 * @interface ProfileClientListQuestionDto
 */
export interface ProfileClientListQuestionDto {
    /**
     * Answers
     * @type {Array<ProfileClientListQuestionAnswerDto>}
     * @memberof ProfileClientListQuestionDto
     */
    answers: Array<ProfileClientListQuestionAnswerDto>;
    /**
     * Id
     * @type {number}
     * @memberof ProfileClientListQuestionDto
     */
    id: number;
    /**
     * Label
     * @type {string}
     * @memberof ProfileClientListQuestionDto
     */
    label: string;
    /**
     * Question
     * @type {string}
     * @memberof ProfileClientListQuestionDto
     */
    question: string;
}
/**
 * An object that stores question answer
 * @export
 * @interface ProfileMetaListAnswerDto
 */
export interface ProfileMetaListAnswerDto {
    /**
     * Answer
     * @type {string}
     * @memberof ProfileMetaListAnswerDto
     */
    answer: string;
    /**
     * Id
     * @type {number}
     * @memberof ProfileMetaListAnswerDto
     */
    id: number;
    /**
     * Show note
     * @type {boolean}
     * @memberof ProfileMetaListAnswerDto
     */
    showNote: boolean;
}
/**
 * An object that stores question
 * @export
 * @interface ProfileMetaListQuestionDto
 */
export interface ProfileMetaListQuestionDto {
    /**
     * Answers
     * @type {Array<ProfileMetaListAnswerDto>}
     * @memberof ProfileMetaListQuestionDto
     */
    answers: Array<ProfileMetaListAnswerDto>;
    /**
     * Id
     * @type {number}
     * @memberof ProfileMetaListQuestionDto
     */
    id: number;
    /**
     * Label
     * @type {string}
     * @memberof ProfileMetaListQuestionDto
     */
    label: string;
    /**
     * Question
     * @type {string}
     * @memberof ProfileMetaListQuestionDto
     */
    question: string;
}
/**
 * An object that stores okved
 * @export
 * @interface ProfileOkvedDto
 */
export interface ProfileOkvedDto {
    /**
     * Okved caption
     * @type {string}
     * @memberof ProfileOkvedDto
     */
    caption: string;
    /**
     * Okved code
     * @type {string}
     * @memberof ProfileOkvedDto
     */
    code: string;
}
/**
 * An object that stores okved exp
 * @export
 * @interface ProfileOkvedExpDto
 */
export interface ProfileOkvedExpDto {
    /**
     * Okved caption
     * @type {string}
     * @memberof ProfileOkvedExpDto
     */
    caption: string;
    /**
     * Okved code
     * @type {string}
     * @memberof ProfileOkvedExpDto
     */
    code: string;
}
/**
 * An object that stores profile status information
 * @export
 * @interface ProfileStatusDto
 */
export interface ProfileStatusDto {
    /**
     * Profile declined
     * @type {boolean}
     * @memberof ProfileStatusDto
     */
    declined: boolean;
    /**
     * Profile verified
     * @type {boolean}
     * @memberof ProfileStatusDto
     */
    verified: boolean;
}
/**
 * An object that stores tnved
 * @export
 * @interface ProfileTnvedDto
 */
export interface ProfileTnvedDto {
    /**
     * Tnved caption
     * @type {string}
     * @memberof ProfileTnvedDto
     */
    caption: string;
    /**
     * Tnved code
     * @type {string}
     * @memberof ProfileTnvedDto
     */
    code: string;
}
/**
 * An object that stores report client contact data information
 * @export
 * @interface ReportClientContactDto
 */
export interface ReportClientContactDto {
    /**
     * Contact person email
     * @type {string}
     * @memberof ReportClientContactDto
     */
    email?: string;
    /**
     * Contact person first name
     * @type {string}
     * @memberof ReportClientContactDto
     */
    firstName?: string;
    /**
     * Contact person last name
     * @type {string}
     * @memberof ReportClientContactDto
     */
    lastName?: string;
    /**
     * Contact person middle name
     * @type {string}
     * @memberof ReportClientContactDto
     */
    middleName?: string;
    /**
     * Contact person phone number
     * @type {string}
     * @memberof ReportClientContactDto
     */
    phoneNumber?: string;
}
/**
 * An object that stores report client data information
 * @export
 * @interface ReportClientDto
 */
export interface ReportClientDto {
    /**
     * Client manager FIO
     * @type {string}
     * @memberof ReportClientDto
     */
    clientManagerFio?: string;
    /**
     * Client manager organization name
     * @type {string}
     * @memberof ReportClientDto
     */
    clientManagerOrg?: string;
    /**
     * Contacts
     * @type {Array<ReportClientContactDto>}
     * @memberof ReportClientDto
     */
    contacts?: Array<ReportClientContactDto>;
    /**
     * INN
     * @type {string}
     * @memberof ReportClientDto
     */
    inn?: string;
    /**
     * Market country
     * @type {string}
     * @memberof ReportClientDto
     */
    marketCountry?: string;
    /**
     * Market product
     * @type {string}
     * @memberof ReportClientDto
     */
    marketProduct?: string;
    /**
     * Memorandum sign date
     * @type {string}
     * @memberof ReportClientDto
     */
    memorandumSignDate?: string;
    /**
     * Client OKVEDS
     * @type {string}
     * @memberof ReportClientDto
     */
    okveds?: string;
    /**
     * Organization name
     * @type {string}
     * @memberof ReportClientDto
     */
    orgName?: string;
    /**
     * Process step
     * @type {string}
     * @memberof ReportClientDto
     */
    processStep?: string;
    /**
     * Region
     * @type {string}
     * @memberof ReportClientDto
     */
    region?: string;
    /**
     * Road map approve date
     * @type {string}
     * @memberof ReportClientDto
     */
    roadMapApproveDate?: string;
    /**
     * Survey date time
     * @type {string}
     * @memberof ReportClientDto
     */
    surveyDate?: string;
    /**
     * Survey rating value
     * @type {string}
     * @memberof ReportClientDto
     */
    surveyRating?: string;
    /**
     * Survey stop factors
     * @type {string}
     * @memberof ReportClientDto
     */
    surveyStopFactors?: string;
    /**
     * Road map tasks list
     * @type {Array<ReportTaskLinkDto>}
     * @memberof ReportClientDto
     */
    taskLinks?: Array<ReportTaskLinkDto>;
    /**
     * Tms tasks link
     * @type {string}
     * @memberof ReportClientDto
     */
    tmsTasksLink?: string;
    /**
     * Client tnveds
     * @type {string}
     * @memberof ReportClientDto
     */
    tnveds?: string;
    /**
     * Profile verify date
     * @type {string}
     * @memberof ReportClientDto
     */
    verifyDate?: string;
}
/**
 * An object that stores potential exporters filter data information
 * @export
 * @interface ReportFilterDto
 */
export interface ReportFilterDto {
    /**
     * Survey date from
     * @type {string}
     * @memberof ReportFilterDto
     */
    surveyDateFrom?: string;
    /**
     * Survey date to
     * @type {string}
     * @memberof ReportFilterDto
     */
    surveyDateTo?: string;
}
/**
 * An object that stores road map tasks list data information
 * @export
 * @interface ReportTaskLinkDto
 */
export interface ReportTaskLinkDto {
    /**
     * Link value
     * @type {string}
     * @memberof ReportTaskLinkDto
     */
    link?: string;
}
/**
 * 
 * @export
 * @interface Resource
 */
export interface Resource {
    /**
     * 
     * @type {string}
     * @memberof Resource
     */
    description?: string;
    /**
     * 
     * @type {any}
     * @memberof Resource
     */
    file?: any;
    /**
     * 
     * @type {string}
     * @memberof Resource
     */
    filename?: string;
    /**
     * 
     * @type {object}
     * @memberof Resource
     */
    inputStream?: object;
    /**
     * 
     * @type {boolean}
     * @memberof Resource
     */
    open?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Resource
     */
    readable?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Resource
     */
    uri?: string;
    /**
     * 
     * @type {URL}
     * @memberof Resource
     */
    url?: URL;
}
/**
 * 
 * @export
 * @interface ResponseEntity
 */
export interface ResponseEntity {
    /**
     * 
     * @type {object}
     * @memberof ResponseEntity
     */
    body?: object;
    /**
     * 
     * @type {string}
     * @memberof ResponseEntity
     */
    statusCode?: ResponseEntityStatusCodeEnum;
    /**
     * 
     * @type {number}
     * @memberof ResponseEntity
     */
    statusCodeValue?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum ResponseEntityStatusCodeEnum {
    _100CONTINUE = '100 CONTINUE',
    _101SWITCHINGPROTOCOLS = '101 SWITCHING_PROTOCOLS',
    _102PROCESSING = '102 PROCESSING',
    _103CHECKPOINT = '103 CHECKPOINT',
    _200OK = '200 OK',
    _201CREATED = '201 CREATED',
    _202ACCEPTED = '202 ACCEPTED',
    _203NONAUTHORITATIVEINFORMATION = '203 NON_AUTHORITATIVE_INFORMATION',
    _204NOCONTENT = '204 NO_CONTENT',
    _205RESETCONTENT = '205 RESET_CONTENT',
    _206PARTIALCONTENT = '206 PARTIAL_CONTENT',
    _207MULTISTATUS = '207 MULTI_STATUS',
    _208ALREADYREPORTED = '208 ALREADY_REPORTED',
    _226IMUSED = '226 IM_USED',
    _300MULTIPLECHOICES = '300 MULTIPLE_CHOICES',
    _301MOVEDPERMANENTLY = '301 MOVED_PERMANENTLY',
    _302FOUND = '302 FOUND',
    _302MOVEDTEMPORARILY = '302 MOVED_TEMPORARILY',
    _303SEEOTHER = '303 SEE_OTHER',
    _304NOTMODIFIED = '304 NOT_MODIFIED',
    _305USEPROXY = '305 USE_PROXY',
    _307TEMPORARYREDIRECT = '307 TEMPORARY_REDIRECT',
    _308PERMANENTREDIRECT = '308 PERMANENT_REDIRECT',
    _400BADREQUEST = '400 BAD_REQUEST',
    _401UNAUTHORIZED = '401 UNAUTHORIZED',
    _402PAYMENTREQUIRED = '402 PAYMENT_REQUIRED',
    _403FORBIDDEN = '403 FORBIDDEN',
    _404NOTFOUND = '404 NOT_FOUND',
    _405METHODNOTALLOWED = '405 METHOD_NOT_ALLOWED',
    _406NOTACCEPTABLE = '406 NOT_ACCEPTABLE',
    _407PROXYAUTHENTICATIONREQUIRED = '407 PROXY_AUTHENTICATION_REQUIRED',
    _408REQUESTTIMEOUT = '408 REQUEST_TIMEOUT',
    _409CONFLICT = '409 CONFLICT',
    _410GONE = '410 GONE',
    _411LENGTHREQUIRED = '411 LENGTH_REQUIRED',
    _412PRECONDITIONFAILED = '412 PRECONDITION_FAILED',
    _413PAYLOADTOOLARGE = '413 PAYLOAD_TOO_LARGE',
    _413REQUESTENTITYTOOLARGE = '413 REQUEST_ENTITY_TOO_LARGE',
    _414URITOOLONG = '414 URI_TOO_LONG',
    _414REQUESTURITOOLONG = '414 REQUEST_URI_TOO_LONG',
    _415UNSUPPORTEDMEDIATYPE = '415 UNSUPPORTED_MEDIA_TYPE',
    _416REQUESTEDRANGENOTSATISFIABLE = '416 REQUESTED_RANGE_NOT_SATISFIABLE',
    _417EXPECTATIONFAILED = '417 EXPECTATION_FAILED',
    _418IAMATEAPOT = '418 I_AM_A_TEAPOT',
    _419INSUFFICIENTSPACEONRESOURCE = '419 INSUFFICIENT_SPACE_ON_RESOURCE',
    _420METHODFAILURE = '420 METHOD_FAILURE',
    _421DESTINATIONLOCKED = '421 DESTINATION_LOCKED',
    _422UNPROCESSABLEENTITY = '422 UNPROCESSABLE_ENTITY',
    _423LOCKED = '423 LOCKED',
    _424FAILEDDEPENDENCY = '424 FAILED_DEPENDENCY',
    _426UPGRADEREQUIRED = '426 UPGRADE_REQUIRED',
    _428PRECONDITIONREQUIRED = '428 PRECONDITION_REQUIRED',
    _429TOOMANYREQUESTS = '429 TOO_MANY_REQUESTS',
    _431REQUESTHEADERFIELDSTOOLARGE = '431 REQUEST_HEADER_FIELDS_TOO_LARGE',
    _451UNAVAILABLEFORLEGALREASONS = '451 UNAVAILABLE_FOR_LEGAL_REASONS',
    _500INTERNALSERVERERROR = '500 INTERNAL_SERVER_ERROR',
    _501NOTIMPLEMENTED = '501 NOT_IMPLEMENTED',
    _502BADGATEWAY = '502 BAD_GATEWAY',
    _503SERVICEUNAVAILABLE = '503 SERVICE_UNAVAILABLE',
    _504GATEWAYTIMEOUT = '504 GATEWAY_TIMEOUT',
    _505HTTPVERSIONNOTSUPPORTED = '505 HTTP_VERSION_NOT_SUPPORTED',
    _506VARIANTALSONEGOTIATES = '506 VARIANT_ALSO_NEGOTIATES',
    _507INSUFFICIENTSTORAGE = '507 INSUFFICIENT_STORAGE',
    _508LOOPDETECTED = '508 LOOP_DETECTED',
    _509BANDWIDTHLIMITEXCEEDED = '509 BANDWIDTH_LIMIT_EXCEEDED',
    _510NOTEXTENDED = '510 NOT_EXTENDED',
    _511NETWORKAUTHENTICATIONREQUIRED = '511 NETWORK_AUTHENTICATION_REQUIRED'
}

/**
 * An object that stores error information
 * @export
 * @interface RestApiError
 */
export interface RestApiError {
    /**
     * 
     * @type {string}
     * @memberof RestApiError
     */
    error?: RestApiErrorErrorEnum;
    /**
     * 
     * @type {string}
     * @memberof RestApiError
     */
    errorCode?: RestApiErrorErrorCodeEnum;
    /**
     * 
     * @type {string}
     * @memberof RestApiError
     */
    message?: string;
    /**
     * 
     * @type {string}
     * @memberof RestApiError
     */
    path?: string;
    /**
     * 
     * @type {number}
     * @memberof RestApiError
     */
    status?: number;
    /**
     * 
     * @type {string}
     * @memberof RestApiError
     */
    timestamp?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum RestApiErrorErrorEnum {
    _100CONTINUE = '100 CONTINUE',
    _101SWITCHINGPROTOCOLS = '101 SWITCHING_PROTOCOLS',
    _102PROCESSING = '102 PROCESSING',
    _103CHECKPOINT = '103 CHECKPOINT',
    _200OK = '200 OK',
    _201CREATED = '201 CREATED',
    _202ACCEPTED = '202 ACCEPTED',
    _203NONAUTHORITATIVEINFORMATION = '203 NON_AUTHORITATIVE_INFORMATION',
    _204NOCONTENT = '204 NO_CONTENT',
    _205RESETCONTENT = '205 RESET_CONTENT',
    _206PARTIALCONTENT = '206 PARTIAL_CONTENT',
    _207MULTISTATUS = '207 MULTI_STATUS',
    _208ALREADYREPORTED = '208 ALREADY_REPORTED',
    _226IMUSED = '226 IM_USED',
    _300MULTIPLECHOICES = '300 MULTIPLE_CHOICES',
    _301MOVEDPERMANENTLY = '301 MOVED_PERMANENTLY',
    _302FOUND = '302 FOUND',
    _302MOVEDTEMPORARILY = '302 MOVED_TEMPORARILY',
    _303SEEOTHER = '303 SEE_OTHER',
    _304NOTMODIFIED = '304 NOT_MODIFIED',
    _305USEPROXY = '305 USE_PROXY',
    _307TEMPORARYREDIRECT = '307 TEMPORARY_REDIRECT',
    _308PERMANENTREDIRECT = '308 PERMANENT_REDIRECT',
    _400BADREQUEST = '400 BAD_REQUEST',
    _401UNAUTHORIZED = '401 UNAUTHORIZED',
    _402PAYMENTREQUIRED = '402 PAYMENT_REQUIRED',
    _403FORBIDDEN = '403 FORBIDDEN',
    _404NOTFOUND = '404 NOT_FOUND',
    _405METHODNOTALLOWED = '405 METHOD_NOT_ALLOWED',
    _406NOTACCEPTABLE = '406 NOT_ACCEPTABLE',
    _407PROXYAUTHENTICATIONREQUIRED = '407 PROXY_AUTHENTICATION_REQUIRED',
    _408REQUESTTIMEOUT = '408 REQUEST_TIMEOUT',
    _409CONFLICT = '409 CONFLICT',
    _410GONE = '410 GONE',
    _411LENGTHREQUIRED = '411 LENGTH_REQUIRED',
    _412PRECONDITIONFAILED = '412 PRECONDITION_FAILED',
    _413PAYLOADTOOLARGE = '413 PAYLOAD_TOO_LARGE',
    _413REQUESTENTITYTOOLARGE = '413 REQUEST_ENTITY_TOO_LARGE',
    _414URITOOLONG = '414 URI_TOO_LONG',
    _414REQUESTURITOOLONG = '414 REQUEST_URI_TOO_LONG',
    _415UNSUPPORTEDMEDIATYPE = '415 UNSUPPORTED_MEDIA_TYPE',
    _416REQUESTEDRANGENOTSATISFIABLE = '416 REQUESTED_RANGE_NOT_SATISFIABLE',
    _417EXPECTATIONFAILED = '417 EXPECTATION_FAILED',
    _418IAMATEAPOT = '418 I_AM_A_TEAPOT',
    _419INSUFFICIENTSPACEONRESOURCE = '419 INSUFFICIENT_SPACE_ON_RESOURCE',
    _420METHODFAILURE = '420 METHOD_FAILURE',
    _421DESTINATIONLOCKED = '421 DESTINATION_LOCKED',
    _422UNPROCESSABLEENTITY = '422 UNPROCESSABLE_ENTITY',
    _423LOCKED = '423 LOCKED',
    _424FAILEDDEPENDENCY = '424 FAILED_DEPENDENCY',
    _426UPGRADEREQUIRED = '426 UPGRADE_REQUIRED',
    _428PRECONDITIONREQUIRED = '428 PRECONDITION_REQUIRED',
    _429TOOMANYREQUESTS = '429 TOO_MANY_REQUESTS',
    _431REQUESTHEADERFIELDSTOOLARGE = '431 REQUEST_HEADER_FIELDS_TOO_LARGE',
    _451UNAVAILABLEFORLEGALREASONS = '451 UNAVAILABLE_FOR_LEGAL_REASONS',
    _500INTERNALSERVERERROR = '500 INTERNAL_SERVER_ERROR',
    _501NOTIMPLEMENTED = '501 NOT_IMPLEMENTED',
    _502BADGATEWAY = '502 BAD_GATEWAY',
    _503SERVICEUNAVAILABLE = '503 SERVICE_UNAVAILABLE',
    _504GATEWAYTIMEOUT = '504 GATEWAY_TIMEOUT',
    _505HTTPVERSIONNOTSUPPORTED = '505 HTTP_VERSION_NOT_SUPPORTED',
    _506VARIANTALSONEGOTIATES = '506 VARIANT_ALSO_NEGOTIATES',
    _507INSUFFICIENTSTORAGE = '507 INSUFFICIENT_STORAGE',
    _508LOOPDETECTED = '508 LOOP_DETECTED',
    _509BANDWIDTHLIMITEXCEEDED = '509 BANDWIDTH_LIMIT_EXCEEDED',
    _510NOTEXTENDED = '510 NOT_EXTENDED',
    _511NETWORKAUTHENTICATIONREQUIRED = '511 NETWORK_AUTHENTICATION_REQUIRED'
}
/**
    * @export
    * @enum {string}
    */
export enum RestApiErrorErrorCodeEnum {
    INTERNAL = 'INTERNAL',
    BPMNAPI = 'BPMN_API',
    DOCSTOREAPI = 'DOC_STORE_API',
    MDMAPI = 'MDM_API',
    NOTIFICATIONAPI = 'NOTIFICATION_API',
    SEARCHAPI = 'SEARCH_API',
    ECMAPI = 'ECM_API',
    ECMGETDOCUMENT = 'ECM_GET_DOCUMENT',
    CHATDOESNOTEXISTS = 'CHAT_DOES_NOT_EXISTS',
    CLIENTDOESNOTEXISTS = 'CLIENT_DOES_NOT_EXISTS',
    SURVEYCANNOTBEFINISHED = 'SURVEY_CANNOT_BE_FINISHED',
    CLIENTSURVEYNOTEXISTS = 'CLIENT_SURVEY_NOT_EXISTS',
    SURVEYUPDATECANNOTBEPERFORMEDCAUSECLIENTSURVEYNOTEXISTS = 'SURVEY_UPDATE_CANNOT_BE_PERFORMED_CAUSE_CLIENT_SURVEY_NOT_EXISTS',
    SURVEYANSWERDOESNOTEXISTS = 'SURVEY_ANSWER_DOES_NOT_EXISTS',
    SURVEYQUESTIONDOESNOTEXISTS = 'SURVEY_QUESTION_DOES_NOT_EXISTS',
    SURVEYGROUPDOESNOTEXISTS = 'SURVEY_GROUP_DOES_NOT_EXISTS',
    FILEMIMETYPECANNOTBEDETECTED = 'FILE_MIME_TYPE_CANNOT_BE_DETECTED',
    SURVEYRESULTDOESNOTEXISTSFORTHISCLIENT = 'SURVEY_RESULT_DOES_NOT_EXISTS_FOR_THIS_CLIENT',
    SURVEYDOESNOTEXISTS = 'SURVEY_DOES_NOT_EXISTS',
    SURVEYCANNOTBECOMPLETEDCAUSENOSURVEYINPROGRESS = 'SURVEY_CANNOT_BE_COMPLETED_CAUSE_NO_SURVEY_IN_PROGRESS',
    SURVEYCANNOTBECOMPLETEDCAUSESURVEYISFINISHED = 'SURVEY_CANNOT_BE_COMPLETED_CAUSE_SURVEY_IS_FINISHED',
    DIAGRAMFILECANNOTBESAVED = 'DIAGRAM_FILE_CANNOT_BE_SAVED',
    SURVEYCANNOTBEFINISHEDCAUSEANSWERSAREINCORRECT = 'SURVEY_CANNOT_BE_FINISHED_CAUSE_ANSWERS_ARE_INCORRECT',
    ERROROCCURREDWHILESUBMITTINGANSWERSURVEYNOTFOUND = 'ERROR_OCCURRED_WHILE_SUBMITTING_ANSWER_SURVEY_NOT_FOUND',
    ERROROCCURREDWHILESUBMITTINGANSWERSURVEYFINISHED = 'ERROR_OCCURRED_WHILE_SUBMITTING_ANSWER_SURVEY_FINISHED',
    LEADIDISNOTVALID = 'LEAD_ID_IS_NOT_VALID',
    PROFILEDOESNOTEXISTS = 'PROFILE_DOES_NOT_EXISTS',
    ANSWERDOESNOTEXISTS = 'ANSWER_DOES_NOT_EXISTS',
    MANAGERCANNOTACCESSTHISCLIENT = 'MANAGER_CANNOT_ACCESS_THIS_CLIENT',
    PROFILEISBEINGPROCESSED = 'PROFILE_IS_BEING_PROCESSED',
    PROFILEVERIFIED = 'PROFILE_VERIFIED',
    PROFILEDECLINED = 'PROFILE_DECLINED',
    PROFILEPRESENTATIONDATACANNOTBESAVED = 'PROFILE_PRESENTATION_DATA_CAN_NOT_BE_SAVED',
    PROFILEPRESENTATIONMIMETYPECANNOTBEDETECTED = 'PROFILE_PRESENTATION_MIME_TYPE_CAN_NOT_BE_DETECTED',
    PROFILEPRESENTATIONFILETYPENOTALLOWEDHAVETOBEONEOFPDFODPPPTPPTX = 'PROFILE_PRESENTATION_FILE_TYPE_NOT_ALLOWED_HAVE_TO_BE_ONE_OF_PDF_ODP_PPT_PPTX',
    RESULTDOCUMENTCANNOTBEGENERATEDCAUSECLIENTSURVEYDOESNOTEXIST = 'RESULT_DOCUMENT_CANNOT_BE_GENERATED_CAUSE_CLIENT_SURVEY_DOES_NOT_EXIST',
    RESULTDOCUMENTCANNOTBEGENERATEDCAUSEDIAGRAMDOESNOTEXIST = 'RESULT_DOCUMENT_CANNOT_BE_GENERATED_CAUSE_DIAGRAM_DOES_NOT_EXIST',
    RESULTDOCUMENTCANNOTBEGENERATEDCAUSESURVEYRESULTSDOESNOTEXIST = 'RESULT_DOCUMENT_CANNOT_BE_GENERATED_CAUSE_SURVEY_RESULTS_DOES_NOT_EXIST',
    CERTIFICATECANNOTBEGENERATEDCAUSECLIENTSURVEYDOESNOTEXIST = 'CERTIFICATE_CANNOT_BE_GENERATED_CAUSE_CLIENT_SURVEY_DOES_NOT_EXIST',
    IOEXCEPTION = 'IO_EXCEPTION',
    MEMORANDUMTEXTDOESNOTEXISTS = 'MEMORANDUM_TEXT_DOES_NOT_EXISTS',
    ROADMAPFORTHISCLIENTNOTFOUND = 'ROAD_MAP_FOR_THIS_CLIENT_NOT_FOUND',
    PROCESSINSTANCEIDISNULL = 'PROCESS_INSTANCE_ID_IS_NULL',
    ANERROROCCURREDDURINGPROCESSINGJSON = 'AN_ERROR_OCCURRED_DURING_PROCESSING_JSON',
    ANERROROCCURREDDURINGINVOCATIONPROCESSESAPI = 'AN_ERROR_OCCURRED_DURING_INVOCATION_PROCESSES_API',
    ROADMAPSDOESNOTEXISTS = 'ROAD_MAPS_DOES_NOT_EXISTS',
    CANNOTBEAPPROVEDCAUSESERVICEDOESNOTEXISTS = 'CANNOT_BE_APPROVED_CAUSE_SERVICE_DOES_NOT_EXISTS',
    CANNOTBEAPPROVEDBYCLIENTCAUSENOTSUCCESSFUL = 'CAN_NOT_BE_APPROVED_BY_CLIENT_CAUSE_NOT_SUCCESSFUL',
    CANNOTBEAPPROVEDBYMANAGERCAUSENOTAPPROVEDBYCLIENT = 'CAN_NOT_BE_APPROVED_BY_MANAGER_CAUSE_NOT_APPROVED_BY_CLIENT',
    ROADMAPSERVICECANNOTBECOMPLETEDCAUSEITININCORRECTSTATE = 'ROAD_MAP_SERVICE_CANNOT_BE_COMPLETED_CAUSE_IT_IN_INCORRECT_STATE',
    ROADMAPSERVICECANNOTBECOMPLETEDCAUSEITDOESNTEXIST = 'ROAD_MAP_SERVICE_CANNOT_BE_COMPLETED_CAUSE_IT_DOESN_T_EXIST',
    ROADMAPSERVICECANNOTBEADDEDCAUSESOMEOFITSEXISTSALREADY = 'ROAD_MAP_SERVICE_CANNOT_BE_ADDED_CAUSE_SOME_OF_ITS_EXISTS_ALREADY',
    ROADMAPSERVICECANNOTBEADDEDCAUSEOFWRONGSERVICEID = 'ROAD_MAP_SERVICE_CANNOT_BE_ADDED_CAUSE_OF_WRONG_SERVICE_ID',
    ROADMAPSERVICECANNOTBEDELETED = 'ROAD_MAP_SERVICE_CANNOT_BE_DELETED',
    ROADMAPSERVICECANNOTBESTARTED = 'ROAD_MAP_SERVICE_CANNOT_BE_STARTED',
    ROADMAPSERVICECANNOTBESTARTEDREQUIREDROADMAPSTEPSINCOMPLETE = 'ROAD_MAP_SERVICE_CANNOT_BE_STARTED_REQUIRED_ROAD_MAP_STEPS_INCOMPLETE',
    ROADMAPSERVICEPLANENDDATECANNOTBEUPDATED = 'ROAD_MAP_SERVICE_PLAN_END_DATE_CAN_NOT_BE_UPDATED',
    ROADMAPSTAGEDOESNOTEXIST = 'ROAD_MAP_STAGE_DOES_NOT_EXIST',
    ROADMAPSERVICEDOESNOTEXIST = 'ROAD_MAP_SERVICE_DOES_NOT_EXIST',
    ROADMAPSTEPDOESNOTEXIST = 'ROAD_MAP_STEP_DOES_NOT_EXIST',
    ROADMAPSTEPCANNOTBEFINISHEDROADMAPSTAGESINCOMPLETE = 'ROAD_MAP_STEP_CANNOT_BE_FINISHED_ROAD_MAP_STAGES_INCOMPLETE',
    ROADMAPSTEPATTACHMENTMIMETYPECANNOTBEDETECTED = 'ROAD_MAP_STEP_ATTACHMENT_MIME_TYPE_CAN_NOT_BE_DETECTED',
    ROADMAPCANNOTBEDECLINEDALREADYAPPROVED = 'ROAD_MAP_CANNOT_BE_DECLINED_ALREADY_APPROVED',
    ROADMAPCANNOTBEDECLINEDALREADYBLOCKED = 'ROAD_MAP_CANNOT_BE_DECLINED_ALREADY_BLOCKED',
    SURVEYACTIVEMETALIMITNOTFOUND = 'SURVEY_ACTIVE_META_LIMIT_NOT_FOUND',
    CSVREPORTGENERATIONERROR = 'CSV_REPORT_GENERATION_ERROR',
    CSVREPORTTYPENOTEXISTS = 'CSV_REPORT_TYPE_NOT_EXISTS',
    ADDMARKETTOROADMAPERROR = 'ADD_MARKET_TO_ROAD_MAP_ERROR',
    ADDPRODUCTTOROADMAPERROR = 'ADD_PRODUCT_TO_ROAD_MAP_ERROR',
    CATALOGITEMBYCODENOTFOUNDORNONUNIQUE = 'CATALOG_ITEM_BY_CODE_NOT_FOUND_OR_NON_UNIQUE'
}

/**
 * An object that stores road map information
 * @export
 * @interface RoadMapDto
 */
export interface RoadMapDto {
    /**
     * Road map approved status
     * @type {boolean}
     * @memberof RoadMapDto
     */
    approved?: boolean;
    /**
     * Road map blocked status
     * @type {boolean}
     * @memberof RoadMapDto
     */
    blocked?: boolean;
    /**
     * Road map declined status
     * @type {boolean}
     * @memberof RoadMapDto
     */
    declined?: boolean;
    /**
     * Road map fact end date and time
     * @type {string}
     * @memberof RoadMapDto
     */
    factEndDateTime?: string;
    /**
     * Road map fact start date and time
     * @type {string}
     * @memberof RoadMapDto
     */
    factStartDateTime?: string;
    /**
     * Road map id
     * @type {number}
     * @memberof RoadMapDto
     */
    id: number;
    /**
     * Road map stages
     * @type {Array<RoadMapStepDto>}
     * @memberof RoadMapDto
     */
    steps: Array<RoadMapStepDto>;
}
/**
 * An object that stores road map market information
 * @export
 * @interface RoadMapMarketDto
 */
export interface RoadMapMarketDto {
    /**
     * Country name
     * @type {string}
     * @memberof RoadMapMarketDto
     */
    caption: string;
    /**
     * Country Oktmo code
     * @type {string}
     * @memberof RoadMapMarketDto
     */
    code: string;
}
/**
 * An object that stores road map product information
 * @export
 * @interface RoadMapProductDto
 */
export interface RoadMapProductDto {
    /**
     * Tnved caption
     * @type {string}
     * @memberof RoadMapProductDto
     */
    caption: string;
    /**
     * Tnved code
     * @type {string}
     * @memberof RoadMapProductDto
     */
    code: string;
}
/**
 * An object that stores road map service information
 * @export
 * @interface RoadMapProductServiceDto
 */
export interface RoadMapProductServiceDto {
    /**
     * Okved caption
     * @type {string}
     * @memberof RoadMapProductServiceDto
     */
    caption: string;
    /**
     * Okved code
     * @type {string}
     * @memberof RoadMapProductServiceDto
     */
    code: string;
}
/**
 * An object that stores road map steps report client data information
 * @export
 * @interface RoadMapReportClientDto
 */
export interface RoadMapReportClientDto {
    /**
     * Road map service bpm status
     * @type {string}
     * @memberof RoadMapReportClientDto
     */
    bpmStatus?: string;
    /**
     * Client manager FIO
     * @type {string}
     * @memberof RoadMapReportClientDto
     */
    clientManagerFio?: string;
    /**
     * Road map percentage complete
     * @type {number}
     * @memberof RoadMapReportClientDto
     */
    completePercentage?: number;
    /**
     * INN
     * @type {string}
     * @memberof RoadMapReportClientDto
     */
    inn?: string;
    /**
     * Market country
     * @type {string}
     * @memberof RoadMapReportClientDto
     */
    marketCountry?: string;
    /**
     * Market product
     * @type {string}
     * @memberof RoadMapReportClientDto
     */
    marketProduct?: string;
    /**
     * Memorandum sign date
     * @type {string}
     * @memberof RoadMapReportClientDto
     */
    memorandumSignDate?: string;
    /**
     * Organization name
     * @type {string}
     * @memberof RoadMapReportClientDto
     */
    orgName?: string;
    /**
     * Profile bpm status
     * @type {string}
     * @memberof RoadMapReportClientDto
     */
    processStep?: string;
    /**
     * Region
     * @type {string}
     * @memberof RoadMapReportClientDto
     */
    region?: string;
    /**
     * Road map service
     * @type {string}
     * @memberof RoadMapReportClientDto
     */
    roadMapService?: string;
    /**
     * Road map service duration in minutes
     * @type {number}
     * @memberof RoadMapReportClientDto
     */
    roadMapServiceDuration?: number;
    /**
     * Road map service history
     * @type {string}
     * @memberof RoadMapReportClientDto
     */
    roadMapServiceHistory?: string;
    /**
     * Road map service start date
     * @type {string}
     * @memberof RoadMapReportClientDto
     */
    roadMapServiceStartDate?: string;
    /**
     * Road map service status
     * @type {string}
     * @memberof RoadMapReportClientDto
     */
    roadMapServiceStatus?: string;
    /**
     * Road map stage
     * @type {string}
     * @memberof RoadMapReportClientDto
     */
    roadMapStage?: string;
    /**
     * Road map start date
     * @type {string}
     * @memberof RoadMapReportClientDto
     */
    roadMapStartDate?: string;
    /**
     * Road map step
     * @type {string}
     * @memberof RoadMapReportClientDto
     */
    roadMapStep?: string;
    /**
     * Survey date time
     * @type {string}
     * @memberof RoadMapReportClientDto
     */
    surveyDate?: string;
    /**
     * Survey rating value
     * @type {string}
     * @memberof RoadMapReportClientDto
     */
    surveyRating?: string;
    /**
     * Survey stop factors
     * @type {string}
     * @memberof RoadMapReportClientDto
     */
    surveyStopFactors?: string;
    /**
     * Road map tasks list
     * @type {Array<ReportTaskLinkDto>}
     * @memberof RoadMapReportClientDto
     */
    taskLinks?: Array<ReportTaskLinkDto>;
    /**
     * Tms tasks link
     * @type {string}
     * @memberof RoadMapReportClientDto
     */
    tmsTasksLink?: string;
    /**
     * Profile verify date
     * @type {string}
     * @memberof RoadMapReportClientDto
     */
    verifyDate?: string;
}
/**
 * An object that stores road map service information
 * @export
 * @interface RoadMapServiceDto
 */
export interface RoadMapServiceDto {
    /**
     * Road map service comment
     * @type {string}
     * @memberof RoadMapServiceDto
     */
    comment?: string;
    /**
     * Road map service duration
     * @type {number}
     * @memberof RoadMapServiceDto
     */
    duration?: number;
    /**
     * Road map service expired
     * @type {boolean}
     * @memberof RoadMapServiceDto
     */
    expired: boolean;
    /**
     * Road map service fact end date and time
     * @type {string}
     * @memberof RoadMapServiceDto
     */
    factEndDateTime?: string;
    /**
     * Road map service fact start date and time
     * @type {string}
     * @memberof RoadMapServiceDto
     */
    factStartDateTime?: string;
    /**
     * Road map service id
     * @type {number}
     * @memberof RoadMapServiceDto
     */
    id: number;
    /**
     * Road map service name
     * @type {string}
     * @memberof RoadMapServiceDto
     */
    name?: string;
    /**
     * Road map service note
     * @type {string}
     * @memberof RoadMapServiceDto
     */
    note?: string;
    /**
     * Road map service plan end date and time
     * @type {string}
     * @memberof RoadMapServiceDto
     */
    planEndDateTime?: string;
    /**
     * Road map service catalog id
     * @type {string}
     * @memberof RoadMapServiceDto
     */
    serviceId?: string;
    /**
     * 
     * @type {RoadMapServiceStatusDto}
     * @memberof RoadMapServiceDto
     */
    status: RoadMapServiceStatusDto;
    /**
     * Road map service url
     * @type {string}
     * @memberof RoadMapServiceDto
     */
    url?: string;
}
/**
 * An object that stores road map service information
 * @export
 * @interface RoadMapServiceInfoDto
 */
export interface RoadMapServiceInfoDto {
    /**
     * Road map service description
     * @type {string}
     * @memberof RoadMapServiceInfoDto
     */
    description?: string;
    /**
     * Road map service duration
     * @type {number}
     * @memberof RoadMapServiceInfoDto
     */
    duration?: number;
    /**
     * Road map service name
     * @type {string}
     * @memberof RoadMapServiceInfoDto
     */
    name?: string;
    /**
     * Process instance id
     * @type {string}
     * @memberof RoadMapServiceInfoDto
     */
    processInstanceId?: string;
}
/**
 * An object that stores response from road map service starting request
 * @export
 * @interface RoadMapServiceStartedDto
 */
export interface RoadMapServiceStartedDto {
    /**
     * Redirect url
     * @type {string}
     * @memberof RoadMapServiceStartedDto
     */
    redirectUrl?: string;
}
/**
 * An object that stores road map service status information
 * @export
 * @interface RoadMapServiceStatusDto
 */
export interface RoadMapServiceStatusDto {
    /**
     * Road map service status description
     * @type {string}
     * @memberof RoadMapServiceStatusDto
     */
    description?: string;
    /**
     * Road map service status label
     * @type {string}
     * @memberof RoadMapServiceStatusDto
     */
    label: RoadMapServiceStatusDtoLabelEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum RoadMapServiceStatusDtoLabelEnum {
    DRAFT = 'DRAFT',
    INPROGRESS = 'IN_PROGRESS',
    SUCCESSFUL = 'SUCCESSFUL',
    REFUSED = 'REFUSED',
    DENIED = 'DENIED',
    APPROVEDBYCLIENT = 'APPROVED_BY_CLIENT',
    APPROVEDBYMANAGER = 'APPROVED_BY_MANAGER'
}

/**
 * An object that stores planEndDateTime to update road map service
 * @export
 * @interface RoadMapServiceUpdatePlanEndDateTimeDto
 */
export interface RoadMapServiceUpdatePlanEndDateTimeDto {
    /**
     * Plan end date and time to set
     * @type {string}
     * @memberof RoadMapServiceUpdatePlanEndDateTimeDto
     */
    planEndDateTime: string;
    /**
     * Reason of postponement of service plan end date and time
     * @type {string}
     * @memberof RoadMapServiceUpdatePlanEndDateTimeDto
     */
    postponementReason: string;
}
/**
 * An object that stores road map stage information
 * @export
 * @interface RoadMapStageDto
 */
export interface RoadMapStageDto {
    /**
     * Road map stage comment
     * @type {string}
     * @memberof RoadMapStageDto
     */
    comment?: string;
    /**
     * Road map stage duration
     * @type {number}
     * @memberof RoadMapStageDto
     */
    duration?: number;
    /**
     * Road map stage fact end date and time
     * @type {string}
     * @memberof RoadMapStageDto
     */
    factEndDateTime?: string;
    /**
     * Road map stage fact start date and time
     * @type {string}
     * @memberof RoadMapStageDto
     */
    factStartDateTime?: string;
    /**
     * Road map stage goal
     * @type {string}
     * @memberof RoadMapStageDto
     */
    goal?: string;
    /**
     * Road map stage id
     * @type {number}
     * @memberof RoadMapStageDto
     */
    id: number;
    /**
     * Road map stage name
     * @type {string}
     * @memberof RoadMapStageDto
     */
    name?: string;
    /**
     * Road map stage plan end date and time
     * @type {string}
     * @memberof RoadMapStageDto
     */
    planEndDateTime?: string;
    /**
     * Road map stage resolution
     * @type {string}
     * @memberof RoadMapStageDto
     */
    resolution?: string;
    /**
     * Road map stage services
     * @type {Array<RoadMapServiceDto>}
     * @memberof RoadMapStageDto
     */
    services: Array<RoadMapServiceDto>;
    /**
     * Road map stage status
     * @type {string}
     * @memberof RoadMapStageDto
     */
    status?: RoadMapStageDtoStatusEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum RoadMapStageDtoStatusEnum {
    FINISHED = 'FINISHED'
}

/**
 * An object that stores planEndDateTime to update road map stage
 * @export
 * @interface RoadMapStageUpdatePlanEndDateTimeDto
 */
export interface RoadMapStageUpdatePlanEndDateTimeDto {
    /**
     * Plan end date and time to set
     * @type {string}
     * @memberof RoadMapStageUpdatePlanEndDateTimeDto
     */
    planEndDateTime: string;
}
/**
 * An object that stores road map step information
 * @export
 * @interface RoadMapStepDto
 */
export interface RoadMapStepDto {
    /**
     * Flag if road map step can be finished
     * @type {boolean}
     * @memberof RoadMapStepDto
     */
    canFinish?: boolean;
    /**
     * Road map step comment
     * @type {string}
     * @memberof RoadMapStepDto
     */
    comment?: string;
    /**
     * Road map step finish comment
     * @type {string}
     * @memberof RoadMapStepDto
     */
    finishComment?: string;
    /**
     * Road map step id
     * @type {number}
     * @memberof RoadMapStepDto
     */
    id: number;
    /**
     * Road map step name
     * @type {string}
     * @memberof RoadMapStepDto
     */
    name?: string;
    /**
     * Road map step rate
     * @type {number}
     * @memberof RoadMapStepDto
     */
    rate?: number;
    /**
     * Road map step stages
     * @type {Array<RoadMapStageDto>}
     * @memberof RoadMapStepDto
     */
    stages: Array<RoadMapStageDto>;
    /**
     * Road map step status
     * @type {string}
     * @memberof RoadMapStepDto
     */
    status?: RoadMapStepDtoStatusEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum RoadMapStepDtoStatusEnum {
    FINISHED = 'FINISHED',
    NOTFINISHED = 'NOT_FINISHED'
}

/**
 * An object that stores road map step result information
 * @export
 * @interface RoadMapStepResultDto
 */
export interface RoadMapStepResultDto {
    /**
     * Road map step comment
     * @type {string}
     * @memberof RoadMapStepResultDto
     */
    comment?: string;
    /**
     * Road map step name
     * @type {string}
     * @memberof RoadMapStepResultDto
     */
    name?: string;
    /**
     * Road map step rate
     * @type {number}
     * @memberof RoadMapStepResultDto
     */
    rate?: number;
    /**
     * Road map step status
     * @type {string}
     * @memberof RoadMapStepResultDto
     */
    status?: RoadMapStepResultDtoStatusEnum;
    /**
     * Road map step id
     * @type {number}
     * @memberof RoadMapStepResultDto
     */
    stepId: number;
}

/**
    * @export
    * @enum {string}
    */
export enum RoadMapStepResultDtoStatusEnum {
    FINISHED = 'FINISHED',
    NOTFINISHED = 'NOT_FINISHED'
}

/**
 * An object that stores road map steps data information
 * @export
 * @interface RoadMapStepsProgressReportDataDto
 */
export interface RoadMapStepsProgressReportDataDto {
    /**
     * Potential clients list data
     * @type {Array<RoadMapReportClientDto>}
     * @memberof RoadMapStepsProgressReportDataDto
     */
    clients?: Array<RoadMapReportClientDto>;
}
/**
 * An object that stores country search request
 * @export
 * @interface SearchCountriesRequest
 */
export interface SearchCountriesRequest {
    /**
     * Country name
     * @type {string}
     * @memberof SearchCountriesRequest
     */
    name: string;
}
/**
 * 
 * @export
 * @interface SearchRequestDto
 */
export interface SearchRequestDto {
    /**
     * Search query
     * @type {string}
     * @memberof SearchRequestDto
     */
    query: string;
    /**
     * Search type
     * @type {string}
     * @memberof SearchRequestDto
     */
    type: SearchRequestDtoTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum SearchRequestDtoTypeEnum {
    TNVED = 'TNVED',
    OKVED = 'OKVED'
}

/**
 * 
 * @export
 * @interface SearchResultDto
 */
export interface SearchResultDto {
    /**
     * 
     * @type {string}
     * @memberof SearchResultDto
     */
    caption?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchResultDto
     */
    code?: string;
}
/**
 * An object that stores service information
 * @export
 * @interface ServiceDto
 */
export interface ServiceDto {
    /**
     * Service id
     * @type {string}
     * @memberof ServiceDto
     */
    id: string;
    /**
     * Service name
     * @type {string}
     * @memberof ServiceDto
     */
    name: string;
    /**
     * Service parent id
     * @type {string}
     * @memberof ServiceDto
     */
    parentId: string;
}
/**
 * An object that stores service information with interfaceUrl
 * @export
 * @interface ServiceItemDto
 */
export interface ServiceItemDto {
    /**
     * Service id
     * @type {string}
     * @memberof ServiceItemDto
     */
    id: string;
    /**
     * Service interface url
     * @type {string}
     * @memberof ServiceItemDto
     */
    interfaceUrl: string;
    /**
     * Service name
     * @type {string}
     * @memberof ServiceItemDto
     */
    name: string;
    /**
     * Service parent id
     * @type {string}
     * @memberof ServiceItemDto
     */
    parentId: string;
}
/**
 * Object storing the new note value for the service
 * @export
 * @interface ServiceNoteRequestDto
 */
export interface ServiceNoteRequestDto {
    /**
     * 
     * @type {string}
     * @memberof ServiceNoteRequestDto
     */
    note?: string;
}
/**
 * An object that stores survey answer
 * @export
 * @interface SurveyAnswerDto
 */
export interface SurveyAnswerDto {
    /**
     * Answer value
     * @type {string}
     * @memberof SurveyAnswerDto
     */
    answer: string;
    /**
     * Answer weight
     * @type {number}
     * @memberof SurveyAnswerDto
     */
    answerWeight: number;
    /**
     * Answer identifier
     * @type {number}
     * @memberof SurveyAnswerDto
     */
    id: number;
}
/**
 * An object that stores submit survey answer information
 * @export
 * @interface SurveyAnswerRequestDto
 */
export interface SurveyAnswerRequestDto {
    /**
     * Answer identifier
     * @type {number}
     * @memberof SurveyAnswerRequestDto
     */
    answerId: number;
    /**
     * Group identifier
     * @type {number}
     * @memberof SurveyAnswerRequestDto
     */
    groupId: number;
    /**
     * Question identifier
     * @type {number}
     * @memberof SurveyAnswerRequestDto
     */
    questionId: number;
}
/**
 * An object that stores survey answer
 * @export
 * @interface SurveyAnswerResponseDto
 */
export interface SurveyAnswerResponseDto {
    /**
     * Answer advice
     * @type {string}
     * @memberof SurveyAnswerResponseDto
     */
    advice?: string;
    /**
     * Answer value
     * @type {string}
     * @memberof SurveyAnswerResponseDto
     */
    answer: string;
    /**
     * Answer identifier
     * @type {number}
     * @memberof SurveyAnswerResponseDto
     */
    answerId: number;
    /**
     * Answer weight
     * @type {number}
     * @memberof SurveyAnswerResponseDto
     */
    answerWeight: number;
    /**
     * Answer selected
     * @type {boolean}
     * @memberof SurveyAnswerResponseDto
     */
    selected: boolean;
    /**
     * Answer sequence
     * @type {number}
     * @memberof SurveyAnswerResponseDto
     */
    sequence: number;
}
/**
 * An object that stores answered survey question data
 * @export
 * @interface SurveyAnsweredDto
 */
export interface SurveyAnsweredDto {
    /**
     * Answer identifier
     * @type {number}
     * @memberof SurveyAnsweredDto
     */
    answerId: number;
    /**
     * Question identifier
     * @type {number}
     * @memberof SurveyAnsweredDto
     */
    questionId: number;
}
/**
 * An object that stores survey complete result
 * @export
 * @interface SurveyCompleteResultDto
 */
export interface SurveyCompleteResultDto {
    /**
     * Survey decision
     * @type {string}
     * @memberof SurveyCompleteResultDto
     */
    decision: SurveyCompleteResultDtoDecisionEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum SurveyCompleteResultDtoDecisionEnum {
    Passed = 'passed',
    Failed = 'failed'
}

/**
 * An object that stores survey status
 * @export
 * @interface SurveyExistingDto
 */
export interface SurveyExistingDto {
    /**
     * Existing status
     * @type {string}
     * @memberof SurveyExistingDto
     */
    status: SurveyExistingDtoStatusEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum SurveyExistingDtoStatusEnum {
    EXISTS = 'EXISTS',
    NOTEXISTS = 'NOT_EXISTS'
}

/**
 * An object that stores survey group
 * @export
 * @interface SurveyGroupDto
 */
export interface SurveyGroupDto {
    /**
     * Questions group weight
     * @type {number}
     * @memberof SurveyGroupDto
     */
    groupWeight: number;
    /**
     * Questions group identifier
     * @type {number}
     * @memberof SurveyGroupDto
     */
    id: number;
    /**
     * Questions group name
     * @type {string}
     * @memberof SurveyGroupDto
     */
    name: string;
    /**
     * All group questions
     * @type {Array<SurveyQuestionDto>}
     * @memberof SurveyGroupDto
     */
    surveyQuestions: Array<SurveyQuestionDto>;
}
/**
 * An object that stores list of survey results information
 * @export
 * @interface SurveyGroupsResponseDto
 */
export interface SurveyGroupsResponseDto {
    /**
     * Is completed
     * @type {boolean}
     * @memberof SurveyGroupsResponseDto
     */
    completed?: boolean;
    /**
     * Rating
     * @type {number}
     * @memberof SurveyGroupsResponseDto
     */
    rating?: number;
    /**
     * List of survey results
     * @type {Array<SurveyResultResponseDto>}
     * @memberof SurveyGroupsResponseDto
     */
    surveyGroups: Array<SurveyResultResponseDto>;
}
/**
 * An object that stores survey question
 * @export
 * @interface SurveyQuestionDto
 */
export interface SurveyQuestionDto {
    /**
     * Question identifier
     * @type {number}
     * @memberof SurveyQuestionDto
     */
    id: number;
    /**
     * Question value
     * @type {string}
     * @memberof SurveyQuestionDto
     */
    question: string;
    /**
     * Question weight
     * @type {number}
     * @memberof SurveyQuestionDto
     */
    questionWeight: number;
    /**
     * Possible question answers
     * @type {Array<SurveyAnswerDto>}
     * @memberof SurveyQuestionDto
     */
    surveyAnswers: Array<SurveyAnswerDto>;
}
/**
 * An object that stores survey question
 * @export
 * @interface SurveyQuestionResponseDto
 */
export interface SurveyQuestionResponseDto {
    /**
     * Possible question answers
     * @type {Array<SurveyAnswerResponseDto>}
     * @memberof SurveyQuestionResponseDto
     */
    answers: Array<SurveyAnswerResponseDto>;
    /**
     * Question value
     * @type {string}
     * @memberof SurveyQuestionResponseDto
     */
    question: string;
    /**
     * Question identifier
     * @type {number}
     * @memberof SurveyQuestionResponseDto
     */
    questionId: number;
    /**
     * Question weight
     * @type {number}
     * @memberof SurveyQuestionResponseDto
     */
    questionWeight: number;
    /**
     * Question sequence
     * @type {number}
     * @memberof SurveyQuestionResponseDto
     */
    sequence: number;
}
/**
 * An object that stores survey result data
 * @export
 * @interface SurveyResultDto
 */
export interface SurveyResultDto {
    /**
     * Answer identifier
     * @type {number}
     * @memberof SurveyResultDto
     */
    answerId: number;
    /**
     * Group identifier
     * @type {number}
     * @memberof SurveyResultDto
     */
    groupId: number;
    /**
     * Question identifier
     * @type {number}
     * @memberof SurveyResultDto
     */
    questionId: number;
}
/**
 * An object that stores survey result information
 * @export
 * @interface SurveyResultResponseDto
 */
export interface SurveyResultResponseDto {
    /**
     * Survey result group id
     * @type {number}
     * @memberof SurveyResultResponseDto
     */
    groupId: number;
    /**
     * Survey result group weight
     * @type {number}
     * @memberof SurveyResultResponseDto
     */
    groupWeight: number;
    /**
     * Survey result group name
     * @type {string}
     * @memberof SurveyResultResponseDto
     */
    name: string;
    /**
     * Survey result questions
     * @type {Array<SurveyQuestionResponseDto>}
     * @memberof SurveyResultResponseDto
     */
    questions: Array<SurveyQuestionResponseDto>;
    /**
     * Survey result sequence
     * @type {number}
     * @memberof SurveyResultResponseDto
     */
    sequence: number;
}
/**
 * 
 * @export
 * @interface URI
 */
export interface URI {
    /**
     * 
     * @type {boolean}
     * @memberof URI
     */
    absolute?: boolean;
    /**
     * 
     * @type {string}
     * @memberof URI
     */
    authority?: string;
    /**
     * 
     * @type {string}
     * @memberof URI
     */
    fragment?: string;
    /**
     * 
     * @type {string}
     * @memberof URI
     */
    host?: string;
    /**
     * 
     * @type {boolean}
     * @memberof URI
     */
    opaque?: boolean;
    /**
     * 
     * @type {string}
     * @memberof URI
     */
    path?: string;
    /**
     * 
     * @type {number}
     * @memberof URI
     */
    port?: number;
    /**
     * 
     * @type {string}
     * @memberof URI
     */
    query?: string;
    /**
     * 
     * @type {string}
     * @memberof URI
     */
    rawAuthority?: string;
    /**
     * 
     * @type {string}
     * @memberof URI
     */
    rawFragment?: string;
    /**
     * 
     * @type {string}
     * @memberof URI
     */
    rawPath?: string;
    /**
     * 
     * @type {string}
     * @memberof URI
     */
    rawQuery?: string;
    /**
     * 
     * @type {string}
     * @memberof URI
     */
    rawSchemeSpecificPart?: string;
    /**
     * 
     * @type {string}
     * @memberof URI
     */
    rawUserInfo?: string;
    /**
     * 
     * @type {string}
     * @memberof URI
     */
    scheme?: string;
    /**
     * 
     * @type {string}
     * @memberof URI
     */
    schemeSpecificPart?: string;
    /**
     * 
     * @type {string}
     * @memberof URI
     */
    userInfo?: string;
}
/**
 * 
 * @export
 * @interface URL
 */
export interface URL {
    /**
     * 
     * @type {string}
     * @memberof URL
     */
    authority?: string;
    /**
     * 
     * @type {object}
     * @memberof URL
     */
    content?: object;
    /**
     * 
     * @type {number}
     * @memberof URL
     */
    defaultPort?: number;
    /**
     * 
     * @type {object}
     * @memberof URL
     */
    deserializedFields?: object;
    /**
     * 
     * @type {string}
     * @memberof URL
     */
    file?: string;
    /**
     * 
     * @type {string}
     * @memberof URL
     */
    host?: string;
    /**
     * 
     * @type {string}
     * @memberof URL
     */
    path?: string;
    /**
     * 
     * @type {number}
     * @memberof URL
     */
    port?: number;
    /**
     * 
     * @type {string}
     * @memberof URL
     */
    protocol?: string;
    /**
     * 
     * @type {string}
     * @memberof URL
     */
    query?: string;
    /**
     * 
     * @type {string}
     * @memberof URL
     */
    ref?: string;
    /**
     * 
     * @type {number}
     * @memberof URL
     */
    serializedHashCode?: number;
    /**
     * 
     * @type {string}
     * @memberof URL
     */
    userInfo?: string;
}

/**
 * ChatControllerV1Api - axios parameter creator
 * @export
 */
export const ChatControllerV1ApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get a client char
         * @summary Get a client chat
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClientChatUsingGET(options: any = {}): RequestArgs {
            const localVarPath = `/api/v1/chat`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ChatControllerV1Api - functional programming interface
 * @export
 */
export const ChatControllerV1ApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Get a client char
         * @summary Get a client chat
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClientChatUsingGET(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChatDto> {
            const localVarAxiosArgs = ChatControllerV1ApiAxiosParamCreator(configuration).getClientChatUsingGET(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ChatControllerV1Api - factory interface
 * @export
 */
export const ChatControllerV1ApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * Get a client char
         * @summary Get a client chat
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClientChatUsingGET(options?: any): AxiosPromise<ChatDto> {
            return ChatControllerV1ApiFp(configuration).getClientChatUsingGET(options)(axios, basePath);
        },
    };
};

/**
 * ChatControllerV1Api - object-oriented interface
 * @export
 * @class ChatControllerV1Api
 * @extends {BaseAPI}
 */
export class ChatControllerV1Api extends BaseAPI {
    /**
     * Get a client char
     * @summary Get a client chat
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatControllerV1Api
     */
    public getClientChatUsingGET(options?: any) {
        return ChatControllerV1ApiFp(this.configuration).getClientChatUsingGET(options)(this.axios, this.basePath);
    }

}


/**
 * ClientControllerV1Api - axios parameter creator
 * @export
 */
export const ClientControllerV1ApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Decline client
         * @summary Decline client
         * @param {string} client client
         * @param {DeclineRequestDto} declineRequestDto declineRequestDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        declineClientUsingPOST(client: string, declineRequestDto: DeclineRequestDto, options: any = {}): RequestArgs {
            // verify required parameter 'client' is not null or undefined
            if (client === null || client === undefined) {
                throw new RequiredError('client','Required parameter client was null or undefined when calling declineClientUsingPOST.');
            }
            // verify required parameter 'declineRequestDto' is not null or undefined
            if (declineRequestDto === null || declineRequestDto === undefined) {
                throw new RequiredError('declineRequestDto','Required parameter declineRequestDto was null or undefined when calling declineClientUsingPOST.');
            }
            const localVarPath = `/api/v1/client/{client}/decline`
                .replace(`{${"client"}}`, encodeURIComponent(String(client)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof declineRequestDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(declineRequestDto !== undefined ? declineRequestDto : {}) : (declineRequestDto || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ClientControllerV1Api - functional programming interface
 * @export
 */
export const ClientControllerV1ApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Decline client
         * @summary Decline client
         * @param {string} client client
         * @param {DeclineRequestDto} declineRequestDto declineRequestDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        declineClientUsingPOST(client: string, declineRequestDto: DeclineRequestDto, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseEntity> {
            const localVarAxiosArgs = ClientControllerV1ApiAxiosParamCreator(configuration).declineClientUsingPOST(client, declineRequestDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ClientControllerV1Api - factory interface
 * @export
 */
export const ClientControllerV1ApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * Decline client
         * @summary Decline client
         * @param {string} client client
         * @param {DeclineRequestDto} declineRequestDto declineRequestDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        declineClientUsingPOST(client: string, declineRequestDto: DeclineRequestDto, options?: any): AxiosPromise<ResponseEntity> {
            return ClientControllerV1ApiFp(configuration).declineClientUsingPOST(client, declineRequestDto, options)(axios, basePath);
        },
    };
};

/**
 * ClientControllerV1Api - object-oriented interface
 * @export
 * @class ClientControllerV1Api
 * @extends {BaseAPI}
 */
export class ClientControllerV1Api extends BaseAPI {
    /**
     * Decline client
     * @summary Decline client
     * @param {string} client client
     * @param {DeclineRequestDto} declineRequestDto declineRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientControllerV1Api
     */
    public declineClientUsingPOST(client: string, declineRequestDto: DeclineRequestDto, options?: any) {
        return ClientControllerV1ApiFp(this.configuration).declineClientUsingPOST(client, declineRequestDto, options)(this.axios, this.basePath);
    }

}


/**
 * CountriesControllerV1Api - axios parameter creator
 * @export
 */
export const CountriesControllerV1ApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Search countries
         * @summary Search countries
         * @param {SearchCountriesRequest} request request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchCountriesUsingPOST(request: SearchCountriesRequest, options: any = {}): RequestArgs {
            // verify required parameter 'request' is not null or undefined
            if (request === null || request === undefined) {
                throw new RequiredError('request','Required parameter request was null or undefined when calling searchCountriesUsingPOST.');
            }
            const localVarPath = `/api/v1/countries/search`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof request !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(request !== undefined ? request : {}) : (request || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CountriesControllerV1Api - functional programming interface
 * @export
 */
export const CountriesControllerV1ApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Search countries
         * @summary Search countries
         * @param {SearchCountriesRequest} request request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchCountriesUsingPOST(request: SearchCountriesRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CountryDto>> {
            const localVarAxiosArgs = CountriesControllerV1ApiAxiosParamCreator(configuration).searchCountriesUsingPOST(request, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * CountriesControllerV1Api - factory interface
 * @export
 */
export const CountriesControllerV1ApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * Search countries
         * @summary Search countries
         * @param {SearchCountriesRequest} request request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchCountriesUsingPOST(request: SearchCountriesRequest, options?: any): AxiosPromise<Array<CountryDto>> {
            return CountriesControllerV1ApiFp(configuration).searchCountriesUsingPOST(request, options)(axios, basePath);
        },
    };
};

/**
 * CountriesControllerV1Api - object-oriented interface
 * @export
 * @class CountriesControllerV1Api
 * @extends {BaseAPI}
 */
export class CountriesControllerV1Api extends BaseAPI {
    /**
     * Search countries
     * @summary Search countries
     * @param {SearchCountriesRequest} request request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CountriesControllerV1Api
     */
    public searchCountriesUsingPOST(request: SearchCountriesRequest, options?: any) {
        return CountriesControllerV1ApiFp(this.configuration).searchCountriesUsingPOST(request, options)(this.axios, this.basePath);
    }

}


/**
 * DocumentControllerV1Api - axios parameter creator
 * @export
 */
export const DocumentControllerV1ApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get survey certificate document url
         * @summary Get survey certificate document url
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSurveyResultCertificateUsingGET(options: any = {}): RequestArgs {
            const localVarPath = `/api/v1/document/certificate/`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get survey results document url
         * @summary Get survey results document url
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSurveyResultsDocumentUsingGET(options: any = {}): RequestArgs {
            const localVarPath = `/api/v1/document/results/`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DocumentControllerV1Api - functional programming interface
 * @export
 */
export const DocumentControllerV1ApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Get survey certificate document url
         * @summary Get survey certificate document url
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSurveyResultCertificateUsingGET(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string> {
            const localVarAxiosArgs = DocumentControllerV1ApiAxiosParamCreator(configuration).getSurveyResultCertificateUsingGET(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get survey results document url
         * @summary Get survey results document url
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSurveyResultsDocumentUsingGET(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string> {
            const localVarAxiosArgs = DocumentControllerV1ApiAxiosParamCreator(configuration).getSurveyResultsDocumentUsingGET(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * DocumentControllerV1Api - factory interface
 * @export
 */
export const DocumentControllerV1ApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * Get survey certificate document url
         * @summary Get survey certificate document url
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSurveyResultCertificateUsingGET(options?: any): AxiosPromise<string> {
            return DocumentControllerV1ApiFp(configuration).getSurveyResultCertificateUsingGET(options)(axios, basePath);
        },
        /**
         * Get survey results document url
         * @summary Get survey results document url
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSurveyResultsDocumentUsingGET(options?: any): AxiosPromise<string> {
            return DocumentControllerV1ApiFp(configuration).getSurveyResultsDocumentUsingGET(options)(axios, basePath);
        },
    };
};

/**
 * DocumentControllerV1Api - object-oriented interface
 * @export
 * @class DocumentControllerV1Api
 * @extends {BaseAPI}
 */
export class DocumentControllerV1Api extends BaseAPI {
    /**
     * Get survey certificate document url
     * @summary Get survey certificate document url
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentControllerV1Api
     */
    public getSurveyResultCertificateUsingGET(options?: any) {
        return DocumentControllerV1ApiFp(this.configuration).getSurveyResultCertificateUsingGET(options)(this.axios, this.basePath);
    }

    /**
     * Get survey results document url
     * @summary Get survey results document url
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentControllerV1Api
     */
    public getSurveyResultsDocumentUsingGET(options?: any) {
        return DocumentControllerV1ApiFp(this.configuration).getSurveyResultsDocumentUsingGET(options)(this.axios, this.basePath);
    }

}


/**
 * MemorandumControllerV1Api - axios parameter creator
 * @export
 */
export const MemorandumControllerV1ApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get client memorandum status
         * @summary Get client memorandum status
         * @param {string} clientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClientMemorandumStatusUsingGET(clientId: string, options: any = {}): RequestArgs {
            // verify required parameter 'clientId' is not null or undefined
            if (clientId === null || clientId === undefined) {
                throw new RequiredError('clientId','Required parameter clientId was null or undefined when calling getClientMemorandumStatusUsingGET.');
            }
            const localVarPath = `/api/v1/memorandum/{clientId}/status`
                .replace(`{${"clientId"}}`, encodeURIComponent(String(clientId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get memorandum
         * @summary Get memorandum
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMemorandumUsingGET(options: any = {}): RequestArgs {
            const localVarPath = `/api/v1/memorandum`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get own memorandum status
         * @summary Get own memorandum status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOwnMemorandumStatusUsingGET(options: any = {}): RequestArgs {
            const localVarPath = `/api/v1/memorandum/status`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sign a memorandum
         * @summary Sign a memorandum
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signMemorandumUsingPOST(options: any = {}): RequestArgs {
            const localVarPath = `/api/v1/memorandum`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MemorandumControllerV1Api - functional programming interface
 * @export
 */
export const MemorandumControllerV1ApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Get client memorandum status
         * @summary Get client memorandum status
         * @param {string} clientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClientMemorandumStatusUsingGET(clientId: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<MemorandumStatusResponseDto> {
            const localVarAxiosArgs = MemorandumControllerV1ApiAxiosParamCreator(configuration).getClientMemorandumStatusUsingGET(clientId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get memorandum
         * @summary Get memorandum
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMemorandumUsingGET(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<MemorandumResponseDto> {
            const localVarAxiosArgs = MemorandumControllerV1ApiAxiosParamCreator(configuration).getMemorandumUsingGET(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get own memorandum status
         * @summary Get own memorandum status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOwnMemorandumStatusUsingGET(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<MemorandumStatusResponseDto> {
            const localVarAxiosArgs = MemorandumControllerV1ApiAxiosParamCreator(configuration).getOwnMemorandumStatusUsingGET(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Sign a memorandum
         * @summary Sign a memorandum
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signMemorandumUsingPOST(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseEntity> {
            const localVarAxiosArgs = MemorandumControllerV1ApiAxiosParamCreator(configuration).signMemorandumUsingPOST(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * MemorandumControllerV1Api - factory interface
 * @export
 */
export const MemorandumControllerV1ApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * Get client memorandum status
         * @summary Get client memorandum status
         * @param {string} clientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClientMemorandumStatusUsingGET(clientId: string, options?: any): AxiosPromise<MemorandumStatusResponseDto> {
            return MemorandumControllerV1ApiFp(configuration).getClientMemorandumStatusUsingGET(clientId, options)(axios, basePath);
        },
        /**
         * Get memorandum
         * @summary Get memorandum
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMemorandumUsingGET(options?: any): AxiosPromise<MemorandumResponseDto> {
            return MemorandumControllerV1ApiFp(configuration).getMemorandumUsingGET(options)(axios, basePath);
        },
        /**
         * Get own memorandum status
         * @summary Get own memorandum status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOwnMemorandumStatusUsingGET(options?: any): AxiosPromise<MemorandumStatusResponseDto> {
            return MemorandumControllerV1ApiFp(configuration).getOwnMemorandumStatusUsingGET(options)(axios, basePath);
        },
        /**
         * Sign a memorandum
         * @summary Sign a memorandum
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signMemorandumUsingPOST(options?: any): AxiosPromise<ResponseEntity> {
            return MemorandumControllerV1ApiFp(configuration).signMemorandumUsingPOST(options)(axios, basePath);
        },
    };
};

/**
 * MemorandumControllerV1Api - object-oriented interface
 * @export
 * @class MemorandumControllerV1Api
 * @extends {BaseAPI}
 */
export class MemorandumControllerV1Api extends BaseAPI {
    /**
     * Get client memorandum status
     * @summary Get client memorandum status
     * @param {string} clientId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemorandumControllerV1Api
     */
    public getClientMemorandumStatusUsingGET(clientId: string, options?: any) {
        return MemorandumControllerV1ApiFp(this.configuration).getClientMemorandumStatusUsingGET(clientId, options)(this.axios, this.basePath);
    }

    /**
     * Get memorandum
     * @summary Get memorandum
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemorandumControllerV1Api
     */
    public getMemorandumUsingGET(options?: any) {
        return MemorandumControllerV1ApiFp(this.configuration).getMemorandumUsingGET(options)(this.axios, this.basePath);
    }

    /**
     * Get own memorandum status
     * @summary Get own memorandum status
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemorandumControllerV1Api
     */
    public getOwnMemorandumStatusUsingGET(options?: any) {
        return MemorandumControllerV1ApiFp(this.configuration).getOwnMemorandumStatusUsingGET(options)(this.axios, this.basePath);
    }

    /**
     * Sign a memorandum
     * @summary Sign a memorandum
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemorandumControllerV1Api
     */
    public signMemorandumUsingPOST(options?: any) {
        return MemorandumControllerV1ApiFp(this.configuration).signMemorandumUsingPOST(options)(this.axios, this.basePath);
    }

}


/**
 * ProcessStatusControllerV1Api - axios parameter creator
 * @export
 */
export const ProcessStatusControllerV1ApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get status
         * @summary Get status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProcessStatusUsingGET(options: any = {}): RequestArgs {
            const localVarPath = `/api/v1/status`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProcessStatusControllerV1Api - functional programming interface
 * @export
 */
export const ProcessStatusControllerV1ApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Get status
         * @summary Get status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProcessStatusUsingGET(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProcessStatusResponseDto> {
            const localVarAxiosArgs = ProcessStatusControllerV1ApiAxiosParamCreator(configuration).getProcessStatusUsingGET(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ProcessStatusControllerV1Api - factory interface
 * @export
 */
export const ProcessStatusControllerV1ApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * Get status
         * @summary Get status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProcessStatusUsingGET(options?: any): AxiosPromise<ProcessStatusResponseDto> {
            return ProcessStatusControllerV1ApiFp(configuration).getProcessStatusUsingGET(options)(axios, basePath);
        },
    };
};

/**
 * ProcessStatusControllerV1Api - object-oriented interface
 * @export
 * @class ProcessStatusControllerV1Api
 * @extends {BaseAPI}
 */
export class ProcessStatusControllerV1Api extends BaseAPI {
    /**
     * Get status
     * @summary Get status
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProcessStatusControllerV1Api
     */
    public getProcessStatusUsingGET(options?: any) {
        return ProcessStatusControllerV1ApiFp(this.configuration).getProcessStatusUsingGET(options)(this.axios, this.basePath);
    }

}


/**
 * ProfileControllerV1Api - axios parameter creator
 * @export
 */
export const ProfileControllerV1ApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Bind client
         * @summary Bind client
         * @param {string} [leadId] leadId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bindClientUsingPOST(leadId?: string, options: any = {}): RequestArgs {
            const localVarPath = `/api/v1/profile/bind`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof leadId !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(leadId !== undefined ? leadId : {}) : (leadId || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a profile
         * @summary Create a profile
         * @param {ClientFilledProfileWithPresentationRequest} profile profile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProfileUsingPOST(profile: ClientFilledProfileWithPresentationRequest, options: any = {}): RequestArgs {
            // verify required parameter 'profile' is not null or undefined
            if (profile === null || profile === undefined) {
                throw new RequiredError('profile','Required parameter profile was null or undefined when calling createProfileUsingPOST.');
            }
            const localVarPath = `/api/v1/profile`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof profile !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(profile !== undefined ? profile : {}) : (profile || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Decline a client profile
         * @summary Decline a client profile
         * @param {string} clientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        declineProfileUsingPOST(clientId: string, options: any = {}): RequestArgs {
            // verify required parameter 'clientId' is not null or undefined
            if (clientId === null || clientId === undefined) {
                throw new RequiredError('clientId','Required parameter clientId was null or undefined when calling declineProfileUsingPOST.');
            }
            const localVarPath = `/api/v1/{clientId}/profile/decline`
                .replace(`{${"clientId"}}`, encodeURIComponent(String(clientId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete client profile
         * @summary Delete client profile
         * @param {string} clientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProfileUsingDELETE(clientId: string, options: any = {}): RequestArgs {
            // verify required parameter 'clientId' is not null or undefined
            if (clientId === null || clientId === undefined) {
                throw new RequiredError('clientId','Required parameter clientId was null or undefined when calling deleteProfileUsingDELETE.');
            }
            const localVarPath = `/api/v1/{clientId}/profile`
                .replace(`{${"clientId"}}`, encodeURIComponent(String(clientId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update client profile
         * @summary Update client profile
         * @param {string} clientId 
         * @param {EditProfileRequest} editProfileRequest editProfileRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editProfileUsingPUT(clientId: string, editProfileRequest: EditProfileRequest, options: any = {}): RequestArgs {
            // verify required parameter 'clientId' is not null or undefined
            if (clientId === null || clientId === undefined) {
                throw new RequiredError('clientId','Required parameter clientId was null or undefined when calling editProfileUsingPUT.');
            }
            // verify required parameter 'editProfileRequest' is not null or undefined
            if (editProfileRequest === null || editProfileRequest === undefined) {
                throw new RequiredError('editProfileRequest','Required parameter editProfileRequest was null or undefined when calling editProfileUsingPUT.');
            }
            const localVarPath = `/api/v1/{clientId}/profile`
                .replace(`{${"clientId"}}`, encodeURIComponent(String(clientId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof editProfileRequest !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(editProfileRequest !== undefined ? editProfileRequest : {}) : (editProfileRequest || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get a profile status
         * @summary Get a profile status
         * @param {string} clientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClientProfileStatusUsingGET(clientId: string, options: any = {}): RequestArgs {
            // verify required parameter 'clientId' is not null or undefined
            if (clientId === null || clientId === undefined) {
                throw new RequiredError('clientId','Required parameter clientId was null or undefined when calling getClientProfileStatusUsingGET.');
            }
            const localVarPath = `/api/v1/{clientId}/profile/status`
                .replace(`{${"clientId"}}`, encodeURIComponent(String(clientId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get a prefilled profile
         * @summary Get a prefilled profile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentOrgProfileUsingGET(options: any = {}): RequestArgs {
            const localVarPath = `/api/v1/profile`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get a profile status
         * @summary Get a profile status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentProfileStatusUsingGET(options: any = {}): RequestArgs {
            const localVarPath = `/api/v1/profile/status`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get a prefilled profile for client
         * @summary Get a prefilled profile for client
         * @param {string} clientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProfilePrefilledUsingGET(clientId: string, options: any = {}): RequestArgs {
            // verify required parameter 'clientId' is not null or undefined
            if (clientId === null || clientId === undefined) {
                throw new RequiredError('clientId','Required parameter clientId was null or undefined when calling getProfilePrefilledUsingGET.');
            }
            const localVarPath = `/api/v1/{clientId}/profile/prefilled`
                .replace(`{${"clientId"}}`, encodeURIComponent(String(clientId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get a client profile
         * @summary Get a client profile
         * @param {string} clientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProfileUsingGET(clientId: string, options: any = {}): RequestArgs {
            // verify required parameter 'clientId' is not null or undefined
            if (clientId === null || clientId === undefined) {
                throw new RequiredError('clientId','Required parameter clientId was null or undefined when calling getProfileUsingGET.');
            }
            const localVarPath = `/api/v1/{clientId}/profile`
                .replace(`{${"clientId"}}`, encodeURIComponent(String(clientId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Upload document to profile process
         * @summary Upload document to profile process
         * @param {any} file file
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadDocumentUsingPOST(file: any, options: any = {}): RequestArgs {
            // verify required parameter 'file' is not null or undefined
            if (file === null || file === undefined) {
                throw new RequiredError('file','Required parameter file was null or undefined when calling uploadDocumentUsingPOST.');
            }
            const localVarPath = `/api/v1/profile/document`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Verify a client profile
         * @summary Verify a client profile
         * @param {string} clientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyProfileUsingPOST(clientId: string, options: any = {}): RequestArgs {
            // verify required parameter 'clientId' is not null or undefined
            if (clientId === null || clientId === undefined) {
                throw new RequiredError('clientId','Required parameter clientId was null or undefined when calling verifyProfileUsingPOST.');
            }
            const localVarPath = `/api/v1/{clientId}/profile/verify`
                .replace(`{${"clientId"}}`, encodeURIComponent(String(clientId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProfileControllerV1Api - functional programming interface
 * @export
 */
export const ProfileControllerV1ApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Bind client
         * @summary Bind client
         * @param {string} [leadId] leadId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bindClientUsingPOST(leadId?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProcessStatusResponseDto> {
            const localVarAxiosArgs = ProfileControllerV1ApiAxiosParamCreator(configuration).bindClientUsingPOST(leadId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Create a profile
         * @summary Create a profile
         * @param {ClientFilledProfileWithPresentationRequest} profile profile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProfileUsingPOST(profile: ClientFilledProfileWithPresentationRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseEntity> {
            const localVarAxiosArgs = ProfileControllerV1ApiAxiosParamCreator(configuration).createProfileUsingPOST(profile, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Decline a client profile
         * @summary Decline a client profile
         * @param {string} clientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        declineProfileUsingPOST(clientId: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseEntity> {
            const localVarAxiosArgs = ProfileControllerV1ApiAxiosParamCreator(configuration).declineProfileUsingPOST(clientId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Delete client profile
         * @summary Delete client profile
         * @param {string} clientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProfileUsingDELETE(clientId: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseEntity> {
            const localVarAxiosArgs = ProfileControllerV1ApiAxiosParamCreator(configuration).deleteProfileUsingDELETE(clientId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Update client profile
         * @summary Update client profile
         * @param {string} clientId 
         * @param {EditProfileRequest} editProfileRequest editProfileRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editProfileUsingPUT(clientId: string, editProfileRequest: EditProfileRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseEntity> {
            const localVarAxiosArgs = ProfileControllerV1ApiAxiosParamCreator(configuration).editProfileUsingPUT(clientId, editProfileRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get a profile status
         * @summary Get a profile status
         * @param {string} clientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClientProfileStatusUsingGET(clientId: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProfileStatusDto> {
            const localVarAxiosArgs = ProfileControllerV1ApiAxiosParamCreator(configuration).getClientProfileStatusUsingGET(clientId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get a prefilled profile
         * @summary Get a prefilled profile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentOrgProfileUsingGET(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PrefilledProfileDto> {
            const localVarAxiosArgs = ProfileControllerV1ApiAxiosParamCreator(configuration).getCurrentOrgProfileUsingGET(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get a profile status
         * @summary Get a profile status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentProfileStatusUsingGET(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProfileStatusDto> {
            const localVarAxiosArgs = ProfileControllerV1ApiAxiosParamCreator(configuration).getCurrentProfileStatusUsingGET(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get a prefilled profile for client
         * @summary Get a prefilled profile for client
         * @param {string} clientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProfilePrefilledUsingGET(clientId: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PrefilledProfileDto> {
            const localVarAxiosArgs = ProfileControllerV1ApiAxiosParamCreator(configuration).getProfilePrefilledUsingGET(clientId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get a client profile
         * @summary Get a client profile
         * @param {string} clientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProfileUsingGET(clientId: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientFilledProfileDto> {
            const localVarAxiosArgs = ProfileControllerV1ApiAxiosParamCreator(configuration).getProfileUsingGET(clientId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Upload document to profile process
         * @summary Upload document to profile process
         * @param {any} file file
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadDocumentUsingPOST(file: any, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseEntity> {
            const localVarAxiosArgs = ProfileControllerV1ApiAxiosParamCreator(configuration).uploadDocumentUsingPOST(file, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Verify a client profile
         * @summary Verify a client profile
         * @param {string} clientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyProfileUsingPOST(clientId: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseEntity> {
            const localVarAxiosArgs = ProfileControllerV1ApiAxiosParamCreator(configuration).verifyProfileUsingPOST(clientId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ProfileControllerV1Api - factory interface
 * @export
 */
export const ProfileControllerV1ApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * Bind client
         * @summary Bind client
         * @param {string} [leadId] leadId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bindClientUsingPOST(leadId?: string, options?: any): AxiosPromise<ProcessStatusResponseDto> {
            return ProfileControllerV1ApiFp(configuration).bindClientUsingPOST(leadId, options)(axios, basePath);
        },
        /**
         * Create a profile
         * @summary Create a profile
         * @param {ClientFilledProfileWithPresentationRequest} profile profile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProfileUsingPOST(profile: ClientFilledProfileWithPresentationRequest, options?: any): AxiosPromise<ResponseEntity> {
            return ProfileControllerV1ApiFp(configuration).createProfileUsingPOST(profile, options)(axios, basePath);
        },
        /**
         * Decline a client profile
         * @summary Decline a client profile
         * @param {string} clientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        declineProfileUsingPOST(clientId: string, options?: any): AxiosPromise<ResponseEntity> {
            return ProfileControllerV1ApiFp(configuration).declineProfileUsingPOST(clientId, options)(axios, basePath);
        },
        /**
         * Delete client profile
         * @summary Delete client profile
         * @param {string} clientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProfileUsingDELETE(clientId: string, options?: any): AxiosPromise<ResponseEntity> {
            return ProfileControllerV1ApiFp(configuration).deleteProfileUsingDELETE(clientId, options)(axios, basePath);
        },
        /**
         * Update client profile
         * @summary Update client profile
         * @param {string} clientId 
         * @param {EditProfileRequest} editProfileRequest editProfileRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editProfileUsingPUT(clientId: string, editProfileRequest: EditProfileRequest, options?: any): AxiosPromise<ResponseEntity> {
            return ProfileControllerV1ApiFp(configuration).editProfileUsingPUT(clientId, editProfileRequest, options)(axios, basePath);
        },
        /**
         * Get a profile status
         * @summary Get a profile status
         * @param {string} clientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClientProfileStatusUsingGET(clientId: string, options?: any): AxiosPromise<ProfileStatusDto> {
            return ProfileControllerV1ApiFp(configuration).getClientProfileStatusUsingGET(clientId, options)(axios, basePath);
        },
        /**
         * Get a prefilled profile
         * @summary Get a prefilled profile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentOrgProfileUsingGET(options?: any): AxiosPromise<PrefilledProfileDto> {
            return ProfileControllerV1ApiFp(configuration).getCurrentOrgProfileUsingGET(options)(axios, basePath);
        },
        /**
         * Get a profile status
         * @summary Get a profile status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentProfileStatusUsingGET(options?: any): AxiosPromise<ProfileStatusDto> {
            return ProfileControllerV1ApiFp(configuration).getCurrentProfileStatusUsingGET(options)(axios, basePath);
        },
        /**
         * Get a prefilled profile for client
         * @summary Get a prefilled profile for client
         * @param {string} clientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProfilePrefilledUsingGET(clientId: string, options?: any): AxiosPromise<PrefilledProfileDto> {
            return ProfileControllerV1ApiFp(configuration).getProfilePrefilledUsingGET(clientId, options)(axios, basePath);
        },
        /**
         * Get a client profile
         * @summary Get a client profile
         * @param {string} clientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProfileUsingGET(clientId: string, options?: any): AxiosPromise<ClientFilledProfileDto> {
            return ProfileControllerV1ApiFp(configuration).getProfileUsingGET(clientId, options)(axios, basePath);
        },
        /**
         * Upload document to profile process
         * @summary Upload document to profile process
         * @param {any} file file
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadDocumentUsingPOST(file: any, options?: any): AxiosPromise<ResponseEntity> {
            return ProfileControllerV1ApiFp(configuration).uploadDocumentUsingPOST(file, options)(axios, basePath);
        },
        /**
         * Verify a client profile
         * @summary Verify a client profile
         * @param {string} clientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyProfileUsingPOST(clientId: string, options?: any): AxiosPromise<ResponseEntity> {
            return ProfileControllerV1ApiFp(configuration).verifyProfileUsingPOST(clientId, options)(axios, basePath);
        },
    };
};

/**
 * ProfileControllerV1Api - object-oriented interface
 * @export
 * @class ProfileControllerV1Api
 * @extends {BaseAPI}
 */
export class ProfileControllerV1Api extends BaseAPI {
    /**
     * Bind client
     * @summary Bind client
     * @param {string} [leadId] leadId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileControllerV1Api
     */
    public bindClientUsingPOST(leadId?: string, options?: any) {
        return ProfileControllerV1ApiFp(this.configuration).bindClientUsingPOST(leadId, options)(this.axios, this.basePath);
    }

    /**
     * Create a profile
     * @summary Create a profile
     * @param {ClientFilledProfileWithPresentationRequest} profile profile
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileControllerV1Api
     */
    public createProfileUsingPOST(profile: ClientFilledProfileWithPresentationRequest, options?: any) {
        return ProfileControllerV1ApiFp(this.configuration).createProfileUsingPOST(profile, options)(this.axios, this.basePath);
    }

    /**
     * Decline a client profile
     * @summary Decline a client profile
     * @param {string} clientId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileControllerV1Api
     */
    public declineProfileUsingPOST(clientId: string, options?: any) {
        return ProfileControllerV1ApiFp(this.configuration).declineProfileUsingPOST(clientId, options)(this.axios, this.basePath);
    }

    /**
     * Delete client profile
     * @summary Delete client profile
     * @param {string} clientId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileControllerV1Api
     */
    public deleteProfileUsingDELETE(clientId: string, options?: any) {
        return ProfileControllerV1ApiFp(this.configuration).deleteProfileUsingDELETE(clientId, options)(this.axios, this.basePath);
    }

    /**
     * Update client profile
     * @summary Update client profile
     * @param {string} clientId 
     * @param {EditProfileRequest} editProfileRequest editProfileRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileControllerV1Api
     */
    public editProfileUsingPUT(clientId: string, editProfileRequest: EditProfileRequest, options?: any) {
        return ProfileControllerV1ApiFp(this.configuration).editProfileUsingPUT(clientId, editProfileRequest, options)(this.axios, this.basePath);
    }

    /**
     * Get a profile status
     * @summary Get a profile status
     * @param {string} clientId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileControllerV1Api
     */
    public getClientProfileStatusUsingGET(clientId: string, options?: any) {
        return ProfileControllerV1ApiFp(this.configuration).getClientProfileStatusUsingGET(clientId, options)(this.axios, this.basePath);
    }

    /**
     * Get a prefilled profile
     * @summary Get a prefilled profile
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileControllerV1Api
     */
    public getCurrentOrgProfileUsingGET(options?: any) {
        return ProfileControllerV1ApiFp(this.configuration).getCurrentOrgProfileUsingGET(options)(this.axios, this.basePath);
    }

    /**
     * Get a profile status
     * @summary Get a profile status
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileControllerV1Api
     */
    public getCurrentProfileStatusUsingGET(options?: any) {
        return ProfileControllerV1ApiFp(this.configuration).getCurrentProfileStatusUsingGET(options)(this.axios, this.basePath);
    }

    /**
     * Get a prefilled profile for client
     * @summary Get a prefilled profile for client
     * @param {string} clientId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileControllerV1Api
     */
    public getProfilePrefilledUsingGET(clientId: string, options?: any) {
        return ProfileControllerV1ApiFp(this.configuration).getProfilePrefilledUsingGET(clientId, options)(this.axios, this.basePath);
    }

    /**
     * Get a client profile
     * @summary Get a client profile
     * @param {string} clientId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileControllerV1Api
     */
    public getProfileUsingGET(clientId: string, options?: any) {
        return ProfileControllerV1ApiFp(this.configuration).getProfileUsingGET(clientId, options)(this.axios, this.basePath);
    }

    /**
     * Upload document to profile process
     * @summary Upload document to profile process
     * @param {any} file file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileControllerV1Api
     */
    public uploadDocumentUsingPOST(file: any, options?: any) {
        return ProfileControllerV1ApiFp(this.configuration).uploadDocumentUsingPOST(file, options)(this.axios, this.basePath);
    }

    /**
     * Verify a client profile
     * @summary Verify a client profile
     * @param {string} clientId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileControllerV1Api
     */
    public verifyProfileUsingPOST(clientId: string, options?: any) {
        return ProfileControllerV1ApiFp(this.configuration).verifyProfileUsingPOST(clientId, options)(this.axios, this.basePath);
    }

}


/**
 * ReportControllerV1Api - axios parameter creator
 * @export
 */
export const ReportControllerV1ApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Download accelerator report
         * @summary Download accelerator report
         * @param {'POTENTIAL_EXPORTER_REPORT' | 'ROAD_MAP_STEPS_PROGRESS'} type type
         * @param {string} [surveyDateFrom] Survey date from
         * @param {string} [surveyDateTo] Survey date to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadReportUsingGET(type: 'POTENTIAL_EXPORTER_REPORT' | 'ROAD_MAP_STEPS_PROGRESS', surveyDateFrom?: string, surveyDateTo?: string, options: any = {}): RequestArgs {
            // verify required parameter 'type' is not null or undefined
            if (type === null || type === undefined) {
                throw new RequiredError('type','Required parameter type was null or undefined when calling downloadReportUsingGET.');
            }
            const localVarPath = `/api/v1/reports/download`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (surveyDateFrom !== undefined) {
                localVarQueryParameter['surveyDateFrom'] = (surveyDateFrom as any instanceof Date) ?
                    (surveyDateFrom as any).toISOString() :
                    surveyDateFrom;
            }

            if (surveyDateTo !== undefined) {
                localVarQueryParameter['surveyDateTo'] = (surveyDateTo as any instanceof Date) ?
                    (surveyDateTo as any).toISOString() :
                    surveyDateTo;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get a potential exporters report
         * @summary Get a potential exporters report
         * @param {ReportFilterDto} [filter] filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPotentialExportersReportUsingPOST(filter?: ReportFilterDto, options: any = {}): RequestArgs {
            const localVarPath = `/api/v1/reports/potential-exporter`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof filter !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(filter !== undefined ? filter : {}) : (filter || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get a road map steps progress
         * @summary Get a road map steps progress
         * @param {ReportFilterDto} [filter] filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRoadMapStepsProgressReportUsingPOST(filter?: ReportFilterDto, options: any = {}): RequestArgs {
            const localVarPath = `/api/v1/reports/road-map-steps-progress`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof filter !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(filter !== undefined ? filter : {}) : (filter || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReportControllerV1Api - functional programming interface
 * @export
 */
export const ReportControllerV1ApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Download accelerator report
         * @summary Download accelerator report
         * @param {'POTENTIAL_EXPORTER_REPORT' | 'ROAD_MAP_STEPS_PROGRESS'} type type
         * @param {string} [surveyDateFrom] Survey date from
         * @param {string} [surveyDateTo] Survey date to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadReportUsingGET(type: 'POTENTIAL_EXPORTER_REPORT' | 'ROAD_MAP_STEPS_PROGRESS', surveyDateFrom?: string, surveyDateTo?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = ReportControllerV1ApiAxiosParamCreator(configuration).downloadReportUsingGET(type, surveyDateFrom, surveyDateTo, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get a potential exporters report
         * @summary Get a potential exporters report
         * @param {ReportFilterDto} [filter] filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPotentialExportersReportUsingPOST(filter?: ReportFilterDto, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PotentialExportersReportDataDto> {
            const localVarAxiosArgs = ReportControllerV1ApiAxiosParamCreator(configuration).getPotentialExportersReportUsingPOST(filter, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get a road map steps progress
         * @summary Get a road map steps progress
         * @param {ReportFilterDto} [filter] filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRoadMapStepsProgressReportUsingPOST(filter?: ReportFilterDto, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoadMapStepsProgressReportDataDto> {
            const localVarAxiosArgs = ReportControllerV1ApiAxiosParamCreator(configuration).getRoadMapStepsProgressReportUsingPOST(filter, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ReportControllerV1Api - factory interface
 * @export
 */
export const ReportControllerV1ApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * Download accelerator report
         * @summary Download accelerator report
         * @param {'POTENTIAL_EXPORTER_REPORT' | 'ROAD_MAP_STEPS_PROGRESS'} type type
         * @param {string} [surveyDateFrom] Survey date from
         * @param {string} [surveyDateTo] Survey date to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadReportUsingGET(type: 'POTENTIAL_EXPORTER_REPORT' | 'ROAD_MAP_STEPS_PROGRESS', surveyDateFrom?: string, surveyDateTo?: string, options?: any): AxiosPromise<void> {
            return ReportControllerV1ApiFp(configuration).downloadReportUsingGET(type, surveyDateFrom, surveyDateTo, options)(axios, basePath);
        },
        /**
         * Get a potential exporters report
         * @summary Get a potential exporters report
         * @param {ReportFilterDto} [filter] filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPotentialExportersReportUsingPOST(filter?: ReportFilterDto, options?: any): AxiosPromise<PotentialExportersReportDataDto> {
            return ReportControllerV1ApiFp(configuration).getPotentialExportersReportUsingPOST(filter, options)(axios, basePath);
        },
        /**
         * Get a road map steps progress
         * @summary Get a road map steps progress
         * @param {ReportFilterDto} [filter] filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRoadMapStepsProgressReportUsingPOST(filter?: ReportFilterDto, options?: any): AxiosPromise<RoadMapStepsProgressReportDataDto> {
            return ReportControllerV1ApiFp(configuration).getRoadMapStepsProgressReportUsingPOST(filter, options)(axios, basePath);
        },
    };
};

/**
 * ReportControllerV1Api - object-oriented interface
 * @export
 * @class ReportControllerV1Api
 * @extends {BaseAPI}
 */
export class ReportControllerV1Api extends BaseAPI {
    /**
     * Download accelerator report
     * @summary Download accelerator report
     * @param {'POTENTIAL_EXPORTER_REPORT' | 'ROAD_MAP_STEPS_PROGRESS'} type type
     * @param {string} [surveyDateFrom] Survey date from
     * @param {string} [surveyDateTo] Survey date to
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportControllerV1Api
     */
    public downloadReportUsingGET(type: 'POTENTIAL_EXPORTER_REPORT' | 'ROAD_MAP_STEPS_PROGRESS', surveyDateFrom?: string, surveyDateTo?: string, options?: any) {
        return ReportControllerV1ApiFp(this.configuration).downloadReportUsingGET(type, surveyDateFrom, surveyDateTo, options)(this.axios, this.basePath);
    }

    /**
     * Get a potential exporters report
     * @summary Get a potential exporters report
     * @param {ReportFilterDto} [filter] filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportControllerV1Api
     */
    public getPotentialExportersReportUsingPOST(filter?: ReportFilterDto, options?: any) {
        return ReportControllerV1ApiFp(this.configuration).getPotentialExportersReportUsingPOST(filter, options)(this.axios, this.basePath);
    }

    /**
     * Get a road map steps progress
     * @summary Get a road map steps progress
     * @param {ReportFilterDto} [filter] filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportControllerV1Api
     */
    public getRoadMapStepsProgressReportUsingPOST(filter?: ReportFilterDto, options?: any) {
        return ReportControllerV1ApiFp(this.configuration).getRoadMapStepsProgressReportUsingPOST(filter, options)(this.axios, this.basePath);
    }

}


/**
 * RoadMapControllerV1Api - axios parameter creator
 * @export
 */
export const RoadMapControllerV1ApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Store market and product
         * @summary Store market and product
         * @param {ProductAndMarketRequestDto} productAndMarketRequestDto productAndMarketRequestDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addProductAndMarketToRoadMapUsingPOST(productAndMarketRequestDto: ProductAndMarketRequestDto, options: any = {}): RequestArgs {
            // verify required parameter 'productAndMarketRequestDto' is not null or undefined
            if (productAndMarketRequestDto === null || productAndMarketRequestDto === undefined) {
                throw new RequiredError('productAndMarketRequestDto','Required parameter productAndMarketRequestDto was null or undefined when calling addProductAndMarketToRoadMapUsingPOST.');
            }
            const localVarPath = `/api/v1/road-map/product-and-market`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof productAndMarketRequestDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(productAndMarketRequestDto !== undefined ? productAndMarketRequestDto : {}) : (productAndMarketRequestDto || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Add services from catalog to road map by manager
         * @summary Add services from catalog to road map by manager
         * @param {string} clientId 
         * @param {Array<AddServicesDto>} addServicesRequest addServicesRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addServicesByManagerUsingPOST(clientId: string, addServicesRequest: Array<AddServicesDto>, options: any = {}): RequestArgs {
            // verify required parameter 'clientId' is not null or undefined
            if (clientId === null || clientId === undefined) {
                throw new RequiredError('clientId','Required parameter clientId was null or undefined when calling addServicesByManagerUsingPOST.');
            }
            // verify required parameter 'addServicesRequest' is not null or undefined
            if (addServicesRequest === null || addServicesRequest === undefined) {
                throw new RequiredError('addServicesRequest','Required parameter addServicesRequest was null or undefined when calling addServicesByManagerUsingPOST.');
            }
            const localVarPath = `/api/v1/{clientId}/road-map/service`
                .replace(`{${"clientId"}}`, encodeURIComponent(String(clientId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof addServicesRequest !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(addServicesRequest !== undefined ? addServicesRequest : {}) : (addServicesRequest || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Approve client road map
         * @summary Approve client road map
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        approveClientRoadMapUsingPOST(options: any = {}): RequestArgs {
            const localVarPath = `/api/v1/road-map/approve`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Approve a road map service by client
         * @summary Approve a road map service by client
         * @param {number} serviceId 
         * @param {number} stageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        approveServiceByClientUsingPOST(serviceId: number, stageId: number, options: any = {}): RequestArgs {
            // verify required parameter 'serviceId' is not null or undefined
            if (serviceId === null || serviceId === undefined) {
                throw new RequiredError('serviceId','Required parameter serviceId was null or undefined when calling approveServiceByClientUsingPOST.');
            }
            // verify required parameter 'stageId' is not null or undefined
            if (stageId === null || stageId === undefined) {
                throw new RequiredError('stageId','Required parameter stageId was null or undefined when calling approveServiceByClientUsingPOST.');
            }
            const localVarPath = `/api/v1/road-map/stage/{stageId}/service/{serviceId}/approve`
                .replace(`{${"serviceId"}}`, encodeURIComponent(String(serviceId)))
                .replace(`{${"stageId"}}`, encodeURIComponent(String(stageId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Approve a road map service by manager
         * @summary Approve a road map service by manager
         * @param {string} clientId 
         * @param {number} serviceId 
         * @param {number} stageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        approveServiceByManagerUsingPOST(clientId: string, serviceId: number, stageId: number, options: any = {}): RequestArgs {
            // verify required parameter 'clientId' is not null or undefined
            if (clientId === null || clientId === undefined) {
                throw new RequiredError('clientId','Required parameter clientId was null or undefined when calling approveServiceByManagerUsingPOST.');
            }
            // verify required parameter 'serviceId' is not null or undefined
            if (serviceId === null || serviceId === undefined) {
                throw new RequiredError('serviceId','Required parameter serviceId was null or undefined when calling approveServiceByManagerUsingPOST.');
            }
            // verify required parameter 'stageId' is not null or undefined
            if (stageId === null || stageId === undefined) {
                throw new RequiredError('stageId','Required parameter stageId was null or undefined when calling approveServiceByManagerUsingPOST.');
            }
            const localVarPath = `/api/v1/{clientId}/road-map/stage/{stageId}/service/{serviceId}/approve`
                .replace(`{${"clientId"}}`, encodeURIComponent(String(clientId)))
                .replace(`{${"serviceId"}}`, encodeURIComponent(String(serviceId)))
                .replace(`{${"stageId"}}`, encodeURIComponent(String(stageId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete road map services by manager
         * @summary Delete service
         * @param {string} clientId 
         * @param {Array<DeleteRoadMapServicesDto>} deleteRoadMapServicesDto deleteRoadMapServicesDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteServicesByManagerUsingPOST(clientId: string, deleteRoadMapServicesDto: Array<DeleteRoadMapServicesDto>, options: any = {}): RequestArgs {
            // verify required parameter 'clientId' is not null or undefined
            if (clientId === null || clientId === undefined) {
                throw new RequiredError('clientId','Required parameter clientId was null or undefined when calling deleteServicesByManagerUsingPOST.');
            }
            // verify required parameter 'deleteRoadMapServicesDto' is not null or undefined
            if (deleteRoadMapServicesDto === null || deleteRoadMapServicesDto === undefined) {
                throw new RequiredError('deleteRoadMapServicesDto','Required parameter deleteRoadMapServicesDto was null or undefined when calling deleteServicesByManagerUsingPOST.');
            }
            const localVarPath = `/api/v1/{clientId}/road-map/service/delete`
                .replace(`{${"clientId"}}`, encodeURIComponent(String(clientId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof deleteRoadMapServicesDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(deleteRoadMapServicesDto !== undefined ? deleteRoadMapServicesDto : {}) : (deleteRoadMapServicesDto || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Finish road map step and optionally upload files
         * @summary Finish road map step and optionally upload files
         * @param {number} stepId 
         * @param {FinishRoadMapStepRequestDto} finishRoadMapStepRequestDto finishRoadMapStepRequestDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        finishStepUsingPOST(stepId: number, finishRoadMapStepRequestDto: FinishRoadMapStepRequestDto, options: any = {}): RequestArgs {
            // verify required parameter 'stepId' is not null or undefined
            if (stepId === null || stepId === undefined) {
                throw new RequiredError('stepId','Required parameter stepId was null or undefined when calling finishStepUsingPOST.');
            }
            // verify required parameter 'finishRoadMapStepRequestDto' is not null or undefined
            if (finishRoadMapStepRequestDto === null || finishRoadMapStepRequestDto === undefined) {
                throw new RequiredError('finishRoadMapStepRequestDto','Required parameter finishRoadMapStepRequestDto was null or undefined when calling finishStepUsingPOST.');
            }
            const localVarPath = `/api/v1/road-map/step/{stepId}/finish`
                .replace(`{${"stepId"}}`, encodeURIComponent(String(stepId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof finishRoadMapStepRequestDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(finishRoadMapStepRequestDto !== undefined ? finishRoadMapStepRequestDto : {}) : (finishRoadMapStepRequestDto || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get client market and product
         * @summary Get client market and product
         * @param {string} clientId clientId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClientProductAndMarketInRoadMapUsingGET(clientId: string, options: any = {}): RequestArgs {
            // verify required parameter 'clientId' is not null or undefined
            if (clientId === null || clientId === undefined) {
                throw new RequiredError('clientId','Required parameter clientId was null or undefined when calling getClientProductAndMarketInRoadMapUsingGET.');
            }
            const localVarPath = `/api/v1/{clientId}/road-map/product-and-market`
                .replace(`{${"clientId"}}`, encodeURIComponent(String(clientId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get a client road map
         * @summary Get a client road map
         * @param {string} clientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClientRoadMapUsingGET(clientId: string, options: any = {}): RequestArgs {
            // verify required parameter 'clientId' is not null or undefined
            if (clientId === null || clientId === undefined) {
                throw new RequiredError('clientId','Required parameter clientId was null or undefined when calling getClientRoadMapUsingGET.');
            }
            const localVarPath = `/api/v1/{clientId}/road-map`
                .replace(`{${"clientId"}}`, encodeURIComponent(String(clientId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get market and product
         * @summary Get market and product
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductAndMarketInRoadMapUsingGET(options: any = {}): RequestArgs {
            const localVarPath = `/api/v1/road-map/product-and-market`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get a road map
         * @summary Get a road map
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRoadMapUsingGET(options: any = {}): RequestArgs {
            const localVarPath = `/api/v1/road-map`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get a client road map pdf file
         * @summary Get a client road map pdf file
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRoadmapReportUsingGET(options: any = {}): RequestArgs {
            const localVarPath = `/api/v1/road-map-file`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get a road map pdf file
         * @summary Get a road map pdf file
         * @param {string} clientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRoadmapReportUsingGET1(clientId: string, options: any = {}): RequestArgs {
            // verify required parameter 'clientId' is not null or undefined
            if (clientId === null || clientId === undefined) {
                throw new RequiredError('clientId','Required parameter clientId was null or undefined when calling getRoadmapReportUsingGET1.');
            }
            const localVarPath = `/api/v1/{clientId}/road-map-file`
                .replace(`{${"clientId"}}`, encodeURIComponent(String(clientId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get road map service info
         * @summary Get road map service info
         * @param {string} clientId 
         * @param {number} serviceId 
         * @param {number} stageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getServiceInfoUsingGET(clientId: string, serviceId: number, stageId: number, options: any = {}): RequestArgs {
            // verify required parameter 'clientId' is not null or undefined
            if (clientId === null || clientId === undefined) {
                throw new RequiredError('clientId','Required parameter clientId was null or undefined when calling getServiceInfoUsingGET.');
            }
            // verify required parameter 'serviceId' is not null or undefined
            if (serviceId === null || serviceId === undefined) {
                throw new RequiredError('serviceId','Required parameter serviceId was null or undefined when calling getServiceInfoUsingGET.');
            }
            // verify required parameter 'stageId' is not null or undefined
            if (stageId === null || stageId === undefined) {
                throw new RequiredError('stageId','Required parameter stageId was null or undefined when calling getServiceInfoUsingGET.');
            }
            const localVarPath = `/api/v1/{clientId}/road-map/stage/{stageId}/service/{serviceId}/info`
                .replace(`{${"clientId"}}`, encodeURIComponent(String(clientId)))
                .replace(`{${"serviceId"}}`, encodeURIComponent(String(serviceId)))
                .replace(`{${"stageId"}}`, encodeURIComponent(String(stageId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get road map steps results
         * @summary Get road map steps results
         * @param {string} clientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStepsResultsUsingGET(clientId: string, options: any = {}): RequestArgs {
            // verify required parameter 'clientId' is not null or undefined
            if (clientId === null || clientId === undefined) {
                throw new RequiredError('clientId','Required parameter clientId was null or undefined when calling getStepsResultsUsingGET.');
            }
            const localVarPath = `/api/v1/{clientId}/road-map/steps/results`
                .replace(`{${"clientId"}}`, encodeURIComponent(String(clientId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Lock a road map by manager
         * @summary Lock a road map by manager
         * @param {string} clientId 
         * @param {ChangeBlockRequestDto} request request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lockRoadMapUsingPOST(clientId: string, request: ChangeBlockRequestDto, options: any = {}): RequestArgs {
            // verify required parameter 'clientId' is not null or undefined
            if (clientId === null || clientId === undefined) {
                throw new RequiredError('clientId','Required parameter clientId was null or undefined when calling lockRoadMapUsingPOST.');
            }
            // verify required parameter 'request' is not null or undefined
            if (request === null || request === undefined) {
                throw new RequiredError('request','Required parameter request was null or undefined when calling lockRoadMapUsingPOST.');
            }
            const localVarPath = `/api/v1/{clientId}/road-map/lock`
                .replace(`{${"clientId"}}`, encodeURIComponent(String(clientId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof request !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(request !== undefined ? request : {}) : (request || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Start road map service by client
         * @summary Start service
         * @param {number} serviceId 
         * @param {number} stageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startServiceByClientUsingPOST(serviceId: number, stageId: number, options: any = {}): RequestArgs {
            // verify required parameter 'serviceId' is not null or undefined
            if (serviceId === null || serviceId === undefined) {
                throw new RequiredError('serviceId','Required parameter serviceId was null or undefined when calling startServiceByClientUsingPOST.');
            }
            // verify required parameter 'stageId' is not null or undefined
            if (stageId === null || stageId === undefined) {
                throw new RequiredError('stageId','Required parameter stageId was null or undefined when calling startServiceByClientUsingPOST.');
            }
            const localVarPath = `/api/v1/road-map/stage/{stageId}/service/{serviceId}/start`
                .replace(`{${"serviceId"}}`, encodeURIComponent(String(serviceId)))
                .replace(`{${"stageId"}}`, encodeURIComponent(String(stageId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Unlock a road map by manager
         * @summary Unlock a road map by manager
         * @param {string} clientId clientId
         * @param {ChangeBlockRequestDto} request request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unlockRoadMapUsingPOST(clientId: string, request: ChangeBlockRequestDto, options: any = {}): RequestArgs {
            // verify required parameter 'clientId' is not null or undefined
            if (clientId === null || clientId === undefined) {
                throw new RequiredError('clientId','Required parameter clientId was null or undefined when calling unlockRoadMapUsingPOST.');
            }
            // verify required parameter 'request' is not null or undefined
            if (request === null || request === undefined) {
                throw new RequiredError('request','Required parameter request was null or undefined when calling unlockRoadMapUsingPOST.');
            }
            const localVarPath = `/api/v1/{clientId}/road-map/unlock`
                .replace(`{${"clientId"}}`, encodeURIComponent(String(clientId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof request !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(request !== undefined ? request : {}) : (request || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update market and product
         * @summary Update market and product
         * @param {string} clientId clientId
         * @param {ProductAndMarketRequestDto} productAndMarketRequestDto productAndMarketRequestDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateClientProductAndMarketInRoadMapUsingPUT(clientId: string, productAndMarketRequestDto: ProductAndMarketRequestDto, options: any = {}): RequestArgs {
            // verify required parameter 'clientId' is not null or undefined
            if (clientId === null || clientId === undefined) {
                throw new RequiredError('clientId','Required parameter clientId was null or undefined when calling updateClientProductAndMarketInRoadMapUsingPUT.');
            }
            // verify required parameter 'productAndMarketRequestDto' is not null or undefined
            if (productAndMarketRequestDto === null || productAndMarketRequestDto === undefined) {
                throw new RequiredError('productAndMarketRequestDto','Required parameter productAndMarketRequestDto was null or undefined when calling updateClientProductAndMarketInRoadMapUsingPUT.');
            }
            const localVarPath = `/api/v1/{clientId}/road-map/product-and-market`
                .replace(`{${"clientId"}}`, encodeURIComponent(String(clientId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof productAndMarketRequestDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(productAndMarketRequestDto !== undefined ? productAndMarketRequestDto : {}) : (productAndMarketRequestDto || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update note to service
         * @summary Update note to service
         * @param {string} clientId 
         * @param {string} serviceId 
         * @param {string} stageId 
         * @param {string} serviceNoteRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNoteToServiceUsingPUT(clientId: string, serviceId: string, stageId: string, serviceNoteRequest: string, options: any = {}): RequestArgs {
            // verify required parameter 'clientId' is not null or undefined
            if (clientId === null || clientId === undefined) {
                throw new RequiredError('clientId','Required parameter clientId was null or undefined when calling updateNoteToServiceUsingPUT.');
            }
            // verify required parameter 'serviceId' is not null or undefined
            if (serviceId === null || serviceId === undefined) {
                throw new RequiredError('serviceId','Required parameter serviceId was null or undefined when calling updateNoteToServiceUsingPUT.');
            }
            // verify required parameter 'stageId' is not null or undefined
            if (stageId === null || stageId === undefined) {
                throw new RequiredError('stageId','Required parameter stageId was null or undefined when calling updateNoteToServiceUsingPUT.');
            }
            // verify required parameter 'serviceNoteRequest' is not null or undefined
            if (serviceNoteRequest === null || serviceNoteRequest === undefined) {
                throw new RequiredError('serviceNoteRequest','Required parameter serviceNoteRequest was null or undefined when calling updateNoteToServiceUsingPUT.');
            }
            const localVarPath = `/api/v1/{clientId}/road-map/stage/{stageId}/service/{serviceId}/note`
                .replace(`{${"clientId"}}`, encodeURIComponent(String(clientId)))
                .replace(`{${"serviceId"}}`, encodeURIComponent(String(serviceId)))
                .replace(`{${"stageId"}}`, encodeURIComponent(String(stageId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof serviceNoteRequest !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(serviceNoteRequest !== undefined ? serviceNoteRequest : {}) : (serviceNoteRequest || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update market and product
         * @summary Update market and product
         * @param {ProductAndMarketRequestDto} productAndMarketRequestDto productAndMarketRequestDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProductAndMarketInRoadMapUsingPUT(productAndMarketRequestDto: ProductAndMarketRequestDto, options: any = {}): RequestArgs {
            // verify required parameter 'productAndMarketRequestDto' is not null or undefined
            if (productAndMarketRequestDto === null || productAndMarketRequestDto === undefined) {
                throw new RequiredError('productAndMarketRequestDto','Required parameter productAndMarketRequestDto was null or undefined when calling updateProductAndMarketInRoadMapUsingPUT.');
            }
            const localVarPath = `/api/v1/road-map/product-and-market`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof productAndMarketRequestDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(productAndMarketRequestDto !== undefined ? productAndMarketRequestDto : {}) : (productAndMarketRequestDto || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Set planEndDateTime for road map service
         * @summary Set planEndDateTime for road map service
         * @param {string} clientId 
         * @param {number} serviceId 
         * @param {number} stageId 
         * @param {RoadMapServiceUpdatePlanEndDateTimeDto} servicePlanEndDateTimeDto servicePlanEndDateTimeDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateServicePlanEndDateTimeUsingPOST(clientId: string, serviceId: number, stageId: number, servicePlanEndDateTimeDto: RoadMapServiceUpdatePlanEndDateTimeDto, options: any = {}): RequestArgs {
            // verify required parameter 'clientId' is not null or undefined
            if (clientId === null || clientId === undefined) {
                throw new RequiredError('clientId','Required parameter clientId was null or undefined when calling updateServicePlanEndDateTimeUsingPOST.');
            }
            // verify required parameter 'serviceId' is not null or undefined
            if (serviceId === null || serviceId === undefined) {
                throw new RequiredError('serviceId','Required parameter serviceId was null or undefined when calling updateServicePlanEndDateTimeUsingPOST.');
            }
            // verify required parameter 'stageId' is not null or undefined
            if (stageId === null || stageId === undefined) {
                throw new RequiredError('stageId','Required parameter stageId was null or undefined when calling updateServicePlanEndDateTimeUsingPOST.');
            }
            // verify required parameter 'servicePlanEndDateTimeDto' is not null or undefined
            if (servicePlanEndDateTimeDto === null || servicePlanEndDateTimeDto === undefined) {
                throw new RequiredError('servicePlanEndDateTimeDto','Required parameter servicePlanEndDateTimeDto was null or undefined when calling updateServicePlanEndDateTimeUsingPOST.');
            }
            const localVarPath = `/api/v1/{clientId}/road-map/stage/{stageId}/service/{serviceId}/planEndDateTime`
                .replace(`{${"clientId"}}`, encodeURIComponent(String(clientId)))
                .replace(`{${"serviceId"}}`, encodeURIComponent(String(serviceId)))
                .replace(`{${"stageId"}}`, encodeURIComponent(String(stageId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof servicePlanEndDateTimeDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(servicePlanEndDateTimeDto !== undefined ? servicePlanEndDateTimeDto : {}) : (servicePlanEndDateTimeDto || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Set planEndDateTime for road map stage
         * @summary Set planEndDateTime for road map stage
         * @param {string} clientId 
         * @param {number} stageId 
         * @param {RoadMapStageUpdatePlanEndDateTimeDto} stagePlanEndDateTimeDto stagePlanEndDateTimeDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStagePlanEndDateTimeUsingPOST(clientId: string, stageId: number, stagePlanEndDateTimeDto: RoadMapStageUpdatePlanEndDateTimeDto, options: any = {}): RequestArgs {
            // verify required parameter 'clientId' is not null or undefined
            if (clientId === null || clientId === undefined) {
                throw new RequiredError('clientId','Required parameter clientId was null or undefined when calling updateStagePlanEndDateTimeUsingPOST.');
            }
            // verify required parameter 'stageId' is not null or undefined
            if (stageId === null || stageId === undefined) {
                throw new RequiredError('stageId','Required parameter stageId was null or undefined when calling updateStagePlanEndDateTimeUsingPOST.');
            }
            // verify required parameter 'stagePlanEndDateTimeDto' is not null or undefined
            if (stagePlanEndDateTimeDto === null || stagePlanEndDateTimeDto === undefined) {
                throw new RequiredError('stagePlanEndDateTimeDto','Required parameter stagePlanEndDateTimeDto was null or undefined when calling updateStagePlanEndDateTimeUsingPOST.');
            }
            const localVarPath = `/api/v1/{clientId}/road-map/stage/{stageId}/planEndDateTime`
                .replace(`{${"clientId"}}`, encodeURIComponent(String(clientId)))
                .replace(`{${"stageId"}}`, encodeURIComponent(String(stageId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof stagePlanEndDateTimeDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(stagePlanEndDateTimeDto !== undefined ? stagePlanEndDateTimeDto : {}) : (stagePlanEndDateTimeDto || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RoadMapControllerV1Api - functional programming interface
 * @export
 */
export const RoadMapControllerV1ApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Store market and product
         * @summary Store market and product
         * @param {ProductAndMarketRequestDto} productAndMarketRequestDto productAndMarketRequestDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addProductAndMarketToRoadMapUsingPOST(productAndMarketRequestDto: ProductAndMarketRequestDto, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseEntity> {
            const localVarAxiosArgs = RoadMapControllerV1ApiAxiosParamCreator(configuration).addProductAndMarketToRoadMapUsingPOST(productAndMarketRequestDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Add services from catalog to road map by manager
         * @summary Add services from catalog to road map by manager
         * @param {string} clientId 
         * @param {Array<AddServicesDto>} addServicesRequest addServicesRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addServicesByManagerUsingPOST(clientId: string, addServicesRequest: Array<AddServicesDto>, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseEntity> {
            const localVarAxiosArgs = RoadMapControllerV1ApiAxiosParamCreator(configuration).addServicesByManagerUsingPOST(clientId, addServicesRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Approve client road map
         * @summary Approve client road map
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        approveClientRoadMapUsingPOST(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseEntity> {
            const localVarAxiosArgs = RoadMapControllerV1ApiAxiosParamCreator(configuration).approveClientRoadMapUsingPOST(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Approve a road map service by client
         * @summary Approve a road map service by client
         * @param {number} serviceId 
         * @param {number} stageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        approveServiceByClientUsingPOST(serviceId: number, stageId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseEntity> {
            const localVarAxiosArgs = RoadMapControllerV1ApiAxiosParamCreator(configuration).approveServiceByClientUsingPOST(serviceId, stageId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Approve a road map service by manager
         * @summary Approve a road map service by manager
         * @param {string} clientId 
         * @param {number} serviceId 
         * @param {number} stageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        approveServiceByManagerUsingPOST(clientId: string, serviceId: number, stageId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseEntity> {
            const localVarAxiosArgs = RoadMapControllerV1ApiAxiosParamCreator(configuration).approveServiceByManagerUsingPOST(clientId, serviceId, stageId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Delete road map services by manager
         * @summary Delete service
         * @param {string} clientId 
         * @param {Array<DeleteRoadMapServicesDto>} deleteRoadMapServicesDto deleteRoadMapServicesDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteServicesByManagerUsingPOST(clientId: string, deleteRoadMapServicesDto: Array<DeleteRoadMapServicesDto>, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseEntity> {
            const localVarAxiosArgs = RoadMapControllerV1ApiAxiosParamCreator(configuration).deleteServicesByManagerUsingPOST(clientId, deleteRoadMapServicesDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Finish road map step and optionally upload files
         * @summary Finish road map step and optionally upload files
         * @param {number} stepId 
         * @param {FinishRoadMapStepRequestDto} finishRoadMapStepRequestDto finishRoadMapStepRequestDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        finishStepUsingPOST(stepId: number, finishRoadMapStepRequestDto: FinishRoadMapStepRequestDto, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseEntity> {
            const localVarAxiosArgs = RoadMapControllerV1ApiAxiosParamCreator(configuration).finishStepUsingPOST(stepId, finishRoadMapStepRequestDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get client market and product
         * @summary Get client market and product
         * @param {string} clientId clientId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClientProductAndMarketInRoadMapUsingGET(clientId: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductAndMarketResponseDto> {
            const localVarAxiosArgs = RoadMapControllerV1ApiAxiosParamCreator(configuration).getClientProductAndMarketInRoadMapUsingGET(clientId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get a client road map
         * @summary Get a client road map
         * @param {string} clientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClientRoadMapUsingGET(clientId: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoadMapDto> {
            const localVarAxiosArgs = RoadMapControllerV1ApiAxiosParamCreator(configuration).getClientRoadMapUsingGET(clientId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get market and product
         * @summary Get market and product
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductAndMarketInRoadMapUsingGET(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductAndMarketResponseDto> {
            const localVarAxiosArgs = RoadMapControllerV1ApiAxiosParamCreator(configuration).getProductAndMarketInRoadMapUsingGET(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get a road map
         * @summary Get a road map
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRoadMapUsingGET(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoadMapDto> {
            const localVarAxiosArgs = RoadMapControllerV1ApiAxiosParamCreator(configuration).getRoadMapUsingGET(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get a client road map pdf file
         * @summary Get a client road map pdf file
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRoadmapReportUsingGET(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string> {
            const localVarAxiosArgs = RoadMapControllerV1ApiAxiosParamCreator(configuration).getRoadmapReportUsingGET(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get a road map pdf file
         * @summary Get a road map pdf file
         * @param {string} clientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRoadmapReportUsingGET1(clientId: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string> {
            const localVarAxiosArgs = RoadMapControllerV1ApiAxiosParamCreator(configuration).getRoadmapReportUsingGET1(clientId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get road map service info
         * @summary Get road map service info
         * @param {string} clientId 
         * @param {number} serviceId 
         * @param {number} stageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getServiceInfoUsingGET(clientId: string, serviceId: number, stageId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoadMapServiceInfoDto> {
            const localVarAxiosArgs = RoadMapControllerV1ApiAxiosParamCreator(configuration).getServiceInfoUsingGET(clientId, serviceId, stageId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get road map steps results
         * @summary Get road map steps results
         * @param {string} clientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStepsResultsUsingGET(clientId: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RoadMapStepResultDto>> {
            const localVarAxiosArgs = RoadMapControllerV1ApiAxiosParamCreator(configuration).getStepsResultsUsingGET(clientId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Lock a road map by manager
         * @summary Lock a road map by manager
         * @param {string} clientId 
         * @param {ChangeBlockRequestDto} request request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lockRoadMapUsingPOST(clientId: string, request: ChangeBlockRequestDto, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseEntity> {
            const localVarAxiosArgs = RoadMapControllerV1ApiAxiosParamCreator(configuration).lockRoadMapUsingPOST(clientId, request, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Start road map service by client
         * @summary Start service
         * @param {number} serviceId 
         * @param {number} stageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startServiceByClientUsingPOST(serviceId: number, stageId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoadMapServiceStartedDto> {
            const localVarAxiosArgs = RoadMapControllerV1ApiAxiosParamCreator(configuration).startServiceByClientUsingPOST(serviceId, stageId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Unlock a road map by manager
         * @summary Unlock a road map by manager
         * @param {string} clientId clientId
         * @param {ChangeBlockRequestDto} request request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unlockRoadMapUsingPOST(clientId: string, request: ChangeBlockRequestDto, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseEntity> {
            const localVarAxiosArgs = RoadMapControllerV1ApiAxiosParamCreator(configuration).unlockRoadMapUsingPOST(clientId, request, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Update market and product
         * @summary Update market and product
         * @param {string} clientId clientId
         * @param {ProductAndMarketRequestDto} productAndMarketRequestDto productAndMarketRequestDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateClientProductAndMarketInRoadMapUsingPUT(clientId: string, productAndMarketRequestDto: ProductAndMarketRequestDto, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseEntity> {
            const localVarAxiosArgs = RoadMapControllerV1ApiAxiosParamCreator(configuration).updateClientProductAndMarketInRoadMapUsingPUT(clientId, productAndMarketRequestDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Update note to service
         * @summary Update note to service
         * @param {string} clientId 
         * @param {string} serviceId 
         * @param {string} stageId 
         * @param {string} serviceNoteRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNoteToServiceUsingPUT(clientId: string, serviceId: string, stageId: string, serviceNoteRequest: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseEntity> {
            const localVarAxiosArgs = RoadMapControllerV1ApiAxiosParamCreator(configuration).updateNoteToServiceUsingPUT(clientId, serviceId, stageId, serviceNoteRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Update market and product
         * @summary Update market and product
         * @param {ProductAndMarketRequestDto} productAndMarketRequestDto productAndMarketRequestDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProductAndMarketInRoadMapUsingPUT(productAndMarketRequestDto: ProductAndMarketRequestDto, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseEntity> {
            const localVarAxiosArgs = RoadMapControllerV1ApiAxiosParamCreator(configuration).updateProductAndMarketInRoadMapUsingPUT(productAndMarketRequestDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Set planEndDateTime for road map service
         * @summary Set planEndDateTime for road map service
         * @param {string} clientId 
         * @param {number} serviceId 
         * @param {number} stageId 
         * @param {RoadMapServiceUpdatePlanEndDateTimeDto} servicePlanEndDateTimeDto servicePlanEndDateTimeDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateServicePlanEndDateTimeUsingPOST(clientId: string, serviceId: number, stageId: number, servicePlanEndDateTimeDto: RoadMapServiceUpdatePlanEndDateTimeDto, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseEntity> {
            const localVarAxiosArgs = RoadMapControllerV1ApiAxiosParamCreator(configuration).updateServicePlanEndDateTimeUsingPOST(clientId, serviceId, stageId, servicePlanEndDateTimeDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Set planEndDateTime for road map stage
         * @summary Set planEndDateTime for road map stage
         * @param {string} clientId 
         * @param {number} stageId 
         * @param {RoadMapStageUpdatePlanEndDateTimeDto} stagePlanEndDateTimeDto stagePlanEndDateTimeDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStagePlanEndDateTimeUsingPOST(clientId: string, stageId: number, stagePlanEndDateTimeDto: RoadMapStageUpdatePlanEndDateTimeDto, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseEntity> {
            const localVarAxiosArgs = RoadMapControllerV1ApiAxiosParamCreator(configuration).updateStagePlanEndDateTimeUsingPOST(clientId, stageId, stagePlanEndDateTimeDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * RoadMapControllerV1Api - factory interface
 * @export
 */
export const RoadMapControllerV1ApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * Store market and product
         * @summary Store market and product
         * @param {ProductAndMarketRequestDto} productAndMarketRequestDto productAndMarketRequestDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addProductAndMarketToRoadMapUsingPOST(productAndMarketRequestDto: ProductAndMarketRequestDto, options?: any): AxiosPromise<ResponseEntity> {
            return RoadMapControllerV1ApiFp(configuration).addProductAndMarketToRoadMapUsingPOST(productAndMarketRequestDto, options)(axios, basePath);
        },
        /**
         * Add services from catalog to road map by manager
         * @summary Add services from catalog to road map by manager
         * @param {string} clientId 
         * @param {Array<AddServicesDto>} addServicesRequest addServicesRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addServicesByManagerUsingPOST(clientId: string, addServicesRequest: Array<AddServicesDto>, options?: any): AxiosPromise<ResponseEntity> {
            return RoadMapControllerV1ApiFp(configuration).addServicesByManagerUsingPOST(clientId, addServicesRequest, options)(axios, basePath);
        },
        /**
         * Approve client road map
         * @summary Approve client road map
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        approveClientRoadMapUsingPOST(options?: any): AxiosPromise<ResponseEntity> {
            return RoadMapControllerV1ApiFp(configuration).approveClientRoadMapUsingPOST(options)(axios, basePath);
        },
        /**
         * Approve a road map service by client
         * @summary Approve a road map service by client
         * @param {number} serviceId 
         * @param {number} stageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        approveServiceByClientUsingPOST(serviceId: number, stageId: number, options?: any): AxiosPromise<ResponseEntity> {
            return RoadMapControllerV1ApiFp(configuration).approveServiceByClientUsingPOST(serviceId, stageId, options)(axios, basePath);
        },
        /**
         * Approve a road map service by manager
         * @summary Approve a road map service by manager
         * @param {string} clientId 
         * @param {number} serviceId 
         * @param {number} stageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        approveServiceByManagerUsingPOST(clientId: string, serviceId: number, stageId: number, options?: any): AxiosPromise<ResponseEntity> {
            return RoadMapControllerV1ApiFp(configuration).approveServiceByManagerUsingPOST(clientId, serviceId, stageId, options)(axios, basePath);
        },
        /**
         * Delete road map services by manager
         * @summary Delete service
         * @param {string} clientId 
         * @param {Array<DeleteRoadMapServicesDto>} deleteRoadMapServicesDto deleteRoadMapServicesDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteServicesByManagerUsingPOST(clientId: string, deleteRoadMapServicesDto: Array<DeleteRoadMapServicesDto>, options?: any): AxiosPromise<ResponseEntity> {
            return RoadMapControllerV1ApiFp(configuration).deleteServicesByManagerUsingPOST(clientId, deleteRoadMapServicesDto, options)(axios, basePath);
        },
        /**
         * Finish road map step and optionally upload files
         * @summary Finish road map step and optionally upload files
         * @param {number} stepId 
         * @param {FinishRoadMapStepRequestDto} finishRoadMapStepRequestDto finishRoadMapStepRequestDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        finishStepUsingPOST(stepId: number, finishRoadMapStepRequestDto: FinishRoadMapStepRequestDto, options?: any): AxiosPromise<ResponseEntity> {
            return RoadMapControllerV1ApiFp(configuration).finishStepUsingPOST(stepId, finishRoadMapStepRequestDto, options)(axios, basePath);
        },
        /**
         * Get client market and product
         * @summary Get client market and product
         * @param {string} clientId clientId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClientProductAndMarketInRoadMapUsingGET(clientId: string, options?: any): AxiosPromise<ProductAndMarketResponseDto> {
            return RoadMapControllerV1ApiFp(configuration).getClientProductAndMarketInRoadMapUsingGET(clientId, options)(axios, basePath);
        },
        /**
         * Get a client road map
         * @summary Get a client road map
         * @param {string} clientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClientRoadMapUsingGET(clientId: string, options?: any): AxiosPromise<RoadMapDto> {
            return RoadMapControllerV1ApiFp(configuration).getClientRoadMapUsingGET(clientId, options)(axios, basePath);
        },
        /**
         * Get market and product
         * @summary Get market and product
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductAndMarketInRoadMapUsingGET(options?: any): AxiosPromise<ProductAndMarketResponseDto> {
            return RoadMapControllerV1ApiFp(configuration).getProductAndMarketInRoadMapUsingGET(options)(axios, basePath);
        },
        /**
         * Get a road map
         * @summary Get a road map
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRoadMapUsingGET(options?: any): AxiosPromise<RoadMapDto> {
            return RoadMapControllerV1ApiFp(configuration).getRoadMapUsingGET(options)(axios, basePath);
        },
        /**
         * Get a client road map pdf file
         * @summary Get a client road map pdf file
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRoadmapReportUsingGET(options?: any): AxiosPromise<string> {
            return RoadMapControllerV1ApiFp(configuration).getRoadmapReportUsingGET(options)(axios, basePath);
        },
        /**
         * Get a road map pdf file
         * @summary Get a road map pdf file
         * @param {string} clientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRoadmapReportUsingGET1(clientId: string, options?: any): AxiosPromise<string> {
            return RoadMapControllerV1ApiFp(configuration).getRoadmapReportUsingGET1(clientId, options)(axios, basePath);
        },
        /**
         * Get road map service info
         * @summary Get road map service info
         * @param {string} clientId 
         * @param {number} serviceId 
         * @param {number} stageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getServiceInfoUsingGET(clientId: string, serviceId: number, stageId: number, options?: any): AxiosPromise<RoadMapServiceInfoDto> {
            return RoadMapControllerV1ApiFp(configuration).getServiceInfoUsingGET(clientId, serviceId, stageId, options)(axios, basePath);
        },
        /**
         * Get road map steps results
         * @summary Get road map steps results
         * @param {string} clientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStepsResultsUsingGET(clientId: string, options?: any): AxiosPromise<Array<RoadMapStepResultDto>> {
            return RoadMapControllerV1ApiFp(configuration).getStepsResultsUsingGET(clientId, options)(axios, basePath);
        },
        /**
         * Lock a road map by manager
         * @summary Lock a road map by manager
         * @param {string} clientId 
         * @param {ChangeBlockRequestDto} request request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lockRoadMapUsingPOST(clientId: string, request: ChangeBlockRequestDto, options?: any): AxiosPromise<ResponseEntity> {
            return RoadMapControllerV1ApiFp(configuration).lockRoadMapUsingPOST(clientId, request, options)(axios, basePath);
        },
        /**
         * Start road map service by client
         * @summary Start service
         * @param {number} serviceId 
         * @param {number} stageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startServiceByClientUsingPOST(serviceId: number, stageId: number, options?: any): AxiosPromise<RoadMapServiceStartedDto> {
            return RoadMapControllerV1ApiFp(configuration).startServiceByClientUsingPOST(serviceId, stageId, options)(axios, basePath);
        },
        /**
         * Unlock a road map by manager
         * @summary Unlock a road map by manager
         * @param {string} clientId clientId
         * @param {ChangeBlockRequestDto} request request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unlockRoadMapUsingPOST(clientId: string, request: ChangeBlockRequestDto, options?: any): AxiosPromise<ResponseEntity> {
            return RoadMapControllerV1ApiFp(configuration).unlockRoadMapUsingPOST(clientId, request, options)(axios, basePath);
        },
        /**
         * Update market and product
         * @summary Update market and product
         * @param {string} clientId clientId
         * @param {ProductAndMarketRequestDto} productAndMarketRequestDto productAndMarketRequestDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateClientProductAndMarketInRoadMapUsingPUT(clientId: string, productAndMarketRequestDto: ProductAndMarketRequestDto, options?: any): AxiosPromise<ResponseEntity> {
            return RoadMapControllerV1ApiFp(configuration).updateClientProductAndMarketInRoadMapUsingPUT(clientId, productAndMarketRequestDto, options)(axios, basePath);
        },
        /**
         * Update note to service
         * @summary Update note to service
         * @param {string} clientId 
         * @param {string} serviceId 
         * @param {string} stageId 
         * @param {string} serviceNoteRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNoteToServiceUsingPUT(clientId: string, serviceId: string, stageId: string, serviceNoteRequest: string, options?: any): AxiosPromise<ResponseEntity> {
            return RoadMapControllerV1ApiFp(configuration).updateNoteToServiceUsingPUT(clientId, serviceId, stageId, serviceNoteRequest, options)(axios, basePath);
        },
        /**
         * Update market and product
         * @summary Update market and product
         * @param {ProductAndMarketRequestDto} productAndMarketRequestDto productAndMarketRequestDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProductAndMarketInRoadMapUsingPUT(productAndMarketRequestDto: ProductAndMarketRequestDto, options?: any): AxiosPromise<ResponseEntity> {
            return RoadMapControllerV1ApiFp(configuration).updateProductAndMarketInRoadMapUsingPUT(productAndMarketRequestDto, options)(axios, basePath);
        },
        /**
         * Set planEndDateTime for road map service
         * @summary Set planEndDateTime for road map service
         * @param {string} clientId 
         * @param {number} serviceId 
         * @param {number} stageId 
         * @param {RoadMapServiceUpdatePlanEndDateTimeDto} servicePlanEndDateTimeDto servicePlanEndDateTimeDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateServicePlanEndDateTimeUsingPOST(clientId: string, serviceId: number, stageId: number, servicePlanEndDateTimeDto: RoadMapServiceUpdatePlanEndDateTimeDto, options?: any): AxiosPromise<ResponseEntity> {
            return RoadMapControllerV1ApiFp(configuration).updateServicePlanEndDateTimeUsingPOST(clientId, serviceId, stageId, servicePlanEndDateTimeDto, options)(axios, basePath);
        },
        /**
         * Set planEndDateTime for road map stage
         * @summary Set planEndDateTime for road map stage
         * @param {string} clientId 
         * @param {number} stageId 
         * @param {RoadMapStageUpdatePlanEndDateTimeDto} stagePlanEndDateTimeDto stagePlanEndDateTimeDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStagePlanEndDateTimeUsingPOST(clientId: string, stageId: number, stagePlanEndDateTimeDto: RoadMapStageUpdatePlanEndDateTimeDto, options?: any): AxiosPromise<ResponseEntity> {
            return RoadMapControllerV1ApiFp(configuration).updateStagePlanEndDateTimeUsingPOST(clientId, stageId, stagePlanEndDateTimeDto, options)(axios, basePath);
        },
    };
};

/**
 * RoadMapControllerV1Api - object-oriented interface
 * @export
 * @class RoadMapControllerV1Api
 * @extends {BaseAPI}
 */
export class RoadMapControllerV1Api extends BaseAPI {
    /**
     * Store market and product
     * @summary Store market and product
     * @param {ProductAndMarketRequestDto} productAndMarketRequestDto productAndMarketRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoadMapControllerV1Api
     */
    public addProductAndMarketToRoadMapUsingPOST(productAndMarketRequestDto: ProductAndMarketRequestDto, options?: any) {
        return RoadMapControllerV1ApiFp(this.configuration).addProductAndMarketToRoadMapUsingPOST(productAndMarketRequestDto, options)(this.axios, this.basePath);
    }

    /**
     * Add services from catalog to road map by manager
     * @summary Add services from catalog to road map by manager
     * @param {string} clientId 
     * @param {Array<AddServicesDto>} addServicesRequest addServicesRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoadMapControllerV1Api
     */
    public addServicesByManagerUsingPOST(clientId: string, addServicesRequest: Array<AddServicesDto>, options?: any) {
        return RoadMapControllerV1ApiFp(this.configuration).addServicesByManagerUsingPOST(clientId, addServicesRequest, options)(this.axios, this.basePath);
    }

    /**
     * Approve client road map
     * @summary Approve client road map
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoadMapControllerV1Api
     */
    public approveClientRoadMapUsingPOST(options?: any) {
        return RoadMapControllerV1ApiFp(this.configuration).approveClientRoadMapUsingPOST(options)(this.axios, this.basePath);
    }

    /**
     * Approve a road map service by client
     * @summary Approve a road map service by client
     * @param {number} serviceId 
     * @param {number} stageId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoadMapControllerV1Api
     */
    public approveServiceByClientUsingPOST(serviceId: number, stageId: number, options?: any) {
        return RoadMapControllerV1ApiFp(this.configuration).approveServiceByClientUsingPOST(serviceId, stageId, options)(this.axios, this.basePath);
    }

    /**
     * Approve a road map service by manager
     * @summary Approve a road map service by manager
     * @param {string} clientId 
     * @param {number} serviceId 
     * @param {number} stageId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoadMapControllerV1Api
     */
    public approveServiceByManagerUsingPOST(clientId: string, serviceId: number, stageId: number, options?: any) {
        return RoadMapControllerV1ApiFp(this.configuration).approveServiceByManagerUsingPOST(clientId, serviceId, stageId, options)(this.axios, this.basePath);
    }

    /**
     * Delete road map services by manager
     * @summary Delete service
     * @param {string} clientId 
     * @param {Array<DeleteRoadMapServicesDto>} deleteRoadMapServicesDto deleteRoadMapServicesDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoadMapControllerV1Api
     */
    public deleteServicesByManagerUsingPOST(clientId: string, deleteRoadMapServicesDto: Array<DeleteRoadMapServicesDto>, options?: any) {
        return RoadMapControllerV1ApiFp(this.configuration).deleteServicesByManagerUsingPOST(clientId, deleteRoadMapServicesDto, options)(this.axios, this.basePath);
    }

    /**
     * Finish road map step and optionally upload files
     * @summary Finish road map step and optionally upload files
     * @param {number} stepId 
     * @param {FinishRoadMapStepRequestDto} finishRoadMapStepRequestDto finishRoadMapStepRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoadMapControllerV1Api
     */
    public finishStepUsingPOST(stepId: number, finishRoadMapStepRequestDto: FinishRoadMapStepRequestDto, options?: any) {
        return RoadMapControllerV1ApiFp(this.configuration).finishStepUsingPOST(stepId, finishRoadMapStepRequestDto, options)(this.axios, this.basePath);
    }

    /**
     * Get client market and product
     * @summary Get client market and product
     * @param {string} clientId clientId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoadMapControllerV1Api
     */
    public getClientProductAndMarketInRoadMapUsingGET(clientId: string, options?: any) {
        return RoadMapControllerV1ApiFp(this.configuration).getClientProductAndMarketInRoadMapUsingGET(clientId, options)(this.axios, this.basePath);
    }

    /**
     * Get a client road map
     * @summary Get a client road map
     * @param {string} clientId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoadMapControllerV1Api
     */
    public getClientRoadMapUsingGET(clientId: string, options?: any) {
        return RoadMapControllerV1ApiFp(this.configuration).getClientRoadMapUsingGET(clientId, options)(this.axios, this.basePath);
    }

    /**
     * Get market and product
     * @summary Get market and product
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoadMapControllerV1Api
     */
    public getProductAndMarketInRoadMapUsingGET(options?: any) {
        return RoadMapControllerV1ApiFp(this.configuration).getProductAndMarketInRoadMapUsingGET(options)(this.axios, this.basePath);
    }

    /**
     * Get a road map
     * @summary Get a road map
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoadMapControllerV1Api
     */
    public getRoadMapUsingGET(options?: any) {
        return RoadMapControllerV1ApiFp(this.configuration).getRoadMapUsingGET(options)(this.axios, this.basePath);
    }

    /**
     * Get a client road map pdf file
     * @summary Get a client road map pdf file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoadMapControllerV1Api
     */
    public getRoadmapReportUsingGET(options?: any) {
        return RoadMapControllerV1ApiFp(this.configuration).getRoadmapReportUsingGET(options)(this.axios, this.basePath);
    }

    /**
     * Get a road map pdf file
     * @summary Get a road map pdf file
     * @param {string} clientId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoadMapControllerV1Api
     */
    public getRoadmapReportUsingGET1(clientId: string, options?: any) {
        return RoadMapControllerV1ApiFp(this.configuration).getRoadmapReportUsingGET1(clientId, options)(this.axios, this.basePath);
    }

    /**
     * Get road map service info
     * @summary Get road map service info
     * @param {string} clientId 
     * @param {number} serviceId 
     * @param {number} stageId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoadMapControllerV1Api
     */
    public getServiceInfoUsingGET(clientId: string, serviceId: number, stageId: number, options?: any) {
        return RoadMapControllerV1ApiFp(this.configuration).getServiceInfoUsingGET(clientId, serviceId, stageId, options)(this.axios, this.basePath);
    }

    /**
     * Get road map steps results
     * @summary Get road map steps results
     * @param {string} clientId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoadMapControllerV1Api
     */
    public getStepsResultsUsingGET(clientId: string, options?: any) {
        return RoadMapControllerV1ApiFp(this.configuration).getStepsResultsUsingGET(clientId, options)(this.axios, this.basePath);
    }

    /**
     * Lock a road map by manager
     * @summary Lock a road map by manager
     * @param {string} clientId 
     * @param {ChangeBlockRequestDto} request request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoadMapControllerV1Api
     */
    public lockRoadMapUsingPOST(clientId: string, request: ChangeBlockRequestDto, options?: any) {
        return RoadMapControllerV1ApiFp(this.configuration).lockRoadMapUsingPOST(clientId, request, options)(this.axios, this.basePath);
    }

    /**
     * Start road map service by client
     * @summary Start service
     * @param {number} serviceId 
     * @param {number} stageId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoadMapControllerV1Api
     */
    public startServiceByClientUsingPOST(serviceId: number, stageId: number, options?: any) {
        return RoadMapControllerV1ApiFp(this.configuration).startServiceByClientUsingPOST(serviceId, stageId, options)(this.axios, this.basePath);
    }

    /**
     * Unlock a road map by manager
     * @summary Unlock a road map by manager
     * @param {string} clientId clientId
     * @param {ChangeBlockRequestDto} request request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoadMapControllerV1Api
     */
    public unlockRoadMapUsingPOST(clientId: string, request: ChangeBlockRequestDto, options?: any) {
        return RoadMapControllerV1ApiFp(this.configuration).unlockRoadMapUsingPOST(clientId, request, options)(this.axios, this.basePath);
    }

    /**
     * Update market and product
     * @summary Update market and product
     * @param {string} clientId clientId
     * @param {ProductAndMarketRequestDto} productAndMarketRequestDto productAndMarketRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoadMapControllerV1Api
     */
    public updateClientProductAndMarketInRoadMapUsingPUT(clientId: string, productAndMarketRequestDto: ProductAndMarketRequestDto, options?: any) {
        return RoadMapControllerV1ApiFp(this.configuration).updateClientProductAndMarketInRoadMapUsingPUT(clientId, productAndMarketRequestDto, options)(this.axios, this.basePath);
    }

    /**
     * Update note to service
     * @summary Update note to service
     * @param {string} clientId 
     * @param {string} serviceId 
     * @param {string} stageId 
     * @param {string} serviceNoteRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoadMapControllerV1Api
     */
    public updateNoteToServiceUsingPUT(clientId: string, serviceId: string, stageId: string, serviceNoteRequest: string, options?: any) {
        return RoadMapControllerV1ApiFp(this.configuration).updateNoteToServiceUsingPUT(clientId, serviceId, stageId, serviceNoteRequest, options)(this.axios, this.basePath);
    }

    /**
     * Update market and product
     * @summary Update market and product
     * @param {ProductAndMarketRequestDto} productAndMarketRequestDto productAndMarketRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoadMapControllerV1Api
     */
    public updateProductAndMarketInRoadMapUsingPUT(productAndMarketRequestDto: ProductAndMarketRequestDto, options?: any) {
        return RoadMapControllerV1ApiFp(this.configuration).updateProductAndMarketInRoadMapUsingPUT(productAndMarketRequestDto, options)(this.axios, this.basePath);
    }

    /**
     * Set planEndDateTime for road map service
     * @summary Set planEndDateTime for road map service
     * @param {string} clientId 
     * @param {number} serviceId 
     * @param {number} stageId 
     * @param {RoadMapServiceUpdatePlanEndDateTimeDto} servicePlanEndDateTimeDto servicePlanEndDateTimeDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoadMapControllerV1Api
     */
    public updateServicePlanEndDateTimeUsingPOST(clientId: string, serviceId: number, stageId: number, servicePlanEndDateTimeDto: RoadMapServiceUpdatePlanEndDateTimeDto, options?: any) {
        return RoadMapControllerV1ApiFp(this.configuration).updateServicePlanEndDateTimeUsingPOST(clientId, serviceId, stageId, servicePlanEndDateTimeDto, options)(this.axios, this.basePath);
    }

    /**
     * Set planEndDateTime for road map stage
     * @summary Set planEndDateTime for road map stage
     * @param {string} clientId 
     * @param {number} stageId 
     * @param {RoadMapStageUpdatePlanEndDateTimeDto} stagePlanEndDateTimeDto stagePlanEndDateTimeDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoadMapControllerV1Api
     */
    public updateStagePlanEndDateTimeUsingPOST(clientId: string, stageId: number, stagePlanEndDateTimeDto: RoadMapStageUpdatePlanEndDateTimeDto, options?: any) {
        return RoadMapControllerV1ApiFp(this.configuration).updateStagePlanEndDateTimeUsingPOST(clientId, stageId, stagePlanEndDateTimeDto, options)(this.axios, this.basePath);
    }

}


/**
 * SearchControllerV1Api - axios parameter creator
 * @export
 */
export const SearchControllerV1ApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Search
         * @summary Search
         * @param {SearchRequestDto} searchRequestDto searchRequestDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchUsingPOST(searchRequestDto: SearchRequestDto, options: any = {}): RequestArgs {
            // verify required parameter 'searchRequestDto' is not null or undefined
            if (searchRequestDto === null || searchRequestDto === undefined) {
                throw new RequiredError('searchRequestDto','Required parameter searchRequestDto was null or undefined when calling searchUsingPOST.');
            }
            const localVarPath = `/api/v1/search`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof searchRequestDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(searchRequestDto !== undefined ? searchRequestDto : {}) : (searchRequestDto || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SearchControllerV1Api - functional programming interface
 * @export
 */
export const SearchControllerV1ApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Search
         * @summary Search
         * @param {SearchRequestDto} searchRequestDto searchRequestDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchUsingPOST(searchRequestDto: SearchRequestDto, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SearchResultDto>> {
            const localVarAxiosArgs = SearchControllerV1ApiAxiosParamCreator(configuration).searchUsingPOST(searchRequestDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * SearchControllerV1Api - factory interface
 * @export
 */
export const SearchControllerV1ApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * Search
         * @summary Search
         * @param {SearchRequestDto} searchRequestDto searchRequestDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchUsingPOST(searchRequestDto: SearchRequestDto, options?: any): AxiosPromise<Array<SearchResultDto>> {
            return SearchControllerV1ApiFp(configuration).searchUsingPOST(searchRequestDto, options)(axios, basePath);
        },
    };
};

/**
 * SearchControllerV1Api - object-oriented interface
 * @export
 * @class SearchControllerV1Api
 * @extends {BaseAPI}
 */
export class SearchControllerV1Api extends BaseAPI {
    /**
     * Search
     * @summary Search
     * @param {SearchRequestDto} searchRequestDto searchRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SearchControllerV1Api
     */
    public searchUsingPOST(searchRequestDto: SearchRequestDto, options?: any) {
        return SearchControllerV1ApiFp(this.configuration).searchUsingPOST(searchRequestDto, options)(this.axios, this.basePath);
    }

}


/**
 * ServicesCatalogControllerV1Api - axios parameter creator
 * @export
 */
export const ServicesCatalogControllerV1ApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get catalog service by uuid
         * @summary Get catalog service by uuid
         * @param {string} serviceUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getServiceUsingGET(serviceUuid: string, options: any = {}): RequestArgs {
            // verify required parameter 'serviceUuid' is not null or undefined
            if (serviceUuid === null || serviceUuid === undefined) {
                throw new RequiredError('serviceUuid','Required parameter serviceUuid was null or undefined when calling getServiceUsingGET.');
            }
            const localVarPath = `/api/v1/api/v1/services-catalog/services/{serviceUuid}`
                .replace(`{${"serviceUuid"}}`, encodeURIComponent(String(serviceUuid)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get list of catalog services
         * @summary Get list of catalog services
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getServicesUsingGET(options: any = {}): RequestArgs {
            const localVarPath = `/api/v1/services-catalog/services`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ServicesCatalogControllerV1Api - functional programming interface
 * @export
 */
export const ServicesCatalogControllerV1ApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Get catalog service by uuid
         * @summary Get catalog service by uuid
         * @param {string} serviceUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getServiceUsingGET(serviceUuid: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ServiceItemDto> {
            const localVarAxiosArgs = ServicesCatalogControllerV1ApiAxiosParamCreator(configuration).getServiceUsingGET(serviceUuid, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get list of catalog services
         * @summary Get list of catalog services
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getServicesUsingGET(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ServiceDto>> {
            const localVarAxiosArgs = ServicesCatalogControllerV1ApiAxiosParamCreator(configuration).getServicesUsingGET(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ServicesCatalogControllerV1Api - factory interface
 * @export
 */
export const ServicesCatalogControllerV1ApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * Get catalog service by uuid
         * @summary Get catalog service by uuid
         * @param {string} serviceUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getServiceUsingGET(serviceUuid: string, options?: any): AxiosPromise<ServiceItemDto> {
            return ServicesCatalogControllerV1ApiFp(configuration).getServiceUsingGET(serviceUuid, options)(axios, basePath);
        },
        /**
         * Get list of catalog services
         * @summary Get list of catalog services
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getServicesUsingGET(options?: any): AxiosPromise<Array<ServiceDto>> {
            return ServicesCatalogControllerV1ApiFp(configuration).getServicesUsingGET(options)(axios, basePath);
        },
    };
};

/**
 * ServicesCatalogControllerV1Api - object-oriented interface
 * @export
 * @class ServicesCatalogControllerV1Api
 * @extends {BaseAPI}
 */
export class ServicesCatalogControllerV1Api extends BaseAPI {
    /**
     * Get catalog service by uuid
     * @summary Get catalog service by uuid
     * @param {string} serviceUuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServicesCatalogControllerV1Api
     */
    public getServiceUsingGET(serviceUuid: string, options?: any) {
        return ServicesCatalogControllerV1ApiFp(this.configuration).getServiceUsingGET(serviceUuid, options)(this.axios, this.basePath);
    }

    /**
     * Get list of catalog services
     * @summary Get list of catalog services
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServicesCatalogControllerV1Api
     */
    public getServicesUsingGET(options?: any) {
        return ServicesCatalogControllerV1ApiFp(this.configuration).getServicesUsingGET(options)(this.axios, this.basePath);
    }

}


/**
 * SurveyControllerV1Api - axios parameter creator
 * @export
 */
export const SurveyControllerV1ApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Completing the survey
         * @summary Completing the survey
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        completeSurveyUsingPOST(options: any = {}): RequestArgs {
            const localVarPath = `/api/v1/survey/finish`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get a survey answered list
         * @summary Get a survey answered list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSurveyAnsweredUsingGET(options: any = {}): RequestArgs {
            const localVarPath = `/api/v1/survey/answered`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get survey existing status
         * @summary Get survey existing status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSurveyExistingStatusUsingGET(options: any = {}): RequestArgs {
            const localVarPath = `/api/v1/survey/status`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get survey results
         * @summary Get survey results
         * @param {string} clientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSurveyResultUsingGET(clientId: string, options: any = {}): RequestArgs {
            // verify required parameter 'clientId' is not null or undefined
            if (clientId === null || clientId === undefined) {
                throw new RequiredError('clientId','Required parameter clientId was null or undefined when calling getSurveyResultUsingGET.');
            }
            const localVarPath = `/api/v1/survey/{clientId}/survey/`
                .replace(`{${"clientId"}}`, encodeURIComponent(String(clientId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get a survey questionnaire
         * @summary Get a survey questionnaire
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSurveyUsingGET(options: any = {}): RequestArgs {
            const localVarPath = `/api/v1/survey`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Submit a survey questionnaire answer
         * @summary Submit a survey questionnaire answer
         * @param {SurveyAnswerRequestDto} surveyAnswer surveyAnswer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitAnswerUsingPOST(surveyAnswer: SurveyAnswerRequestDto, options: any = {}): RequestArgs {
            // verify required parameter 'surveyAnswer' is not null or undefined
            if (surveyAnswer === null || surveyAnswer === undefined) {
                throw new RequiredError('surveyAnswer','Required parameter surveyAnswer was null or undefined when calling submitAnswerUsingPOST.');
            }
            const localVarPath = `/api/v1/survey/answer`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof surveyAnswer !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(surveyAnswer !== undefined ? surveyAnswer : {}) : (surveyAnswer || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SurveyControllerV1Api - functional programming interface
 * @export
 */
export const SurveyControllerV1ApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Completing the survey
         * @summary Completing the survey
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        completeSurveyUsingPOST(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SurveyCompleteResultDto> {
            const localVarAxiosArgs = SurveyControllerV1ApiAxiosParamCreator(configuration).completeSurveyUsingPOST(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get a survey answered list
         * @summary Get a survey answered list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSurveyAnsweredUsingGET(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SurveyAnsweredDto>> {
            const localVarAxiosArgs = SurveyControllerV1ApiAxiosParamCreator(configuration).getSurveyAnsweredUsingGET(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get survey existing status
         * @summary Get survey existing status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSurveyExistingStatusUsingGET(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SurveyExistingDto> {
            const localVarAxiosArgs = SurveyControllerV1ApiAxiosParamCreator(configuration).getSurveyExistingStatusUsingGET(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get survey results
         * @summary Get survey results
         * @param {string} clientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSurveyResultUsingGET(clientId: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SurveyGroupsResponseDto> {
            const localVarAxiosArgs = SurveyControllerV1ApiAxiosParamCreator(configuration).getSurveyResultUsingGET(clientId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get a survey questionnaire
         * @summary Get a survey questionnaire
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSurveyUsingGET(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SurveyGroupDto>> {
            const localVarAxiosArgs = SurveyControllerV1ApiAxiosParamCreator(configuration).getSurveyUsingGET(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Submit a survey questionnaire answer
         * @summary Submit a survey questionnaire answer
         * @param {SurveyAnswerRequestDto} surveyAnswer surveyAnswer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitAnswerUsingPOST(surveyAnswer: SurveyAnswerRequestDto, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string> {
            const localVarAxiosArgs = SurveyControllerV1ApiAxiosParamCreator(configuration).submitAnswerUsingPOST(surveyAnswer, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * SurveyControllerV1Api - factory interface
 * @export
 */
export const SurveyControllerV1ApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * Completing the survey
         * @summary Completing the survey
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        completeSurveyUsingPOST(options?: any): AxiosPromise<SurveyCompleteResultDto> {
            return SurveyControllerV1ApiFp(configuration).completeSurveyUsingPOST(options)(axios, basePath);
        },
        /**
         * Get a survey answered list
         * @summary Get a survey answered list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSurveyAnsweredUsingGET(options?: any): AxiosPromise<Array<SurveyAnsweredDto>> {
            return SurveyControllerV1ApiFp(configuration).getSurveyAnsweredUsingGET(options)(axios, basePath);
        },
        /**
         * Get survey existing status
         * @summary Get survey existing status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSurveyExistingStatusUsingGET(options?: any): AxiosPromise<SurveyExistingDto> {
            return SurveyControllerV1ApiFp(configuration).getSurveyExistingStatusUsingGET(options)(axios, basePath);
        },
        /**
         * Get survey results
         * @summary Get survey results
         * @param {string} clientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSurveyResultUsingGET(clientId: string, options?: any): AxiosPromise<SurveyGroupsResponseDto> {
            return SurveyControllerV1ApiFp(configuration).getSurveyResultUsingGET(clientId, options)(axios, basePath);
        },
        /**
         * Get a survey questionnaire
         * @summary Get a survey questionnaire
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSurveyUsingGET(options?: any): AxiosPromise<Array<SurveyGroupDto>> {
            return SurveyControllerV1ApiFp(configuration).getSurveyUsingGET(options)(axios, basePath);
        },
        /**
         * Submit a survey questionnaire answer
         * @summary Submit a survey questionnaire answer
         * @param {SurveyAnswerRequestDto} surveyAnswer surveyAnswer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitAnswerUsingPOST(surveyAnswer: SurveyAnswerRequestDto, options?: any): AxiosPromise<string> {
            return SurveyControllerV1ApiFp(configuration).submitAnswerUsingPOST(surveyAnswer, options)(axios, basePath);
        },
    };
};

/**
 * SurveyControllerV1Api - object-oriented interface
 * @export
 * @class SurveyControllerV1Api
 * @extends {BaseAPI}
 */
export class SurveyControllerV1Api extends BaseAPI {
    /**
     * Completing the survey
     * @summary Completing the survey
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SurveyControllerV1Api
     */
    public completeSurveyUsingPOST(options?: any) {
        return SurveyControllerV1ApiFp(this.configuration).completeSurveyUsingPOST(options)(this.axios, this.basePath);
    }

    /**
     * Get a survey answered list
     * @summary Get a survey answered list
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SurveyControllerV1Api
     */
    public getSurveyAnsweredUsingGET(options?: any) {
        return SurveyControllerV1ApiFp(this.configuration).getSurveyAnsweredUsingGET(options)(this.axios, this.basePath);
    }

    /**
     * Get survey existing status
     * @summary Get survey existing status
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SurveyControllerV1Api
     */
    public getSurveyExistingStatusUsingGET(options?: any) {
        return SurveyControllerV1ApiFp(this.configuration).getSurveyExistingStatusUsingGET(options)(this.axios, this.basePath);
    }

    /**
     * Get survey results
     * @summary Get survey results
     * @param {string} clientId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SurveyControllerV1Api
     */
    public getSurveyResultUsingGET(clientId: string, options?: any) {
        return SurveyControllerV1ApiFp(this.configuration).getSurveyResultUsingGET(clientId, options)(this.axios, this.basePath);
    }

    /**
     * Get a survey questionnaire
     * @summary Get a survey questionnaire
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SurveyControllerV1Api
     */
    public getSurveyUsingGET(options?: any) {
        return SurveyControllerV1ApiFp(this.configuration).getSurveyUsingGET(options)(this.axios, this.basePath);
    }

    /**
     * Submit a survey questionnaire answer
     * @summary Submit a survey questionnaire answer
     * @param {SurveyAnswerRequestDto} surveyAnswer surveyAnswer
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SurveyControllerV1Api
     */
    public submitAnswerUsingPOST(surveyAnswer: SurveyAnswerRequestDto, options?: any) {
        return SurveyControllerV1ApiFp(this.configuration).submitAnswerUsingPOST(surveyAnswer, options)(this.axios, this.basePath);
    }

}


/**
 * SystemAuthControllerApi - axios parameter creator
 * @export
 */
export const SystemAuthControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary getAuthHeader
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAuthHeaderUsingGET(options: any = {}): RequestArgs {
            const localVarPath = `/api/systemtoken/@header`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTokenUsingGET(options: any = {}): RequestArgs {
            const localVarPath = `/api/systemtoken/@token`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SystemAuthControllerApi - functional programming interface
 * @export
 */
export const SystemAuthControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary getAuthHeader
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAuthHeaderUsingGET(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string> {
            const localVarAxiosArgs = SystemAuthControllerApiAxiosParamCreator(configuration).getAuthHeaderUsingGET(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary getToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTokenUsingGET(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string> {
            const localVarAxiosArgs = SystemAuthControllerApiAxiosParamCreator(configuration).getTokenUsingGET(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * SystemAuthControllerApi - factory interface
 * @export
 */
export const SystemAuthControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary getAuthHeader
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAuthHeaderUsingGET(options?: any): AxiosPromise<string> {
            return SystemAuthControllerApiFp(configuration).getAuthHeaderUsingGET(options)(axios, basePath);
        },
        /**
         * 
         * @summary getToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTokenUsingGET(options?: any): AxiosPromise<string> {
            return SystemAuthControllerApiFp(configuration).getTokenUsingGET(options)(axios, basePath);
        },
    };
};

/**
 * SystemAuthControllerApi - object-oriented interface
 * @export
 * @class SystemAuthControllerApi
 * @extends {BaseAPI}
 */
export class SystemAuthControllerApi extends BaseAPI {
    /**
     * 
     * @summary getAuthHeader
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemAuthControllerApi
     */
    public getAuthHeaderUsingGET(options?: any) {
        return SystemAuthControllerApiFp(this.configuration).getAuthHeaderUsingGET(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary getToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemAuthControllerApi
     */
    public getTokenUsingGET(options?: any) {
        return SystemAuthControllerApiFp(this.configuration).getTokenUsingGET(options)(this.axios, this.basePath);
    }

}


