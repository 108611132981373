import React from "react";
import css from "./index.module.css";

const LoadingScreen = () => (
  <div className={css.container}>
    <div className={css.loadingText}>Загрузка</div>
  </div>
);

export default LoadingScreen;
