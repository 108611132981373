import "./lib/theme.css";
import "react-app-polyfill/ie11";
import * as serviceWorker from "./serviceWorker";
import { App } from "./features/core";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import { Router } from "react-router-dom";
import { UidmProvider } from "uidm-react-lib";
import { createBrowserHistory } from "history";
import LoadingScreen from "ui/atoms/LoadingScreen";
import React from "react";
import ReactDOM from "react-dom";
import configureStore from "./store/configureStore";
import i from "./lib/i18n";
import rootReducer from "./store/rootReducer";
import rootSaga from "./store/rootSaga";

i.changeLanguage("ru");

const history = createBrowserHistory();

const { store, persistor } = configureStore(rootReducer, rootSaga, history);

window._env = Object.assign(process.env, window._env);

const uidmConfig = {
  clientId: "accelerator",
  ssoBaseUrl: window._env.REACT_APP_SSO_BASE_URL,
  oauthConsumerBaseUrl: window._env.REACT_APP_OAUTH_CONSUMER_BASE_URL,
  useOauthConsumerBaseUrl: true,
  useModalLogin: true,
};

const handleUidmEvent = (event: string | object, error: any) => {
  // if (typeof event === "object" && (event as any).type === "login") {
  //   store.dispatch(loginSuccessful((event as any).data));
  // }
};
class ErrorWrapper extends React.Component {
  componentDidCatch(error: any, errorInfo: any) {
    // eslint-disable-next-line no-console
    console.log(error, errorInfo);
  }
  render() {
    return this.props.children;
  }
}
ReactDOM.render(
  <ErrorWrapper>
    <UidmProvider
      autoRefresh
      initConfig={uidmConfig}
      // eslint-disable-next-line react/jsx-sort-props
      LoadingComponent={<LoadingScreen />}
      onEvent={handleUidmEvent}
    >
      <Router history={history}>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <App />
          </PersistGate>
        </Provider>
      </Router>
    </UidmProvider>
  </ErrorWrapper>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
