import { Link } from "react-router-dom";
import BlueButton from "../../atoms/BlueButton";
import React from "react";
import css from "./hello.module.css";

const Hello = () => {
  return (
    <>
      <div className={css.hello}>
        <div className={css.hero}>
          <h1 className={css.title}>
            Начните свой путь
            <br />в мире экспорта
          </h1>
          <div className={css.subtitle}>
            Заполните анкету и&nbsp;станьте участником программы экспортной
            акселерации
          </div>
          <div>
            <Link to="/survey">
              <BlueButton>Начать</BlueButton>
            </Link>
          </div>
        </div>
      </div>

      <div className={css.whatis}>
        <div className={css.whatiscontent}>
          <h2 className={css.title}>Экспортный акселератор</h2>
          <p className={css.whatistext}>
            Экспортный акселератор&nbsp;&mdash; это сервис Российского
            экспортного центра, позволяющий российским компаниям нарастить
            экспортные компетенции и реализовать свои внешнеторговые проекты за
            счет индивидуально подобранного комплекса мер нефинансовой и
            финансовой поддержки, а также персонального клиентского
            сопровождения.
          </p>
        </div>
      </div>
    </>
  );
};

export default Hello;
