import { LinkBlueButton } from "ui/atoms/Button";
import React from "react";
import TemplateInnerPage from "../../ui/templates/TemplateInnerPage";
import css from "./index.module.css";

const Page404 = () => {
  return (
    <TemplateInnerPage>
      <div className={css.page}>
        <div className={css.num}>404</div>
        <div className={css.content}>
          <div className={css.suptitle}>ОШИБКА 404</div>
          <div className={css.title}>Страница не найдена</div>
          <div className={css.description}>
            Неправильно набран адрес или такой <br />
            страницы не существует
          </div>
          <LinkBlueButton to="/">Перейти на главную</LinkBlueButton>
        </div>
      </div>
    </TemplateInnerPage>
  );
};

export default Page404;
