import { BlueButton } from "ui/atoms/Button";
import { useUidmSSO } from "uidm-react-lib";
import Header from "ui/organisms/Header";
import React, { FC } from "react";
import css from "./index.module.css";
import './menu.css';
import withProperties from "lib/with-properties";

const OuterContainer: FC = (props) => {
  return <div className={css.outerContainer} {...props}></div>;
};

const LoginOrRegisterButton = () => {
  const [sso] = useUidmSSO();
  return (
    <div className={css.loginOrRegisterButtonContainer}>
      <BlueButton onClick={() => sso.login()}>
        Зарегистрироваться / Войти
      </BlueButton>
    </div>
  );
};

const Template: FC = ({ children }) => (
  <OuterContainer>
    <Header />
    <main className={css.main}>{children}</main>
    {/* <Notifications /> */}
  </OuterContainer>
);

export default withProperties(Template, { LoginOrRegisterButton });
