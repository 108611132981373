import { History } from "history";
import { Persistor } from "redux-persist";
import { SagaIterator } from "redux-saga";
import { all, fork } from "redux-saga/effects";
import { saga as coreSaga } from "../features/core";
import { saga as profileSaga } from "../features/profile";
import { saga as roadmapSaga } from "../features/roadmap";

export default function (persistor: Persistor, history: History<any>) {
  return function* (): SagaIterator {
    return yield all([
      fork(coreSaga, persistor, history),
      fork(profileSaga, history),
      fork(roadmapSaga, history),
    ]);
  };
}
