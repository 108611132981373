import { ActionTypes } from "./actions";
import { GenericAction } from "../../../store/actions";
import {
  RemoteData,
  failure,
  initialized,
  pending,
  success,
} from "../../../lib/remote";
import { RestApiErrorErrorCodeEnum, RoadMapDto } from "api";
import { combineReducers } from "redux";

type RoadMapState = RemoteData<RoadMapDto, RestApiErrorErrorCodeEnum>;

function roadmap(
  state: RoadMapState = initialized(),
  action: GenericAction
): RoadMapState {
  switch (action.type) {
    case ActionTypes.LOAD_ROADMAP:
    case ActionTypes.REFRESH_ROADMAP:
      return pending(state);
    case ActionTypes.LOAD_ROADMAP_SUCCESS:
      return success(action.roadmap);
    case ActionTypes.LOAD_ROADMAP_FAILURE:
      return failure(action.error);
    default:
      return state;
  }
}

type StartServiceNotificationState = RemoteData<
  true,
  RestApiErrorErrorCodeEnum
>;

function startServiceNotification(
  state: StartServiceNotificationState = initialized(),
  action: GenericAction
): StartServiceNotificationState {
  switch (action.type) {
    case ActionTypes.START_SERVICE:
      return pending();
    case ActionTypes.START_SERVICE_SUCCESS:
      return success(true);
    case ActionTypes.START_SERVICE_FAILURE:
      return failure(action.error);
    case ActionTypes.START_SERVICE_NOTIFICATION_HIDDEN:
      return initialized();
    default:
      return state;
  }
}

type ApproveServiceNotificationState = RemoteData<true>;

function approveServiceNotification(
  state: ApproveServiceNotificationState = initialized(),
  action: GenericAction
): ApproveServiceNotificationState {
  switch (action.type) {
    case ActionTypes.APPROVE_SERVICE:
      return pending();
    case ActionTypes.APPROVE_SERVICE_SUCCESS:
      return success(true);
    case ActionTypes.APPROVE_SERVICE_NOTIFICATION_HIDDEN:
      return initialized();
    default:
      return state;
  }
}

type ApproveRoadmapNotificationState = boolean;

function approveRoadmapNotification(
  state: ApproveRoadmapNotificationState = false,
  action: GenericAction
): ApproveRoadmapNotificationState {
  switch (action.type) {
    case ActionTypes.SHOW_APPROVE_ROADMAP_NOTIFICATION:
      return true;
    case ActionTypes.APPROVE_ROADMAP_NOTIFICATION_HIDDEN:
      return false;
    default:
      return state;
  }
}

export default combineReducers({
  roadmap,
  startServiceNotification,
  approveServiceNotification,
  approveRoadmapNotification,
});
