import { ErrorInfo } from "react";
import { NotificationSeverity } from "./types";
import {
  ProcessStatusResponseDtoStatusEnum,
  RestApiErrorErrorCodeEnum,
} from "api";
import { Token } from "api/token.types";
import i18n from "../../../lib/i18n";
import uuid4 from "uuid4";

export const ActionTypes = {
  LOGIN_SUCCESSFUL: "CORE/LOGIN_SUCCESSFUL" as const,
  LOGOUT: "COMMON/CORE/LOGOUT" as const,

  LOAD_GLOBAL_STATUS: "CORE/LOAD_GLOBAL_STATUS" as const,
  LOAD_GLOBAL_STATUS_SUCCESS: "CORE/LOAD_GLOBAL_STATUS_SUCCESS" as const,
  LOAD_GLOBAL_STATUS_FAILURE: "CORE/LOAD_GLOBAL_STATUS_FAILURE" as const,

  SHOW_NOTIFICATION: "CORE/SHOW_NOTIFICATION" as const,
  NOTIFICATION_EXPIRED: "CORE/NOTIFICATION_EXPIRED" as const,
  NOTIFICATION_CLOSED: "CORE/NOTIFICATION_CLOSED" as const,
};

export const loginSuccessful = (token: Token) => ({
  type: ActionTypes.LOGIN_SUCCESSFUL,
  token,
});
export const logout = () => ({ type: ActionTypes.LOGOUT });

export const loadGlobalStatus = () => ({
  type: ActionTypes.LOAD_GLOBAL_STATUS,
});
export const loadGlobalStatusSuccess = (
  status: ProcessStatusResponseDtoStatusEnum
) => ({
  type: ActionTypes.LOAD_GLOBAL_STATUS_SUCCESS,
  status,
});
export const loadGlobalStatusFailure = (error: RestApiErrorErrorCodeEnum) => ({
  type: ActionTypes.LOAD_GLOBAL_STATUS_FAILURE,
  error,
});

export const showNotification = (
  severity: NotificationSeverity,
  message: string
) => ({
  type: ActionTypes.SHOW_NOTIFICATION,
  severity,
  timestamp: new Date(),
  id: uuid4(),
  message,
});
export const notificationExpired = (id: string) => ({
  type: ActionTypes.NOTIFICATION_EXPIRED,
  id,
});

export const notificationClosed = (id: string) => ({
  type: ActionTypes.NOTIFICATION_CLOSED,
  id,
});

export const showError = (error: Error, errorInfo?: ErrorInfo) => {
  // eslint-disable-next-line no-console
  console.error(error, errorInfo);
  return showNotification(
    NotificationSeverity.ERROR,
    error.message || "Внутренняя ошибка"
  );
};
export const showRestError = (error: RestApiErrorErrorCodeEnum) => {
  return showNotification(
    NotificationSeverity.ERROR,
    i18n.t(`errors.${error}`)
  );
};

export const showInternalErrorNotification = (error: Error) => {
  // eslint-disable-next-line no-console
  console.error(error);
  return showNotification(
    NotificationSeverity.ERROR,
    `Произошла ошибка ${error.message}`
  );
};
