import React from "react";
import css from "./howit.module.css";

type Content = {
  title: React.ReactNode;
  description: React.ReactNode;
};

const ContentData: Content[] = [
  {
    title: <>Зарегистрируйтесь</>,
    description: (
      <>
        Зарегистрируйте личный кабинет на портале «Мой&nbsp;экспорт»&nbsp;и
        перейдите в сервис «Экспортный акселератор»
      </>
    ),
  },
  {
    title: <>Пройдите анкетирование</>,
    description: (
      <>
        Ответы на вопросы Анкеты на следующем шаге будут использоваться в
        качестве базы для формирования индивидуальной дорожной карты
      </>
    ),
  },
  {
    title: <>Заполните профиль</>,
    description: (
      <>
        Заполните профиль пользователя информацией, необходимой для определения
        соответствующих мер поддержки
      </>
    ),
  },
  {
    title: <>Подпишите меморандум</>,
    description: (
      <>
        Меморандум документально подтверждает ваше участие в программе
        Акселерации. После подписания Меморандума вашей компании будет предложен
        индивидуальный план акселерации / развития экспортной деятельности
      </>
    ),
  },
  {
    title: <>Получите дорожную карту</>,
    description: (
      <>
        Вам будет предложен индивидуальный план продвижения продукции на
        экспорт, который в последующем может быть дополнен или скорректирован в
        ходе взаимодействия с клиентским менеджером
      </>
    ),
  },
  {
    title: <>Приступайте к исполнению плана</>,
    description: (
      <>
        Шаг за шагом пройдите весь экспортный цикл до заключения контракта с
        зарубежными партнерами. Получайте необходимые консультации специалистов,
        меры поддержки РЭЦ и аккредитованных партнеров
      </>
    ),
  },
];
// &nbsp;
const Howit = () => {
  return (
    <div className={css.howitwrap}>
      <div className={css.howit}>
        <h2 className={css.title}>Как это работает?</h2>

        <div className={css.blocks}>
          {ContentData.map(({ title, description }, index) => (
            <div key={index} className={css.item} data-num={`0${index + 1}`}>
              <h3 className={css.itemTitle}>{title}</h3>
              <p className={css.itemDesc}>{description}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Howit;
