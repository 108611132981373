// eslint-disable-next-line import/prefer-default-export
import { StoreState } from "store/rootReducer";
import { maybeData, remoteMap } from "lib/remote";

export const selectRoadmap = (state: StoreState) => state.roadmap.roadmap;

export const selectShowNotificationStartService = (state: StoreState) =>
  remoteMap(
    (redirectUrl) => redirectUrl ?? window._env.REACT_APP_ELK_SERVICE_LIST_URL,
    state.roadmap.startServiceNotification
  );

export const selectShowNotificationApproveService = (
  state: StoreState
): boolean =>
  maybeData((a) => a, false, state.roadmap.approveServiceNotification);

export const selectShowNotificationApproveRoadmap = (
  state: StoreState
): boolean => state.roadmap.approveRoadmapNotification;
