import React from "react";
import css from "./whom.module.css";

const Whom = () => {
  return (
    <div className={css.whomwrap}>
      <div className={css.whom}>
        <h2 className={css.title}>
          Думаете, стоит ли принимать участие?
          <br />
          Программа отлично подходит следующим категориям компаний
        </h2>
        <div className={css.blocks}>
          <div className={css.big}>
            <div className={css.subtitle}>Новичкам</div>
            <div className={css.text}>
              Компании, ранее не&nbsp;осуществлявшие экспорт, но&nbsp;обладающие
              экспортным потенциалом, или осуществляющие поставки
              на&nbsp;экспорт через посредников
            </div>
          </div>
          <div className={css.small}>
            <div className={css.subtitle}>Неопытным</div>
            <div className={css.text}>
              Компании, осуществляющие разовые или нерегулярные (сезонные /
              конъюнктурные) поставки на&nbsp;экспорт
            </div>
          </div>
          <div className={css.small}>
            <div className={css.subtitle}>Развивающимся</div>
            <div className={css.text}>
              Компании, осуществляющие регулярные поставки, но&nbsp;планирующие
              расширить географию и&nbsp;товарную номенклатуру
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Whom;
