import { Container } from "ui/atoms/Container";
import React, { FC } from "react";
import Template from "ui/molecules/Template";

const TemplateInnerPage: FC = ({ children }) => (
  <Template>
    <Container>{children}</Container>
  </Template>
);

export default TemplateInnerPage;
