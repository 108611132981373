import React from "react";
import cn from "classnames";
import css from "./whatinclude-accordion.module.css";

type ContentItem = {
  title: React.ReactNode;
  description: React.ReactNode;
};
const WhatIncludeAccordion = ({
  content,
  visible,
}: {
  content: ContentItem[];
  visible: boolean;
}) => {
  const [active, setActive] = React.useState(0);

  if (!visible) {
    // рендерим только активный, сохраняя текущую позицию открытого пункта
    return null;
  }

  return (
    <div className={css.accordion}>
      {content.map((row, index) => {
        return (
          <div
            key={index}
            className={cn(css.row, {
              [css.active]: active === index && !!row.description,
            })}
          >
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/interactive-supports-focus */}
            <div
              className={cn(css.title, {
                [css.title__cursor]: !!row.description,
              })}
              data-index={index + 1}
              onClick={() => !!row.description && setActive(index)}
              role="button"
            >
              {row.title}
            </div>
            {!!row.description && (
              <div className={css.description}>{row.description}</div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default React.memo(WhatIncludeAccordion);
