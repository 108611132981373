import { DeepReadonly } from "ts-essentials";
import { PrefilledProfileDto, RestApiErrorErrorCodeEnum } from "api";
import { Profile, ProfileStatus } from "./reducer";

export const ActionTypes = {
  LOAD_CURRENT_PROFILE: "PROFILE/LOAD_CURRENT_PROFILE" as const,
  LOAD_CURRENT_PROFILE_SUCCESS: "PROFILE/LOAD_CURRENT_PROFILE_SUCCESS" as const,
  LOAD_CURRENT_PROFILE_FAILURE: "PROFILE/LOAD_CURRENT_PROFILE_FAILURE" as const,

  BIND_PROFILE: "PROFILE/BIND_PROFILE" as const,

  UPDATE_PROFILE: "PROFILE/UPDATE_PROFILE" as const,
  SAVE_PROFILE: "PROFILE/SAVE_PROFILE" as const,
  SAVE_PROFILE_SUCCESS: "PROFILE/SAVE_PROFILE_SUCCESS" as const,
  SAVE_PROFILE_FAILURE: "PROFILE/SAVE_PROFILE_FAILURE" as const,

  LOAD_MEMORANDUM_STATUS: "PROFILE/LOAD_MEMORANDUM_STATUS" as const,
  LOAD_MEMORANDUM_STATUS_SUCCESS: "PROFILE/LOAD_MEMORANDUM_STATUS_SUCCESS" as const,
  LOAD_MEMORANDUM_STATUS_FAILURE: "PROFILE/LOAD_MEMORANDUM_STATUS_FAILURE" as const,
};

export const loadCurrentProfile = () => ({
  type: ActionTypes.LOAD_CURRENT_PROFILE,
});

export const loadCurrentProfileSuccess = (
  profile: PrefilledProfileDto,
  status: ProfileStatus
) => ({
  type: ActionTypes.LOAD_CURRENT_PROFILE_SUCCESS,
  profile,
  status,
});

export const loadCurrentProfileFailure = (
  error: RestApiErrorErrorCodeEnum
) => ({
  type: ActionTypes.LOAD_CURRENT_PROFILE_FAILURE,
  error,
});

export const bindProfile = () => ({ type: ActionTypes.BIND_PROFILE });

export const updateProfile = (profile: Profile) => ({
  type: ActionTypes.UPDATE_PROFILE,
  profile,
});

export const saveProfile = (
  profile: DeepReadonly<Profile & { presentation: File | undefined }>
) => ({
  type: ActionTypes.SAVE_PROFILE,
  profile,
  meta: { thunk: true },
});

export const saveProfileSuccess = (meta: any) => ({
  type: ActionTypes.SAVE_PROFILE_SUCCESS,
  meta,
});

export const saveProfileFailure = (
  error: RestApiErrorErrorCodeEnum,
  meta: any
) => ({
  type: ActionTypes.SAVE_PROFILE_FAILURE,
  payload: error,
  error: true,
  meta: { ...meta, dontIntercept: true },
});

export const loadMemorandumStatus = () => ({
  type: ActionTypes.LOAD_MEMORANDUM_STATUS,
});

export const loadMemorandumStatusSuccess = (signed: boolean) => ({
  type: ActionTypes.LOAD_MEMORANDUM_STATUS_SUCCESS,
  signed,
});

export const loadMemorandumStatusFailure = (error: string | Error) => ({
  type: ActionTypes.LOAD_MEMORANDUM_STATUS_FAILURE,
  error,
});
