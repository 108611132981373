import { Route, Switch } from "react-router";
import { apiGetTokenCookie } from "api";
import { loginSuccessful, logout } from "features/core/redux/actions";
import { selectIsLoggedIn } from "features/core/redux/selectors";
import { useDispatch, useSelector } from "react-redux";
import { useUidmSSO } from "uidm-react-lib";
import Page404 from "features/page404";
import PromoPage from "../../../promo/pages";
import React, { Suspense, lazy, useEffect } from "react";
import css from "./app.module.css";

const LazyMemorandumPage = lazy(() =>
  import(
    /* webpackChunkName: "memorandum", webpackPrefetch: true */ "features/memorandum/pages/Memorandum"
  )
);

const LazyProfilePage = lazy(() =>
  import(
    /* webpackChunkName: "profile", webpackPrefetch: true */ "features/profile/pages/index"
  )
);

const LazyRoadMapPage = lazy(() =>
  import(
    /* webpackChunkName: "roadmap", webpackPrefetch: true */ "features/roadmap/pages/Index"
  )
);
const LazySurveyPage = lazy(() =>
  import(
    /* webpackChunkName: "survey", webpackPrefetch: true */ "features/survey/pages/Survey"
  )
);

function useUpdateLoginStatus() {
  const [sso] = useUidmSSO();
  const dispatch = useDispatch();
  const ourCookie = apiGetTokenCookie();
  const isLoggedIn = useSelector(selectIsLoggedIn);

  useEffect(() => {
    if (sso.logged) {
      dispatch(loginSuccessful(sso.userData));
    }
    if (!sso.logged && isLoggedIn) {
      dispatch(logout());
    }
  }, [dispatch, ourCookie, sso.logged, sso.userData, isLoggedIn]);

  // useEffect(() => {
  //   if (sso.logged && ourCookie) {
  //     // Условие здесь потому что нужно отслеживать изменени глобального статуса, в саге это сделать сложнее.
  //     if (
  //       hasData(globalStatus) &&
  //       (globalStatus.data ===
  //         ProcessStatusResponseDtoStatusEnum.SURVEYINPROGRESS ||
  //         globalStatus.data === ProcessStatusResponseDtoStatusEnum.SURVEYOPEN)
  //     ) {
  //       dispatch(bindProfile());
  //     }
  //   }
  // }, [dispatch, globalStatus, ourCookie, sso.logged]);
}

const App: React.FC = () => {
  useUpdateLoginStatus();

  return (
    <div className={css.app}>
      <Suspense fallback={null}>
        <Switch>
          <Route component={PromoPage} exact path="/" />
          <Route component={LazyMemorandumPage} path="/memorandum" />
          <Route component={LazySurveyPage} path="/survey" />
          <Route component={LazyProfilePage} path="/profile" />
          <Route component={LazyRoadMapPage} path="/roadmap" />
          <Route component={Page404} />
        </Switch>
      </Suspense>
    </div>
  );
};

export default App;
