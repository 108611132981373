import React, { FC } from "react";
import cn from "classnames";
import css from "./index.module.css";

type ContainerType = React.ComponentProps<"div">;

export const Container: FC<ContainerType> = ({ className = "", ...rest }) => (
  <div className={cn(css.container, className)} {...rest} />
);

export const ContainerFlex: FC<ContainerType> = ({
  className = "",
  ...rest
}) => <div className={cn(css.container, css.flex, className)} {...rest} />;
