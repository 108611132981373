import {
  ActionTypes,
  loadCurrentProfile,
  loadCurrentProfileFailure,
  loadCurrentProfileSuccess,
  loadMemorandumStatusFailure,
  loadMemorandumStatusSuccess,
  saveProfile,
  saveProfileFailure,
  saveProfileSuccess,
} from "./actions";
import { AxiosResponse } from "axios";
import {
  ClientFilledProfileWithPresentationRequest,
  MemorandumControllerV1,
  MemorandumStatusResponseDto,
  PrefilledProfileDto,
  ProfileClientListAnswerDto,
  ProfileControllerV1,
  ProfileStatusDto,
} from "api";
import { DeepReadonly, DeepWritable } from "ts-essentials";
import { History } from "history";
import { ProfileStatus } from "./reducer";
import { all, call, put, select, takeEvery } from "redux-saga/effects";
import { hasData } from "lib/remote";
import { oc } from "ts-optchain";
import { parseStringList } from "lib/string-lists";
import { selectIsLoggedIn } from "features/core/redux/selectors";
import { selectProfile } from "./selectors";
import { toBase64 } from "lib/file-upload";
import { toErrorCode } from "lib/error-codes";

export default function* profileSaga(_history: History<unknown>) {
  yield all([
    takeEvery(ActionTypes.LOAD_CURRENT_PROFILE, function* () {
      try {
        const profileRes: AxiosResponse<PrefilledProfileDto> = yield call({
          context: ProfileControllerV1,
          fn: ProfileControllerV1.getCurrentOrgProfileUsingGET,
        });

        try {
          const profileStatusRes: AxiosResponse<ProfileStatusDto> = yield call({
            context: ProfileControllerV1,
            fn: ProfileControllerV1.getCurrentProfileStatusUsingGET,
          });
          yield put(
            loadCurrentProfileSuccess(
              profileRes.data,
              profileStatusRes.data.declined
                ? ProfileStatus.DECLINED
                : profileStatusRes.data.verified
                ? ProfileStatus.VERIFIED
                : ProfileStatus.WAITING
            )
          );
        } catch (e) {
          yield put(
            loadCurrentProfileSuccess(
              profileRes.data,
              ProfileStatus.NOT_CREATED
            )
          );
        }
        try {
          const memorandumStatus: AxiosResponse<MemorandumStatusResponseDto> = yield call(
            {
              context: MemorandumControllerV1,
              fn: MemorandumControllerV1.getOwnMemorandumStatusUsingGET,
            }
          );
          yield put(
            loadMemorandumStatusSuccess(
              memorandumStatus.data.signedByClient || false
            )
          );
        } catch (e) {
          yield put(loadMemorandumStatusFailure(toErrorCode(e)));
        }
      } catch (e) {
        if (
          oc(e).response.status() !== 401 &&
          oc(e).response.status() !== 403
        ) {
          yield put(loadCurrentProfileFailure(toErrorCode(e)));
        } else {
          const isLoggedIn: ReturnType<typeof selectIsLoggedIn> = yield select(
            selectIsLoggedIn
          );
          if (!isLoggedIn) {
            yield put(loadCurrentProfileFailure(toErrorCode(e)));
          }
        }
      }
    }),
    takeEvery(ActionTypes.BIND_PROFILE, function* () {
      try {
        yield call({
          context: ProfileControllerV1,
          fn: ProfileControllerV1.bindClientUsingPOST,
        });
      } catch (e) {}
    }),
    takeEvery(ActionTypes.SAVE_PROFILE, function* (
      action: ReturnType<typeof saveProfile>
    ) {
      try {
        const {
          clientDropdownLists,
          presentation,
          mainBrands,
          mainProducts,
          expProducts,
          ...values
        } = action.profile;
        const profileData: ReturnType<typeof selectProfile> = yield select(
          selectProfile
        );
        if (hasData(profileData)) {
          const dropDownLists: ProfileClientListAnswerDto[] = Object.keys(
            clientDropdownLists
          ).map((dropdownAlias) => {
            const dropdownValue = clientDropdownLists[dropdownAlias];
            const questionData =
              profileData.data.serverDropdowns[dropdownAlias];

            return {
              questionId: questionData.questionId,
              answerId: Number(dropdownValue.value!.id),
              note: clientDropdownLists[dropdownAlias].note,
            };
          });

          const {
            presentation: presentationFile,
            ...restProfileFields
          } = profileData.data.data;

          const hasPresentation = profileData.data.data.presentation != null;
          let presentation: string = yield hasPresentation
            ? toBase64(profileData.data.data.presentation!)
            : undefined;
          if (presentation) {
            // Обрезаем всё, кроме base64
            const idx = presentation.indexOf("base64,");
            if (idx > -1) {
              presentation = presentation.substring(idx + "base64,".length);
            }
          }
          if (values.phone != null) {
            values.phone = values.phone.replace(/[^+\d]/g, "");
          }
          if (values.contactPhone != null) {
            values.contactPhone = values.contactPhone.replace(/[^+\d]/g, "");
          }

          const result: DeepReadonly<ClientFilledProfileWithPresentationRequest> =
            // в action приходит readonly вариант, поэтому все данные копируем
            {
              ...restProfileFields,
              ...values,
              // workaround ошибки в final-form, при использовании formatOnBlur parse={} не всегда применяется
              mainBrands: parseStringList(mainBrands ?? []),
              mainProducts: parseStringList(mainProducts ?? []),
              expProducts: parseStringList(expProducts ?? []),
              dropDownLists,
              presentationDto: hasPresentation
                ? {
                    presentation,
                    fileName: profileData.data.data.presentation!.name,
                  }
                : undefined,
            };

          const operations = [
            call(
              {
                context: ProfileControllerV1,
                fn: ProfileControllerV1.createProfileUsingPOST,
              },
              result as DeepWritable<ClientFilledProfileWithPresentationRequest>
            ),
          ];

          yield all(operations);
          yield put(saveProfileSuccess(action.meta));

          yield put(loadCurrentProfile());
        }
      } catch (e) {
        yield put(saveProfileFailure(toErrorCode(e), action.meta));
      }
    }),
  ]);
}
