import React, { ComponentProps } from "react";
import cn from "classnames";
import css from "./bluebutton.module.css";

const BlueButton = ({ className, ...rest }: ComponentProps<"button">) => {
  return (
    <button className={cn(css.button, className)} type="button" {...rest} />
  );
};

export default BlueButton;
