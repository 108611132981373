/* eslint-disable import/prefer-default-export */
export type EntitiesState = {
  // profile_countries: {
  //   [key: string]: CountryNormalized;
  // };
  // survey_groups: {
  //   [key: string]: SurveyGroupNormalized;
  // };
  // survey_questions: {
  //   [key: string]: SurveyQuestionNormalized;
  // };
  // survey_answers: {
  //   [key: string]: SurveyAnswerNormalized;
  // };
};
export const ENTITIES_INITIAL_STATE: EntitiesState = {
  // profile_countries: {}
  // survey_groups: {},
  // survey_questions: {},
  // survey_answers: {}
};

// export interface CountryNormalized extends CountryDto {}
// export interface SurveyGroupDenormalized extends CountryDto {}

// export interface SurveyGroupNormalized
//   extends Omit<SurveyGroupDto, "surveyQuestions"> {
//   surveyQuestions: string[];
// }
// export interface SurveyGroupDenormalized
//   extends Omit<SurveyGroupDto, "surveyQuestions"> {
//   surveyQuestions: SurveyQuestionDenormalized[];
// }

// export const countrySchema = new schema.Entity(
//   "profile_countries",
//   {},
//   {
//     idAttribute: "code"
//   }
// );

// export const countriesSchema = new schema.Array(countrySchema);

// export const normalizeCountries = (
//   groups: CountryDto[]
// ): NormalizedSchema<any, string[]> =>
//   normalize<CountryDto>(groups, countriesSchema);

// export const denormalizeCountries = (
//   list: DeepReadonly<string[] | number[]>,
//   entities: FullEntitiesState
// ): SurveyGroupDenormalized[] => denormalize(list, countriesSchema, entities);
