import { ActionTypes } from "./actions";
import { DeepReadonly } from "ts-essentials";
import { GenericAction } from "../../../store/actions";
import { NotificationSeverity } from "./types";
import {
  ProcessStatusResponseDtoStatusEnum,
  RestApiErrorErrorCodeEnum,
} from "api";
import { RemoteData, failure, initialized, pending, success } from "lib/remote";
import { Token } from "api/token.types";
import { combineReducers } from "redux";

type AuthState = DeepReadonly<{
  token: Token | null;
}>;

function auth(
  state: AuthState = { token: null },
  action: GenericAction
): AuthState {
  switch (action.type) {
    case ActionTypes.LOGIN_SUCCESSFUL:
      return { token: action.token };
    default:
      return state;
  }
}

type GlobalStatusState = RemoteData<
  ProcessStatusResponseDtoStatusEnum,
  RestApiErrorErrorCodeEnum
>;

function globalStatus(
  state: GlobalStatusState = initialized(),
  action: GenericAction
): GlobalStatusState {
  switch (action.type) {
    case ActionTypes.LOAD_GLOBAL_STATUS:
      return pending();
    case ActionTypes.LOAD_GLOBAL_STATUS_SUCCESS:
      return success(action.status);
    case ActionTypes.LOAD_GLOBAL_STATUS_FAILURE:
      return failure(action.error);
    default:
      return state;
  }
}

type NotificationState = DeepReadonly<
  Array<{
    message: string;
    severity: NotificationSeverity;
    timestamp: Date;
    id: string;
  }>
>;

const initialNotificationState: NotificationState = [];

function notifications(
  state: NotificationState = initialNotificationState,
  action: GenericAction
): NotificationState {
  switch (action.type) {
    case ActionTypes.SHOW_NOTIFICATION:
      return [
        ...state,
        {
          message: action.message,
          severity: action.severity,
          id: action.id,
          timestamp: action.timestamp,
        },
      ];
    case ActionTypes.NOTIFICATION_CLOSED:
    case ActionTypes.NOTIFICATION_EXPIRED:
      return state.filter((n) => n.id !== action.id);
    default:
      return state;
  }
}

export default combineReducers({ auth, globalStatus, notifications });
