import { initReactI18next } from "react-i18next";
import Backend from "i18next-xhr-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import i18n from "i18next";

if (process.env.NODE_ENV !== "test") {
  i18n
    // load translation using xhr -> see /public/locales
    // learn more: https://github.com/i18next/i18next-xhr-backend
    .use(Backend);
}
// detect user language
// learn more: https://github.com/i18next/i18next-browser-languageDetector
i18n
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    fallbackLng: "ru",
    debug: process.env.NODE_ENV === "development",

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    react: {
      useSuspense: process.env.NODE_ENV !== "test",
      wait: true,
    },
  });
if (process.env.NODE_ENV === "test") {
  i18n.changeLanguage("cimode");
}
export default i18n;
