import {
  ActionTypes,
  loadGlobalStatus,
  loadGlobalStatusFailure,
  loadGlobalStatusSuccess,
  loginSuccessful,
  showInternalErrorNotification,
} from "./actions";
import { AxiosResponse } from "axios";
import { GenericAction } from "store/actions";
import { History } from "history";
import { Persistor } from "redux-persist";
import {
  ProcessStatusControllerV1,
  ProcessStatusResponseDto,
  ProcessStatusResponseDtoStatusEnum,
} from "api";
import { UnreachableCaseError } from "ts-essentials";
import {
  all,
  call,
  delay,
  fork,
  put,
  race,
  select,
  take,
  takeEvery,
} from "redux-saga/effects";
import { bindProfile } from "features/profile/redux/actions";
import { hasData } from "lib/remote";
import { selectGlobalStatus, selectIsLoggedIn } from "./selectors";
import Cookies from "js-cookie";

export default function* coreSaga(
  persistor: Persistor,
  history: History<unknown>
) {
  yield all([
    fork(authSaga, persistor, history),
    fork(errorHandlingSaga),
    fork(onAppInitSaga, history),
  ]);
}

function* errorHandlingSaga() {
  yield all([
    takeEvery("*", function* (action: GenericAction) {
      if (
        action.type.includes("_FAILURE") &&
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        !(action as any)?.meta?.dontIntercept
      ) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const error = (action as any).error;
        if (error != null && error instanceof Error) {
          yield put(showInternalErrorNotification(error));
        }
      }
    }),
  ]);
}

function* authSaga(persistor: Persistor, history: History<unknown>) {
  yield all([
    takeEvery(ActionTypes.LOGIN_SUCCESSFUL, function* (
      action: ReturnType<typeof loginSuccessful>
    ) {
      yield loadGlobalStatusSaga();
      yield put(bindProfile());
      yield redirectAccordingToStatusSaga(history);
    }),
    takeEvery(ActionTypes.LOGOUT, function* () {
      // Удаляем старый lead_id
      Cookies.remove("lead_id", { path: "/api/v1/" });
      persistor.purge();
      history.replace("/");
      yield;
    }),
  ]);
}

function* onAppInitSaga(history: History<unknown>) {
  try {
    yield take("persist/REHYDRATE");
    yield loadGlobalStatusSaga();
    yield redirectAccordingToStatusSaga(history);
  } catch (err) {}
}

function* loadGlobalStatusSaga() {
  try {
    const isLoggedIn: ReturnType<typeof selectIsLoggedIn> = yield select(
      selectIsLoggedIn
    );

    if (isLoggedIn) {
      yield put(loadGlobalStatus());
      const statusRes: AxiosResponse<ProcessStatusResponseDto> = yield call({
        context: ProcessStatusControllerV1,
        // eslint-disable-next-line @typescript-eslint/unbound-method
        fn: ProcessStatusControllerV1.getProcessStatusUsingGET,
      });
      yield put(loadGlobalStatusSuccess(statusRes.data.status));
    }
  } catch (error) {
    yield put(loadGlobalStatusFailure(error));
  }
}

function* redirectAccordingToStatusSaga(history: History<unknown>) {
  try {
    const globalStatus: ReturnType<typeof selectGlobalStatus> = yield select(
      selectGlobalStatus
    );
    while (!hasData(globalStatus)) {
      yield race([delay(1000), take(ActionTypes.LOAD_GLOBAL_STATUS_SUCCESS)]);
    }

    switch (globalStatus.data) {
      case ProcessStatusResponseDtoStatusEnum.SURVEYOPEN:
        // ничего не делаем
        break;
      case ProcessStatusResponseDtoStatusEnum.SURVEYINPROGRESS:
      case ProcessStatusResponseDtoStatusEnum.SURVEYFAILED: {
        history.replace("/survey");
        break;
      }
      case ProcessStatusResponseDtoStatusEnum.PROFILEOPEN:
        break;
      case ProcessStatusResponseDtoStatusEnum.PROFILEDECLINED:
      case ProcessStatusResponseDtoStatusEnum.PROFILEONVERIFY:
      case ProcessStatusResponseDtoStatusEnum.MEMORANDUMOPEN:
        history.replace("/profile");
        break;
      case ProcessStatusResponseDtoStatusEnum.ROADMAPINPROGRESS:
        history.replace("/roadmap");
        break;
      default:
        throw new UnreachableCaseError(globalStatus.data);
    }
  } catch (e) {
    // TODO обработать
  }
}
