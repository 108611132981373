import { ActionTypes } from "./actions";
import {
  ClientFilledProfileWithPresentationRequest,
  CountryDto,
  ProfileMetaListAnswerDto,
  ProfileMetaListQuestionDto,
} from "api";
import { ActionTypes as CoreActionTypes } from "features/core/redux/actions";
import { DeepReadonly } from "ts-essentials";
import { GenericAction } from "store/actions";
import {
  RemoteData,
  failure,
  initialized,
  pending,
  remoteMap,
  success,
} from "lib/remote";
import { combineReducers } from "redux";

export enum ProfileStatus {
  NOT_CREATED = "NOT_CREATED",
  WAITING = "WAITING",
  DECLINED = "DECLINED",
  VERIFIED = "VERIFIED",
  FAILED_TO_SAVE = "FAILED_TO_SAVE",
}

export interface ServerDropDownNormalized {
  [questionLabel: string]: {
    questionId: number;
    question: string;
    answers: DeepReadonly<ProfileMetaListAnswerDto[]>;
  };
}

interface SelectedValue {
  id: number;
  answer: string;
  showNote: boolean;
  note?: string;
}
/**
 * dropdown'ы с сервера приходят в довольно своеобразном формате, преобразовываем их чтобы было легче работать из формы
 * У Dropdown'а есть label (это английское название поля :) ), его используем как ключ в Map'е
 */
export type Profile = DeepReadonly<
  Omit<
    ClientFilledProfileWithPresentationRequest,
    "dropDownLists" | "countries"
  > & {
    countries: CountryDto[];
    clientDropdownLists: {
      [questionLabel: string]: {
        value: SelectedValue | null;
        note: string | undefined;
      };
    };

    presentation: File | undefined;
  }
>;

type ProfileState = RemoteData<
  DeepReadonly<{
    data: Profile;
    serverDropdowns: ServerDropDownNormalized;
    saving: boolean;
    status: ProfileStatus;
  }>
>;
function profile(
  state: ProfileState = initialized(),
  action: GenericAction
): ProfileState {
  switch (action.type) {
    case ActionTypes.LOAD_CURRENT_PROFILE:
    case CoreActionTypes.LOGIN_SUCCESSFUL:
      return pending(state);
    case ActionTypes.LOAD_CURRENT_PROFILE_FAILURE:
      return failure(action.error.toString());
    case ActionTypes.SAVE_PROFILE:
      return remoteMap((state) => ({ ...state, saving: true }), state);
    case ActionTypes.SAVE_PROFILE_SUCCESS:
      return remoteMap((state) => ({ ...state, saving: false }), state);
    case ActionTypes.SAVE_PROFILE_FAILURE:
      return remoteMap(
        (state) => ({
          ...state,
          status: ProfileStatus.FAILED_TO_SAVE,
          saving: false,
        }),
        state
      );
    case ActionTypes.LOAD_CURRENT_PROFILE_SUCCESS: {
      const { dropDownLists, ...rest } = action.profile;
      return success({
        data: {
          ...rest,
          clientDropdownLists: {},
          clientDropdownListNotes: {},
          countries: [],
          presentation: undefined,
        },
        serverDropdowns: normalizeDropdowns(dropDownLists),
        status: action.status,
        saving: false,
      });
    }
    case ActionTypes.UPDATE_PROFILE:
      return remoteMap((state) => ({ ...state, data: action.profile }), state);
    default:
      return state;
  }
}

function memorandumSigned(
  state: RemoteData<boolean> = initialized(),
  action: GenericAction
): RemoteData<boolean> {
  switch (action.type) {
    case ActionTypes.LOAD_MEMORANDUM_STATUS:
      return pending();
    case ActionTypes.LOAD_MEMORANDUM_STATUS_SUCCESS:
      return success(action.signed);
    case ActionTypes.LOAD_MEMORANDUM_STATUS_FAILURE:
      return failure(action.error.toString());
    default:
      return state;
  }
}

const normalizeDropdowns = (dropdowns: ProfileMetaListQuestionDto[]) => {
  const normalized: {
    [questionLabel: string]: {
      questionId: number;
      question: string;
      answers: DeepReadonly<ProfileMetaListAnswerDto[]>;
    };
  } = {};
  for (const q of dropdowns) {
    normalized[q.label] = {
      questionId: q.id,
      question: q.question,
      answers: q.answers,
    };
  }
  return normalized;
};

export default combineReducers({ profile, memorandumSigned });
