// eslint-disable-next-line import/no-cycle
import {
  ENTITIES_INITIAL_STATE as CORE_ENTITIES_INITIAL_STATE,
  EntitiesState as CoreEntitiesState,
} from "../features/core/redux/normalize";
import {
  ENTITIES_INITIAL_STATE as PROFILE_ENTITIES_INITIAL_STATE,
  EntitiesState as ProfileEntitiesState,
} from "../features/profile/redux/normalize";

import { DeepReadonly } from "ts-essentials";
import { mergeDeepRight } from "ramda";
export type EntitiesState = DeepReadonly<
  CoreEntitiesState & ProfileEntitiesState
>;

const initialEntitiesState: EntitiesState = {
  ...CORE_ENTITIES_INITIAL_STATE,
  ...PROFILE_ENTITIES_INITIAL_STATE,
};

export default function entities(
  state: EntitiesState = initialEntitiesState,
  action: any
): EntitiesState {
  const { entities } = action as { entities?: any };

  if (entities) {
    return mergeDeepRight(state, entities) as EntitiesState;
  } else {
    return state;
  }
}
