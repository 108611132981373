/* eslint-disable import/prefer-default-export */
import { StoreState } from "../../../store/rootReducer";
import { createSelector } from "reselect";
import { oc } from "ts-optchain";

// export const selectEntitiesCoreUsers = (state: StoreState) =>
//   selectEntities(state).common_core_users;

export const selectIsLoggedIn = (state: StoreState) =>
  state.core.auth.token != null;

export const selectCurrentOrganizationName = (state: StoreState) => {
  const token = state.core.auth.token;
  const orgs = oc(token).organizations([]);

  return oc(orgs.find((o) => o.id === oc(token).currOrgId())).displayName();
};

export const selectCurrentUserName = (state: StoreState) =>
  oc(state.core.auth.token).displayName(undefined);

export const selectGlobalStatus = (state: StoreState) =>
  state.core.globalStatus;

export const select5Notifications = createSelector(
  (state: StoreState) => state.core.notifications,
  (notifications) => {
    return notifications.slice(0, 5);
  }
);
