import { Link, LinkProps } from "react-router-dom";
import React from "react";
import cn from "classnames";
import css from "./index.module.css";

type ButtonProps = React.ComponentProps<"button">;

export const AuthButton: React.FC<ButtonProps> = ({
  className = "",
  ...rest
}) => (
  <button
    className={cn(css.button, css.buttonAuth, className)}
    type="button"
    {...rest}
  />
);

export const InformButton = ({ className = "", ...rest }) => (
  <button className={cn(css.buttonInform, className)} type="button" {...rest} />
);

export const BlueButton: React.FC<ButtonProps> = ({
  className = "",
  ...rest
}) => (
  <button
    className={cn(css.button, css.buttonBlue, className)}
    type="button"
    {...rest}
  />
);

export const LinkBlueButton: React.FC<LinkProps & { disabled?: boolean }> = ({
  to,
  className = "",
  children,
  disabled,
  ...rest
}) => {
  return disabled ? (
    <span className={cn(css.button, css.buttonBlue, className)}>
      {children}
    </span>
  ) : (
    <Link
      className={cn(css.button, css.buttonBlue, className)}
      to={to}
      {...rest}
    >
      {children}
    </Link>
  );
};
