import { AxiosError } from "axios";
import { RestApiError, RestApiErrorErrorCodeEnum } from "api";

/* eslint-disable import/prefer-default-export */

export const toErrorCode = (
  e: AxiosError<RestApiError> | Error | string | undefined
): RestApiErrorErrorCodeEnum => {
  if ((e as AxiosError).response) {
    return (e as AxiosError).response!.data.errorCode;
  } else if (typeof e === "string") {
    return Object.values(RestApiErrorErrorCodeEnum).includes(
      e as RestApiErrorErrorCodeEnum
    )
      ? (e as RestApiErrorErrorCodeEnum)
      : RestApiErrorErrorCodeEnum.INTERNAL;
  } else {
    // eslint-disable-next-line no-console
    console.error(e);
    return RestApiErrorErrorCodeEnum.INTERNAL;
  }
};
